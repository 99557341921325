import { Button, Grid, CircularProgress } from "@mui/material";
import PieChartWithCenterLabel from "./PieChart";
import HorizontalLinearStepper from "./Stepper";
import { useState, useEffect } from "react";
import { useNavStore, useReqStore, useUserStor } from "../../../store";
import "./requests.css";
import server from "../../../api/server";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import DiscountPopup from "./DiscountPopup";
import DiscountConfirmPopup from "./ConfirmDiscount";
import ConfirmCancelPopup from "../../renter/request/ConfirmCancelPopup";
import { redirectOwner } from "../../utils/common";

const Agreement = () => {
  //to check if this offer is the second offer to owner
  const [ownerCanCanel, setOwnerCanCancel] = useState(true);

  const [loading, setLoading] = useState(true);
  const [loadingR, setLoadingR] = useState(true);
  const [acceptloading, setAcceptLoading] = useState(false);
  const [rejectloading, setRejectLoading] = useState(false);
  const [offer, setOffer] = useState(null);
  const [isDiscountPopup, setIsDiscountPopup] = useState(false);
  const [isConfirmDiscountPopup, setIsConfirmDiscountPopup] = useState(false);
  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);
  const navigate = useNavigate();
  // const { req } = useReqStore();
  // const { token } = useUserStor();

  const discount = 1000;

  const token = localStorage.getItem("token");

  const reqId = localStorage.getItem("app-id");
  const { req, setReq } = useReqStore();

  const date = new Date(req.created_at).toLocaleDateString();
  const data = [
    { value: 40, label: "", color: "#014E97" },
    { value: 60, label: "", color: "#F1F4FF" },
  ];

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-applications/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      setLoadingR(false);
      // redirect(data.date.application_status_id, CurrentPageId, navigate);
    } catch (error) {
      console.log(error);
    }
  };

  const CurrentPageId = window.location.pathname.substring(16);

  useEffect(() => {
    if (req) {
      redirectOwner(req.application_status_id, CurrentPageId, navigate);
      getOffer();
    }
  }, [req]);

  const getOffer = async () => {
    const { data } = await server.get(`/customers/get-offers/${reqId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    setOffer(data.data);
    setLoading(false);
    // redirect(req.application_status_id, CurrentPageId, navigate);
  };

  const aproveOffer = async () => {
    setAcceptLoading(true);
    try {
      const { data } = await server.post(
        "/customers/owner-offer-reply",
        {
          offer_id: offer.id,
          status: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setOffer(null);
      getData();
    } catch (error) {
      console.log(error);
      setAcceptLoading(false);
    }
  };
  const OwnerAproved = async () => {
    setAcceptLoading(true);
    try {
      const { data } = await server.post(
        "/customers/owner-approved",
        {
          offer_id: offer.id,
          bear_amount_by: "Owner",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      navigate("/owner/requests/5");
      setOffer(null);
    } catch (error) {
      console.log(error);
      setAcceptLoading(false);
    }
  };

  const rejectOffer = async () => {
    setRejectLoading(true);
    try {
      const { data } = await server.post(
        "/customers/owner-offer-reply",
        {
          offer_id: offer.id,
          status: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setOffer(null);
    } catch (error) {
      console.log(error);
      setRejectLoading(false);
    }
  };

  const cancelApplication = async () => {
    setRejectLoading(true);
    try {
      const { data } = await server.patch(
        `/customers/cancel-application/${req.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setOffer(null);
      navigate("/owner/dashboard");
    } catch (error) {
      console.log(error);
      setRejectLoading(false);
    }
  };

  //to check if this offer is the second offer to owner
  const handleActionAprove = () => {
    if (offer?.last_offer_status === 2) {
      OwnerAproved();
    } else {
      aproveOffer();
    }
  };
  const handleActionReject = () => {
    if (offer?.last_offer_status === 2) {
      // cancelApplication();
      setConfirmCancelPopup(true);
    } else {
      // rejectOffer();
      setIsDiscountPopup(true);
    }
  };

  const renderOffer = () => {
    if (offer) {
      return (
        <>
          <div className="agree-box4">
            <div className="text6">العرض الحالي</div>
            {renterAproveTheOffer()}
            {/* <div className="text6">{offer.note}</div> */}
          </div>
          <div className="agree-box1" style={{ borderRadius: "8px" }}>
            <div className="agree-box2">
              {/* <div style={{ direction: "rtl" }}>
                <div className="text2">رسوم التخفيض</div>
                <div className="text6">{`${offer.fees} ريال`}</div>
              </div> */}
              <div style={{ direction: "rtl" }}>
                <div className="text2">قيمة المبلغ السنوي</div>
                <div className="text6">{`${offer.rent_amount} ريال`}</div>
              </div>
            </div>
          </div>
          <div className="agree-box3">
            <LoadingButton
              variant="contained"
              sx={{ fontFamily: "font", bgcolor: "#014E97" }}
              onClick={() => handleActionAprove()}
              loading={acceptloading}
            >
              <div
                className="text3"
                style={{
                  color: "#fff",
                  fontSize: "0.79rem",
                }}
              >
                موافقة
              </div>
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="info"
              sx={{
                fontFamily: "font",
                width: "max-content",
                fontSize: "0.8vmax",
                background: "rgba(248, 159, 170, 0.50)",
                "&:hover": {
                  background: "rgba(248, 159, 170, 0.50)",
                },
              }}
              onClick={() => handleActionReject()}
              loading={rejectloading}
            >
              {offer?.last_offer_status === 2 ? (
                <div
                  className="text3"
                  style={{
                    color: "#EB001B",
                    fontSize: "0.79rem",
                  }}
                >
                  إلغاء الطلب
                </div>
              ) : (
                <div
                  className="text3"
                  style={{
                    // color: "#EB001B",
                    fontSize: "0.79rem",
                  }}
                >
                  رفض
                </div>
              )}
            </LoadingButton>
          </div>
        </>
      );
    } else {
      return (
        <div className="agree-box4">
          <div
            className="text6"
            style={{
              width: "100%",
              padding: "5px",
              borderTop: "10px",
            }}
          >
            بانتظار المستأجر ليرد على العرض
          </div>
        </div>
      );
    }
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginBlock: "20px",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return renderOffer();
    }
  };

  const renterAproveTheOffer = () => {
    if (offer?.last_offer_status === 2) {
      return (
        <div style={{ width: "100%" }}>
          <div className="text12 mr0" style={{ marginTop: 10 }}>
            لم تتم الموافقة على العرض من قبل المستأجر
          </div>
          <div className="text2" style={{ marginTop: 10 }}>
            بإمكانك مواصلةالنعاقد وتحمل الرسوم الإضافية
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "الطلبات",
        items: [{ text: "طلبات الإيجار", path: "/owner/requests" }],
      },
    });
    // console.log(req);
    getData();
    getOffer();
  }, []);

  return (
    <div className="req-root vw100 scale-r">
      <DiscountPopup
        open={isDiscountPopup}
        setOpen={setIsDiscountPopup}
        offerId={offer?.id}
        setOffer={setOffer}
        discount={offer?.fees}
        token={token}
        confirm={setIsConfirmDiscountPopup}
      />
      <DiscountConfirmPopup
        open={isConfirmDiscountPopup}
        setOpen={setIsConfirmDiscountPopup}
        offerId={offer?.id}
        setOffer={setOffer}
        discount={offer?.fees}
        token={token}
      />
      <ConfirmCancelPopup
        open={confirmCancelPopup}
        setOpen={setConfirmCancelPopup}
        offerId={offer?.id}
        setOffer={setOffer}
        userType="Owner"
        token={token}
        reqId={req.id}
      />
      {loadingR ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginBlock: "20px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="box10">
            <div className="box11">
              <div className="text8 center">طلباتي</div>
            </div>
            <div>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  // width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={() => navigate("/owner/requests/new")}
              >
                <div className="text3">إنشاء طلب جديد</div>
              </Button>
            </div>
          </div>

          <div className="box14" style={{ height: "auto" }}>
            <Grid
              container
              sx={{
                direction: "rtl",
                borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
              }}
            >
              <Grid item md={4} xs={6}>
                <div className="box11">
                  <div className="text22 center">{`الطلب #${req.serial_number}`}</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {date}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={0}
                xs={0}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                  },
                }}
              >
                <div className="box11" style={{ width: "100%" }}>
                  <div className="text9 center">حالة الطلب</div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="shape2"></div>
                    <p className="text22 text-r" style={{ marginTop: "5%" }}>
                      {req.status.name}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} />
                <div className="text9 center">مكتمل بنسبة</div>
                <PieChartWithCenterLabel data={data} />
              </Grid>
            </Grid>
            <div className="box14" />
            <Grid
              container
              sx={{
                direction: "rtl",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                padding: "10px",
                borderRadius: "8px",
                // display: "inline-block",
              }}
            >
              {/* <Grid item lg={1} md={1} sm={12} xs={12}>
						<div className="text9 center">حالة الطلب: </div>
					</Grid> */}
              <Grid item md={12} sm={12} xs={12}>
                <HorizontalLinearStepper
                  acitve={req.application_status_id - 1}
                  error={offer?.last_offer_status === 2}
                />
              </Grid>
            </Grid>
            {renderLoading()}
          </div>
        </>
      )}
    </div>
  );
};

export default Agreement;
