import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { useReqStore } from "../../../store";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DiscountPopup({
  open,
  setOpen,
  offerId,
  token,
  discount,
  setOffer,
  confirm,
}) {
  // const navigate = useNavigate();
  // const { setReq } = useReqStore();

  const [acceptloading, setAcceptLoading] = useState(false);
  const [rejectloading, setRejectLoading] = useState(false);

  const discounted = Math.round(Number(discount) * 0.5);

  const rejectOffer = async () => {
    // setRejectLoading(true);
    // try {
    // 	const { data } = await server.post(
    // 		"/customers/owner-offer-reply",
    // 		{
    // 			offer_id: offerId,
    // 			status: 2,
    // 		},
    // 		{
    // 			headers: {
    // 				Authorization: `Bearer ${token}`,
    // 			},

    // 			redirect: "follow",
    // 		}
    // 	);
    // 	console.log(data);
    // 	setOffer(null);
    // 	setOpen(false);
    // } catch (error) {
    // 	console.log(error);
    // 	setRejectLoading(false);
    // }
    setOpen(false);
    confirm(true);
  };

  const acceptOffer = async () => {
    setAcceptLoading(true);
    try {
      const { data } = await server.post(
        "/customers/owner-offer-reply",
        {
          offer_id: offerId,
          status: 3,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setOffer(null);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setAcceptLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
      >
        <DialogContent
          dividers
          sx={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1vmax",
            width: "50vmin",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginInline: "20px",
            }}
          >
            <div style={{ fontSize: "1vmax" }}>
              {`هل تريد مناصفة التخفيض مع المستأجر (تخفيض 5%)`}
            </div>
          </div>

          <div className="agree-box3">
            <LoadingButton
              variant="contained"
              color="error"
              sx={{
                fontFamily: "font",
                width: "max-content",
                fontSize: "0.8vmax",
                background: "rgba(248, 159, 170, 0.50)",
                "&:hover": {
                  background: "rgba(248, 159, 170, 0.50)",
                },
              }}
              onClick={() => rejectOffer()}
              loading={rejectloading}
            >
              <div
                className="text3"
                style={{
                  color: "#EB001B",
                  fontSize: "0.79rem",
                }}
              >
                رفض
              </div>
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ fontFamily: "font", bgcolor: "#014E97" }}
              onClick={() => acceptOffer()}
              loading={acceptloading}
            >
              <div
                className="text3"
                style={{
                  color: "#fff",
                  fontSize: "0.79rem",
                }}
              >
                موافقة
              </div>
            </LoadingButton>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
