import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Otp from "./components/Login/Otp";
import IdVerification from "./components/Login/IdVerificatin";
import HomeLayout from "./components/HomeLayuot/HomeLayout";
import Dashboard from "./components/Dashboard/Dashboard";
import RenterRequest from "./components/renter/request/Request";
import NewRenterReq from "./components/renter/request/Newreq";
import RequestShow from "./components/renter/request/RequestShow";
import AprovedReq from "./components/renter/request/AprovedReq";
import PayFee from "./components/renter/request/PayFee";
import EjarContract from "./components/renter/request/EjarContract";
import CompleteReq from "./components/renter/request/CompleteReq";
import PaymentsPage from "./components/renter/payments/PaymentsPage";
import Support from "./components/renter/support/Support";
import NewTicket from "./components/renter/support/NewTicket";
import Ticket from "./components/renter/support/Ticket";
import HomeLayoutOwner from "./components/owner/ownerLayuot/HomeLayoutOwner";
import DashboardOwner from "./components/owner/dashBoard/DashboardOwner";
import OwnerRequest from "./components/owner/requests/Request";
import NewOwnerReq from "./components/owner/requests/Newreq";
import RequestShowOwner from "./components/owner/requests/RequestShow";
import SupportOwner from "./components/owner/support/Support";
import NewTicketOwner from "./components/owner/support/NewTicket";
import TicketOwner from "./components/owner/support/Ticket";
import LandingPage from "./components/LandingPage/LandingPage";
import Signup from "./components/Login/Signup";
import Agreement from "./components/owner/requests/Agreement";
import EjarContractOwner from "./components/owner/requests/EjarContractOwner";
import CompleteApplication from "./components/renter/request/CompleteApplication";
import Profile from "./components/renter/profile/Profile";
import OwnerProfile from "./components/owner/profile/Profile";
import Contracts from "./components/renter/contracts/Contracts";
import ContractsOwner from "./components/owner/contracts/Contracts";
import ContractDetailsRenter from "./components/renter/contracts/ContractDetails";
import ContractDetailsOwner from "./components/owner/contracts/ContractDetails";
import RenterPay from "./components/renter/request/RenterPay";
import Deposits from "./components/owner/deposites/Deposits";
import Payment from "./components/payment/Payment";
import RealTime from "./RealTime";
import CompleteRequset from "./components/owner/requests/CompleteRequest";
import NafathCheck from "./components/Login/NafathCheck";
import Register from "./components/Login/Register";
import Signin from "./components/Login/Signin";

function App() {
  //test
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/:referralCode" element={<Signin />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/register" element={<Register />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/idverification" element={<IdVerification />} />
          <Route path="/nafath-check" element={<NafathCheck />} />
          <Route
            path="/home"
            element={<HomeLayout element={<Dashboard />} />}
          />
          <Route
            path="/renter/requests"
            element={<HomeLayout element={<RenterRequest />} />}
          />
          <Route
            path="/renter/requests/new"
            element={<HomeLayout element={<NewRenterReq />} />}
          />
          <Route
            path="/renter/requests/show"
            element={<HomeLayout element={<RequestShow />} />}
          />
          <Route
            path="/renter/payment/:installmentId"
            element={<HomeLayout element={<Payment />} />}
          />
          <Route
            path="/renter/payment/:installmentId/:statusId"
            element={<HomeLayout element={<Payment />} />}
          />
          <Route
            path="/renter/pay"
            element={<HomeLayout element={<RenterPay />} />}
          />
          <Route
            path="/renter/profile"
            element={<HomeLayout element={<Profile />} />}
          />
          <Route
            path="/renter/requests/1"
            element={<HomeLayout element={<CompleteApplication />} />}
          />
          <Route
            path="/renter/requests/2"
            element={<HomeLayout element={<RequestShow />} />}
          />
          <Route
            path="/renter/requests/3"
            element={<HomeLayout element={<AprovedReq />} />}
          />
          <Route
            path="/renter/requests/4"
            element={<HomeLayout element={<AprovedReq />} />}
          />
          <Route
            path="/renter/requests/5"
            element={<HomeLayout element={<EjarContract />} />}
          />
          <Route
            path="/renter/requests/7"
            element={<HomeLayout element={<RequestShow />} />}
          />
          <Route
            path="/renter/requests/6"
            element={<HomeLayout element={<CompleteReq />} />}
          />
          <Route
            path="/renter/contracts"
            element={<HomeLayout element={<Contracts />} />}
          />
          <Route
            path="/renter/contracts/:id"
            element={<HomeLayout element={<ContractDetailsRenter />} />}
          />
          <Route
            path="/renter/payments"
            element={<HomeLayout element={<PaymentsPage />} />}
          />
          <Route
            path="/renter/support"
            element={<HomeLayout element={<Support />} />}
          />
          <Route
            path="/renter/support/newticket"
            element={<HomeLayout element={<NewTicket />} />}
          />
          <Route
            path="/renter/support/ticket"
            element={<HomeLayout element={<Ticket />} />}
          />
          <Route
            path="/owner/dashboard"
            element={<HomeLayoutOwner element={<DashboardOwner />} />}
          />
          <Route
            path="/owner/requests"
            element={<HomeLayoutOwner element={<OwnerRequest />} />}
          />
          <Route
            path="/owner/requests/new"
            element={<HomeLayoutOwner element={<NewOwnerReq />} />}
          />
          <Route
            path="/owner/profile"
            element={<HomeLayoutOwner element={<OwnerProfile />} />}
          />
          <Route
            path="/owner/requests/1"
            element={<HomeLayoutOwner element={<RequestShowOwner />} />}
          />
          <Route
            path="/owner/requests/2"
            element={<HomeLayoutOwner element={<RequestShowOwner />} />}
          />
          <Route
            path="/owner/requests/3"
            element={<HomeLayoutOwner element={<Agreement />} />}
          />
          <Route
            path="/owner/requests/4"
            element={<HomeLayoutOwner element={<Agreement />} />}
          />
          <Route
            path="/owner/requests/5"
            element={<HomeLayoutOwner element={<EjarContractOwner />} />}
          />
          <Route
            path="/owner/requests/6"
            element={<HomeLayoutOwner element={<CompleteRequset />} />}
          />
          <Route
            path="/owner/requests/7"
            element={<HomeLayoutOwner element={<RequestShowOwner />} />}
          />
          <Route
            path="/owner/contracts/"
            element={<HomeLayoutOwner element={<ContractsOwner />} />}
          />
          <Route
            path="/owner/contracts/:id"
            element={<HomeLayoutOwner element={<ContractDetailsOwner />} />}
          />
          <Route
            path="/owner/deposits"
            element={<HomeLayoutOwner element={<Deposits />} />}
          />
          <Route
            path="/owner/support"
            element={<HomeLayoutOwner element={<SupportOwner />} />}
          />
          <Route
            path="/owner/support/newticket"
            element={<HomeLayoutOwner element={<NewTicketOwner />} />}
          />
          <Route
            path="/owner/support/ticket"
            element={<HomeLayoutOwner element={<TicketOwner />} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
