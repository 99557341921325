import { Grid, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { useEffect, useState } from "react";
import CustomPaginationActionsTable from "./DataGrid";
import { useNavStore } from "../../../store";

const Contracts = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const token = localStorage.getItem("token");

	const getContracts = async () => {
		try {
			const { data } = await server.get("/customers/get-contracts", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setData(data.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		useNavStore.setState({
			nav: {
				text: "العقود",
			},
		});
		getContracts();
	}, []);

	return (
		<div style={{ width: "100%" }}>
			<div
				className="box10"
				style={{
					display: "flex",
					justifyContent: "start",
					width: "100%",
				}}
			>
				<div className="box11" style={{ width: "100%" }}>
					<div className="text8 center" style={{ marginRight: "0%" }}>
						العقود
					</div>
				</div>
			</div>
			<div
				className="box14"
				style={{
					margin: "2rem",
					height: "auto",
					justifyContent: "center",
				}}
			>
				<div className="box10 box50">
					{/* <div className="box11 box51" style={{ paddingRight: 0 }}>
						<SearchBar setSearch={setSearch} />
						<Button
							variant="contained"
							size="small"
							onClick={handleSearch}
							sx={{
								marginRight: "10px",
								// width: "10vw",
								bgcolor: "#014E97",
								height: "35px",
							}}
						>
							<div className="text3">بحث</div>
						</Button>
					</div> */}
				</div>
				<div
					style={{
						backgroundColor: "#F4F7FF",
						padding: 10,
						borderRadius: 8,
						width: "100%",
						textAlign: "center",
					}}
				>
					{!loading ? (
						<>
							{data.length === 0 ? (
								<div
									className="text8"
									style={{
										width: "100%",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									لاتوجد عقود
								</div>
							) : (
								<CustomPaginationActionsTable
									rows={data}
									noPagination
								/>
							)}
						</>
					) : (
						<div style={{ width: "100%", textAlign: "center" }}>
							<CircularProgress />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Contracts;
