import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import success from "../../assets/paysuccess.png";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SuccessPayment({ open, setOpen, amount }) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/renter/payments");
  };

  const handleAgeeAndContinue = () => {
    navigate("/renter/payments");
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
      >
        <DialogContent
          dividers
          sx={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1vmax",
            width: "50vmin",
          }}
        >
          <img src={success} alt="success" style={{ width: "10vmax" }} />
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: "1.5vmax" }}>تم إرسال المدفوعات بنجاح</div>
            {/* <div
              style={{
                color: "#27AE60",
                fontSize: "1.5vmax",
                marginRight: "5px",
              }}
            >
              بنجاح
            </div> */}
          </div>
          <div style={{ fontSize: "1.9vmax" }}>{amount} ريال</div>
          <Button
            // autoFocus
            onClick={handleAgeeAndContinue}
            variant="contained"
            size="large"
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              bgcolor: "#014E97",
            }}
          >
            <div className="text3">تم</div>
          </Button>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
