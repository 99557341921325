import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PieChartWithCenterLabel from "./PieChart";
import HorizontalLinearStepper from "./Stepper";
import icon1 from "../../../assets/single-folded-content 1.png";
import Contract from "./Contract";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReqStore, useSettingStore, useUserStor } from "../../../store";
import server from "../../../api/server";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmRejectPopup from "./ConfirmRejectPopup";
import ConfirmCancelPopup from "./ConfirmCancelPopup";
import { redirect } from "../../utils/common";
import { NumericFormat } from "react-number-format";

const AprovedReq = () => {
  const [loading, setLoading] = useState(true);
  const [loadingR, setLoadingR] = useState(true);
  const [acceptloading, setAcceptLoading] = useState(false);
  const [rejectloading, setRejectLoading] = useState(false);
  const [offer, setOffer] = useState(null);
  const [openContract, setOpenContract] = useState(false);
  const [confirmRejectPopup, setConfirmRejectPopup] = useState(false);
  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);

  const { settings } = useSettingStore();
  const CurrentPageId = window.location.pathname.substring(17);

  const navigate = useNavigate();
  const { setFees } = useReqStore();
  // const { token } = useUserStor();
  const token = localStorage.getItem("token");
  const reqId = localStorage.getItem("app-id");

  const { req, setReq } = useReqStore();

  //check if the owner rejected the the offer so the renter can cancel the the application
  const [renterCanCancel, setRenterCanCancel] = useState(false);

  const data = [
    { value: 50, label: "", color: "#014E97" },
    { value: 50, label: "", color: "#F1F4FF" },
  ];
  const date = new Date(req.created_at).toLocaleDateString();

  const getOffer = async () => {
    try {
      const { data } = await server.get(`/customers/get-offers/${reqId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOffer(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-applications/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // localStorage.setItem("req", JSON.stringify(data.data));
      setReq(data.data);
      setLoadingR(false);
      // redirect(data.date.application_status_id, CurrentPageId, navigate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (req) {
      redirect(req.application_status_id, CurrentPageId, navigate);
      getOffer();
    }
  }, [req]);

  const aproveOffer = async () => {
    try {
      const { data } = await server.post(
        "/customers/renter-offer-reply",
        {
          offer_id: offer.id,
          status: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setFees(data.data.Administrative_fees);
      // setOpenContract(true);
      navigate("/renter/requests/5");
      if (data.data.offers) {
        localStorage.setItem("req", JSON.stringify(data.data));
      }
    } catch (err) {
      console.log(console.log(err));
    }
  };

  const bearOfferAprove = async () => {
    try {
      const { data } = await server.post(
        "/customers/renter-offer-reply",
        {
          offer_id: offer.id,
          status: 1,
          bear_amount_by: "Renter",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setFees(data.data.Administrative_fees);
      // setOpenContract(true);
      navigate("/renter/requests/5");
      if (data.data.offers) {
        setReq(data.data);
      }
    } catch (err) {
      console.log(console.log(err));
    }
  };

  const rejectOffer = async () => {
    try {
      const { data } = await server.post(
        "/customers/renter-offer-reply",
        {
          offer_id: offer.id,
          status: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setOffer(null);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelApplication = async () => {
    try {
      const { data } = await server.patch(
        `/customers/cancel-application/${req.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setOffer(null);
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionAprove = () => {
    if (offer?.last_offer_status === 2) {
      bearOfferAprove();
    } else {
      aproveOffer();
    }
  };
  const handleActionReject = () => {
    if (req.model_type !== "Owner") {
      // cancelApplication();
      setConfirmCancelPopup(true);
    } else {
      setConfirmRejectPopup(true);
      // rejectOffer();
    }
  };
  // const handleActionReject = () => {
  // 	// if (offer?.last_offer_status === 2) {
  // 	// 	cancelApplication();
  // 	// } else {
  // 	// 	rejectOffer();
  // 	// }
  // 	cancelApplication();
  // };

  const ownerAproveTheOffer = () => {
    if (offer?.last_offer_status === 2) {
      return (
        <div style={{ width: "100%" }}>
          <div className="text12 mr0" style={{ color: "#EB001B" }}>
            لم تتم الموافقة على العرض من قبل المالك
          </div>
          <div className="text2">
            بإمكانك مواصلةالنعاقد وتحمل الرسوم الإضافية
          </div>
        </div>
      );
    }
  };
  const renderCalculations = () => {
    if (offer?.last_offer_status === 2) {
      return (
        <p className="text12">
          {offer.rent_amount * 0.1} + {offer.fees} رسوم إدارية
        </p>
      );
    } else {
      return <p className="text12">{offer.fees} رسوم إدارية</p>;
    }
  };

  // const handleNavigate = () => {
  // 	navigate("/renter/requests/payfee");
  // };

  const renderOffer = () => {
    if (offer) {
      const taxValue =
        Number(offer.total_amount) * Number(`0.${settings.vat_tax}`);
      const totalValue =
        Number(offer.total_amount) + Number(taxValue.toFixed(2));
      const installment = totalValue / 12;
      return (
        <>
          <div
            className="text8"
            style={{
              width: "100%",
              padding: "5px",
              borderTop: "10px",
            }}
          >
            معلومات التعاقد
          </div>
          {ownerAproveTheOffer()}
          <div
            className="text12"
            style={{
              width: "100%",
              padding: "5px",
              borderTop: "10px",
            }}
          >
            {/* {offer.note} */}
          </div>

          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={12} md={6}>
              <Box sx={boxStyle2}>
                <div className="text3">قيمة الإيجار + الضريبة</div>
                <div
                  className="text8"
                  style={{
                    color: "#014E97",
                    marginTop: 5,
                    display: "flex",
                    gap: 4,
                  }}
                >
                  {/* {offer.total_amount} ريال */}
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    value={offer.total_amount}
                  />
                  <div>+</div>
                  <div>
                    <NumericFormat
                      displayType="text"
                      thousandSeparator=","
                      value={taxValue.toFixed(2)}
                    />
                  </div>
                  <div>=</div>
                  <div>
                    <NumericFormat
                      displayType="text"
                      thousandSeparator=","
                      value={`${totalValue} ريال`}
                    />
                  </div>
                  <div style={{ marginRight: 0 }}>ريال</div>
                </div>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Box sx={boxStyle2}>
                <div className="text3">نسبة الربح</div>
                <div
                  className="text8 text-green"
                  style={{
                    marginTop: 5,
                  }}
                >
                  20%
                </div>
              </Box>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Box sx={boxStyle2}>
                <div className="text3">متوسط القسط الشهري</div>
                <div
                  className="text8"
                  style={{
                    color: "#014E97",
                    marginTop: 5,
                  }}
                >
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    value={`${installment.toFixed(2)} ريال`}
                  />
                  <div style={{ marginRight: 5 }}>ريال</div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={boxStyle2}>
                <div className="text3">قيمة الدفعة الأولى</div>
                <div
                  className="text8"
                  style={{
                    color: "#014E97",
                    marginTop: 5,
                  }}
                >
                  <NumericFormat
                    displayType="text"
                    prefix=""
                    thousandSeparator=","
                    value={`${
                      Number(installment.toFixed(2)) + Number(offer.fees)
                    }`}
                  />
                  <div style={{ marginRight: 5 }}>ريال</div>
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
            >
              <LoadingButton
                variant="contained"
                color="error"
                sx={{
                  fontFamily: "font",
                  width: "max-content",
                  fontSize: "0.8vmax",
                  background: "rgba(248, 159, 170, 0.50)",
                  "&:hover": {
                    background: "rgba(248, 159, 170, 0.50)",
                  },
                }}
                onClick={handleActionReject}
                loading={rejectloading}
              >
                {/* <div
										className="text3"
										style={{
											color: "#EB001B",
											fontSize: "0.79rem",
										}}
									>
										إلغاء الطلب
									</div> */}
                {req.model_type !== "Owner" ? (
                  <div
                    className="text3"
                    style={{
                      color: "#EB001B",
                      fontSize: "0.79rem",
                    }}
                  >
                    إلغاء الطلب
                  </div>
                ) : (
                  <div
                    className="text3"
                    style={{
                      color: "#EB001B",
                      fontSize: "0.79rem",
                    }}
                  >
                    رفض
                  </div>
                )}
              </LoadingButton>
              <LoadingButton
                loading={acceptloading}
                variant="contained"
                sx={{
                  // width: "100%",
                  fontSize: "0.8vmax",
                  bgcolor: "#014E97",
                }}
                onClick={handleActionAprove}
              >
                <div
                  className="text3"
                  style={{
                    color: "#fff",
                    fontSize: "0.79rem",
                  }}
                >
                  قبول العرض
                </div>
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <div
          className="text8"
          style={{
            width: "100%",
            padding: "5px",
            borderTop: "10px",
          }}
        >
          بانتظار المالك ليرد على العرض
        </div>
      );
    }
  };

  const renderLoading = () => {
    if (loading) {
      return <CircularProgress />;
    } else {
      return renderOffer();
    }
  };

  useEffect(() => {
    getOffer();
    getData();
  }, []);

  return (
    <div className="req-root vw100">
      {loadingR ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="box10">
            <div className="box11">
              <div className="text8 center">طلباتي</div>
            </div>
            <div>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  // width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={() => navigate("/renter/requests/new")}
              >
                <div className="text3">إنشاء طلب جديد</div>
              </Button>
            </div>
          </div>

          <div className="box14" style={{ height: "auto" }}>
            <Grid
              container
              sx={{
                direction: "rtl",
                borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
              }}
            >
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <div className="box11">
                  <div className="text22 center">{`الطلب #${req.serial_number}`}</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {date}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={0}
                xs={0}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                  },
                }}
              >
                <div className="box11">
                  <div className="text9 center">حالة الطلب</div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="shape2"
                      style={{
                        backgroundColor:
                          offer?.last_offer_status === 2 ? "#EB001B" : "auto",
                      }}
                    ></div>
                    <p
                      className="text22 text-r"
                      style={{
                        marginTop: "5%",
                      }}
                    >
                      {req.status.name}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} />
                <div className="text9 center">مكتمل بنسبة</div>
                <PieChartWithCenterLabel data={data} />
              </Grid>
            </Grid>
            <div className="box14" style={{ height: "auto" }} />
            <Grid
              container
              sx={{
                direction: "rtl",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                padding: "10px",
                borderRadius: "8px",
                // display: "inline-block",
              }}
            >
              {/* <Grid item lg={1} md={1} sm={12} xs={12}>
						<div className="text9 center">حالة الطلب: </div>
					</Grid> */}
              <Grid item md={12} xs={12}>
                <HorizontalLinearStepper
                  acitve={req.application_status_id - 1}
                  error={offer?.last_offer_status === 2}
                />
              </Grid>
            </Grid>

            <div
              style={{
                direction: "rtl",
                padding: "10px",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                display: "flex",
                width: "100%",

                // display: "inline-block",
              }}
            >
              {/* <Grid
						container
						direction={"row"}
						sx={{ marginTop: "10px" }}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div className="box16-green mr0">
								<div className="box17">
									<img src={icon1} alt="icon" />
									<div className="text24">عقد الإلتزام</div>
								</div>
								<div className="text24">تمت الموافقة</div>
							</div>
						</div>
					</Grid> */}
              {renderLoading()}
            </div>
          </div>
        </>
      )}
      <ConfirmRejectPopup
        open={confirmRejectPopup}
        setOpen={setConfirmRejectPopup}
        offerId={offer?.id}
        setOffer={setOffer}
        token={token}
      />
      <ConfirmCancelPopup
        open={confirmCancelPopup}
        setOpen={setConfirmCancelPopup}
        offerId={offer?.id}
        setOffer={setOffer}
        token={token}
        reqId={req.id}
        userType="Renter"
      />
      <Contract
        open={openContract}
        setOpen={setOpenContract}
        req={req}
        token={token}
      />
    </div>
  );
};

export default AprovedReq;

const boxStyle1 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "10px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#F4F7FF",
  display: "flex",
  justifyContent: "space-between",
};

const boxStyle2 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "15px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#fff",
};
