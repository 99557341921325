import { Button, CircularProgress, Grid } from "@mui/material";
import "./dashboard.css";
import icon1 from "../../assets/Group.png";
import PieChartWithCenterLabel from "./PieChart";
import icon2 from "../../assets/icon1.png";
import icon3 from "../../assets/icon3.png";
import { useNavStore, usePayStore, useUserStor } from "../../store";
import { Suspense, useEffect, useState } from "react";
import server from "../../api/server";
import { useNavigate } from "react-router-dom";
import Activities from "./Activities";
import Inbox from "./Inbox";
import { showDate } from "../utils/common";
import image1 from "../../assets/empty 1.png";

const Dashboard = () => {
  const [activities, setActivities] = useState(null);
  const [loading, setLoading] = useState(true);
  const [installment, setInstallment] = useState({});

  const { nav } = useNavStore();
  const { userData, token, setDashboardData } = useUserStor();
  const [data, setData] = useState({});
  const localToken = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const { setCurrentPayment } = usePayStore();

  const handleNavigateToPayment = () => {
    setCurrentPayment(installment);
    navigate(`/renter/payment/${installment.id}`);
  };

  const getData = async () => {
    try {
      const { data } = await server.get("/customers/get-data", {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setDashboardData(data.data);
      setData(data.data);
      setActivities(data.data.activities);
      setInstallment(data.data.current_installment);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshData = async () => {
    try {
      const { data } = await server.get("/customers/refresh-applications", {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const isUserOwner = () => {
    if (user?.customer_type_id === 2) {
      navigate("/owner/dashboard");
    }
  };

  useEffect(() => {
    useNavStore.setState({ nav: { text: "لوحة المعلومات" } });
    refreshData();
    getData();
    isUserOwner();
  }, []);

  //show up when there is no applicaions
  const renderEmptyScreen = () => {
    return (
      <div className="renter-req-root" style={{ justifyContent: "flex-start" }}>
        <div className="shape10">
          <img src={image1} alt="img" />
        </div>
        <div className="text20">
          لا توجد طلبات إيجار محفوظه يمكنك إنشاء طلب جديد
        </div>
        <Button
          variant="outlined"
          size="large"
          sx={{
            minWidth: "20vw",
            width: "max-content",
            borderRadius: "52px",
            border: "solid 1px #004A98",
          }}
          onClick={handleCreateNewReq}
        >
          <div className="text21">إنشاء طلب إيجار +</div>
        </Button>
      </div>
    );
  };
  const handleCreateNewReq = () => {
    navigate("/renter/requests/new");
  };

  return (
    <div style={{ margin: "0px" }}>
      {data.applications?.length === 0 ? (
        renderEmptyScreen()
      ) : (
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ display: "flex", direction: "rtl", mt: "0px" }}
          mt={"0px"}
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="wc400px"
              style={{ marginRight: "0px", fontFamily: "font" }}
            >
              {`أهلا بك , ${userData?.name.split(" ")[0]} 👋`}
            </div>
            <Button
              variant="contained"
              size="large"
              sx={{
                marginTop: "10px",
                marginLeft: "10px",
                // width: "10vw",
                bgcolor: "#014E97",
              }}
              onClick={handleCreateNewReq}
            >
              <div className="text3">إنشاء طلب جديد</div>
            </Button>
          </Grid>
          <Grid
            item
            lg={4.1}
            md={4.1}
            sm={12}
            xs={12}
            display={"flex"}
            sx={{ height: "max-content" }}

            // ml={4}
            // mt={4}
          >
            <div className="box1 h0 width-available">
              <img
                src={icon1}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <p className="text8 mr5">الأقساط</p>
              <div className="border"></div>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  {" "}
                  <Grid item xs={12} display={"flex"}>
                    <Grid item xs={6}>
                      <Grid item xs={12} display={"flex"}>
                        <div className="shape1"></div>
                        <p className="text9">
                          المدفوع : {data.paid_installments} أقساط
                        </p>
                      </Grid>
                      <Grid item xs={12} display={"flex"}>
                        <div className="shape2"></div>
                        <p className="text9">
                          المتبقي : {data.unpaid_installments} أقساط
                        </p>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <PieChartWithCenterLabel
                        paid_installments={data.paid_installments}
                        unpaid_installments={data.unpaid_installments}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </Grid>
          <Grid
            item
            lg={7.9}
            md={7.7}
            sm={12}
            xs={12}
            display={"flex"}
            sx={{ height: "max-content" }}
            // ml={4}
            mt={0}
          >
            <div className="box1 h0 width-available">
              <p className="text8 mr5">القسط الحالي</p>
              {loading ? (
                <CircularProgress size={"5rem"} />
              ) : (
                <>
                  {installment?.id ? (
                    <>
                      <Grid item xs={12} display={"flex"}>
                        <p className="text10">العقد:</p>
                        <p className="text10">{installment.serial_no}</p>
                      </Grid>
                      <div className="box2">
                        <Grid container>
                          <Grid item lg={3} md={3} sm={6} xs={6}>
                            <p className="text11">التاريخ المحدد للدفع </p>
                            <p className="text12">
                              {showDate(installment.date_for_payment)}
                            </p>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <p className="text11">قيمة القسط</p>
                            <p className="text12">
                              {installment.deserved_amount} ريال سعودي
                            </p>
                          </Grid>

                          <Grid
                            item
                            lg={3}
                            md={3}
                            sm={12}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                mb: "10px",
                                width: "100%",
                                marginBlock: "0px",
                                marginInline: "5px",
                              }}
                              onClick={handleNavigateToPayment}
                            >
                              <div className="text13">تقيد كمدفوع</div>
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      لاتوجد أقساط
                    </div>
                  )}
                </>
              )}
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={"flex"}
            sx={{ height: "max-content" }}
            mt={0}
          >
            <div className="box1 h0 width-available">
              <div style={{ marginBottom: "10px" }} className="flex-center">
                <img
                  src={icon2}
                  alt="icon"
                  style={{ width: "20px", height: "20px" }}
                />
                <p className="text8 mr5">صندوق الوارد</p>
              </div>

              <Grid container>
                <Inbox userType="renter" />
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            sx={{ height: "max-content" }}
            xs={12}
            display={"flex"}
          >
            <div
              className="box1 h0 width-available flex-center"
              style={{ marginBottom: "10px" }}
            >
              <img
                src={icon3}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <p className="text8 mr5">الأنشطة الاخيرة</p>
              <Grid container>
                {loading ? (
                  <CircularProgress size={"5rem"} />
                ) : (
                  <Activities data={activities} userType="renter" />
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
