import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import icon from "../../../assets/external-link-alt 1.png";
import { useReqStore } from "../../../store";

export default function DataTable({ data }) {
  const navigate = useNavigate();
  const { setReq } = useReqStore();

  const rows = data.map((row) => {
    if (!row.renter) return;
    const date = new Date(row.created_at).toLocaleDateString();
    const reqStatus = () => {
      if (row.application_status_id === 6) {
        return <div className="text9 text-green">{row.status.name}</div>;
      } else if (row.application_status_id === 7) {
        return (
          <div className="text9" style={{ color: "#f20404" }}>
            {row.status.name}
          </div>
        );
      } else {
        return <div className="text9 orange">{row.status.name}</div>;
      }
    };
    const handleShowRiq = (req) => {
      if (!req.renter) return;
      setReq(req);
      localStorage.setItem("app-id", req.id);
      // localStorage.setItem("req", JSON.stringify(req));
      navigate(`/owner/requests/${req.application_status_id}`);
    };
    return (
      <div
        key={row.serial_number}
        // className="box10"
        style={{
          direction: "rtl",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: "10px",
          borderBottom: "1px solid #E7E7E7",
          padding: "5px",
        }}
      >
        <Grid container>
          <Grid item xs={1}>
            <div>
              <div className="text9" style={{ color: "#080D2B" }}>
                {row.serial_number}
              </div>
            </div>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <div className="text9">{row.renter?.name || "غير مسجل"}</div>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <div className="text9">{row.renter?.phone || "غير مسجل"}</div>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            {reqStatus()}
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"center"}>
            <div className="text9">{row.origin_rent_amount}</div>
          </Grid>
          <Grid item xs={1.5} display={"flex"} justifyContent={"center"}>
            <div className="text9">{row.property_location}</div>
          </Grid>
          <Grid item xs={1.5} display={"flex"} justifyContent={"center"}>
            <div className="text9">{date}</div>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"center"}>
            <Button variant="contained" onClick={() => handleShowRiq(row)}>
              <div className="text9" style={{ color: "#fff" }}>
                عرض
              </div>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  });

  return (
    <div
      className="box1"
      style={{
        height: "auto",
        backgroundColor: "#F4F7FF",
        minWidth: "min-content",
        // width: "500px",
      }}
    >
      <div
        // className="box10"
        style={{
          direction: "rtl",
          display: "flex",
          // justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: "10px",
          borderBottom: "1px solid #E7E7E7",
          padding: "15px",
          backgroundColor: "#fff",
          borderRadius: "4px",
        }}
      >
        <Grid container>
          <Grid item xs={1}>
            <div>
              <div
                className="text9"
                style={{ color: "#080D2B", width: "500px" }}
              >
                رقم الطلب
              </div>
            </div>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <div className="text9">إسم المستأجر</div>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <div className="text9">رقم الجوال</div>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <div className="text9">حالة الطلب</div>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"center"}>
            <div className="text9">قيمة الإيجار</div>
          </Grid>
          <Grid item xs={1.5} display={"flex"} justifyContent={"center"}>
            <div className="text9">موقع العقار</div>
          </Grid>
          <Grid item xs={1.5} display={"flex"} justifyContent={"center"}>
            <div className="text9">التاريخ</div>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"center"}>
            <div className="text9">خيارات</div>
          </Grid>
        </Grid>
      </div>
      {/* rows */}

      {rows}
    </div>
  );
}
