import { Button, Checkbox, Grid, TextField } from "@mui/material";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import "./login.css";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import {
	DateField,
	LocalizationProvider,
	DatePicker,
	MobileDatePicker,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavStore, useUserStor } from "../../store";
import { LoadingButton } from "@mui/lab";

const IdVerification = () => {
	const navigate = useNavigate();
	const { userData, token, setUserData } = useUserStor();
	const [Loading, setLoading] = useState(false);
	const [id, setId] = useState(null);
	const [date, setDate] = useState(null);
	const [idNumber, setIdNumber] = useState(null);
	const [lat, setLat] = useState();
	const [long, setLong] = useState();
	const [address, setAddress] = useState(null);

	const completeProfile = async () => {
		setLoading(true);
		try {
			const { data } = await server.post(
				"/customers/complete-profile",
				{
					dob: date,
					identity_type_id: id,
					identity_number: idNumber,
					lat: `${lat}`,
					long: `${long}`,
					address: `${address?.neighbourhood}, ${address?.city}, ${address?.state}, ${address?.country}`,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log(data);
			setUserData(data.data);
			localStorage.setItem("user", JSON.stringify(data.data));
			navigate("/home");
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const getAddrss = async () => {
		const link = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`;
		fetch(link)
			.then((res) => res.json())
			.then((data) => setAddress(data.address))
			.then(console.log(address));
	};

	useEffect(() => {
		navigator.geolocation.getCurrentPosition((position) => {
			setLat(position.coords.latitude);
			setLong(position.coords.longitude);
			// console.log(lat);
		});
	}, []);

	useEffect(() => {
		if (long) {
			getAddrss();
		}
	}, [long]);

	const handlecheckNationalId = () => {
		setId(1);
	};

	const handleCheckSpecialResidency = () => {
		setId(2);
	};

	const handleCheckResidencyId = () => {
		setId(3);
	};

	// navigation to next page
	const handleOnclickContinue = () => {
		completeProfile();
	};

	return (
		<div
			style={{
				backgroundImage: `url(${image22})`,
				backgroundSize: "cover",
				height: "100vh",
				display: "flex",
			}}
		>
			<Grid
				container
				className="login-container"
				sx={{ flexDirection: "row-reverse" }}
			>
				<Grid
					item
					xs={12}
					lg={6}
					md={6}
					sm={12}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
					}}
					className="image-container"
				>
					<img className="image1" src={image1} alt="Qistar" />
				</Grid>
				<Grid
					item
					xs={12}
					lg={6}
					md={6}
					sm={12}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
					}}
					className="login-root"
				>
					<div className="logIn" style={{ width: "30vw" }}>
						<img src={image2} className="image2" alt="Qistar" />
						<div className="text1">إختر نوع التعريف</div>
						<Grid
							container
							spacing={3}
							sx={{
								display: "flex",
								// justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div
									className={
										id === 3 ? "check-box2" : "check-box1"
									}
								>
									<div className="text5">هوية مقيم</div>
									<Checkbox
										style={{ transform: "scale(0.7)" }}
										onClick={handleCheckResidencyId}
										checked={id === 3}
									/>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div
									className={
										id === 2 ? "check-box2" : "check-box1"
									}
								>
									<div className="text5">إقامه مميزة</div>
									<Checkbox
										style={{ transform: "scale(0.7)" }}
										onClick={handleCheckSpecialResidency}
										checked={id === 2}
									/>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div
									className={
										id === 1 ? "check-box2" : "check-box1"
									}
								>
									<div className="text5">هوية وطنية</div>
									<Checkbox
										style={{
											transform: "scale(0.7)",
											width: "2rem",
										}}
										onClick={handlecheckNationalId}
										checked={id === 1}
									/>
								</div>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className="text2">تاريخ الميلاد</div>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<DemoContainer
										components={["MobileDatePicker"]}
									>
										<MobileDatePicker
											sx={{
												backgroundColor: "#F1F4FF",
												width: "100%",
												// direction: "rtl",
												color: "GrayText",
												fontFamily: "font",
												padding: 0,
											}}
											size="small"
											className="textInput1"
											variant="outlined"
											format="DD-MM-YYYY"
											onChange={(newValue) =>
												setDate(newValue)
											}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className="text2">رقم الهوية</div>
								<TextField
									sx={{
										backgroundColor: "#F1F4FF",
										width: "100%",
										direction: "rtl",
										color: "GrayText",
										// fontWeight: "700",
										fontFamily: "font",
										// height: "1rem",
									}}
									size="small"
									className="textInput1"
									variant="outlined"
									onChange={(e) =>
										setIdNumber(e.target.value)
									}
								/>
							</Grid>
						</Grid>

						<LoadingButton
							loading={Loading}
							variant="contained"
							sx={{ backgroundColor: "#004A98", width: "100%" }}
							onClick={handleOnclickContinue}
						>
							<div className="text3"> متابعة</div>
						</LoadingButton>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default IdVerification;
