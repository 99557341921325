import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { useReqStore } from "../../../store";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmCancelPopup({
  open,
  setOpen,
  userType,
  token,
  setOffer,
  reqId,
}) {
  const navigate = useNavigate();
  // const { setReq } = useReqStore();

  const [acceptloading, setAcceptLoading] = useState(false);
  const [rejectloading, setRejectLoading] = useState(false);
  const [cancel_reason, set_cancel_reason] = useState("");
  const [serverError, setServerError] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));

  const ServerErrorText = ({ value }) => {
    if (serverError[value]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {serverError[value]}
        </div>
      );
    }
  };

  const cancelApplication = async () => {
    setRejectLoading(true);
    try {
      const { data } = await server.patch(
        `/customers/cancel-application/${reqId}`,
        {
          cancel_by: user.id,
          cancel_as: userType,
          cancel_reason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      setOffer(null);
      navigate("/home");
    } catch (error) {
      console.log(error);
      setRejectLoading(false);
      setServerError(error.response.data.error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
      >
        <DialogContent
          dividers
          sx={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1vmax",
            width: "50vmin",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginInline: "20px",
            }}
          >
            {/* <div style={{ fontSize: "1vmax" }}>
							{`هل تريد مناصفة التخفيض مع المستأجر (تخفيض ${discounted} ريال)`}
						</div> */}
            <div style={{ fontSize: "1vmax" }}>{`هل أنت متاكد؟`}</div>
          </div>

          <div
            className="text2 t201 bold slb-text"
            style={{ color: "#1a1a1a", width: "100%" }}
          >
            سبب الإلغاء
          </div>
          <TextField
            multiline
            minRows={3}
            maxRows={15}
            sx={{
              ":focus-within": {
                backgroundColor: "#F1F4FF",
              },
              width: "100%",
              direction: "rtl",
              color: "GrayText",
              fontWeight: "700px",
            }}
            className="textInput1"
            variant="outlined"
            size="small"
            onChange={(e) => set_cancel_reason(e.target.value)}
          />
          <ServerErrorText value={"cancel_reason"} />
          <div className="agree-box3">
            <LoadingButton
              variant="contained"
              color="error"
              sx={{
                fontFamily: "font",
                width: "max-content",
                fontSize: "0.8vmax",
                background: "rgba(248, 159, 170, 0.50)",
                "&:hover": {
                  background: "rgba(248, 159, 170, 0.50)",
                },
              }}
              // onClick={() => rejectOffer()}
              onClick={() => handleClose()}
            >
              <div
                className="text3"
                style={{
                  color: "#EB001B",
                  fontSize: "0.79rem",
                }}
              >
                رجوع
              </div>
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ fontFamily: "font", bgcolor: "#014E97" }}
              onClick={() => cancelApplication()}
              loading={rejectloading}
            >
              <div
                className="text3"
                style={{
                  color: "#fff",
                  fontSize: "0.79rem",
                }}
              >
                إرسال
              </div>
            </LoadingButton>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
