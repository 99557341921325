import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import success from "../../../assets/success.svg";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { useReqStore } from "../../../store";
import { useState } from "react";
import { IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import icon1 from "../../../assets/upload.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function EditFinancialStatements({
  open,
  setOpen,
  appId,
  token,
  setFeedBack,
}) {
  const navigate = useNavigate();
  const { setReq, req } = useReqStore();

  const [financial_statements, setFinancial_statements] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formdata = new FormData();
  formdata.append("application_id", appId);
  formdata.append("financial_statements", financial_statements);

  const sendApplication = async () => {
    if (!financial_statements) {
      setError(true);
      return;
    }
    setLoading(true);
    try {
      const { data } = await server.post(
        "/customers/update-financial-statements",
        formdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("req", JSON.stringify({ ...req, files: data.data }));
      setReq({ ...req, files: data.data });
      setLoading(false);
      setOpen(false);
      setFeedBack(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
      // setError(Object.keys(error.response.data.error).toString());
    }
    console.log(error);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ErrorText = () => {
    return (
      <div
        className="text2"
        style={{ color: "red", marginTop: "0px", width: "100%" }}
      >
        لايمكنك ترك هذا الحقل فارغاً
      </div>
    );
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
      >
        <DialogContent
          dividers
          sx={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1vmax",
            width: "50vmin",
          }}
        >
          <div className="box1" style={{ height: "auto", width: "90%" }}>
            <div className="text10">قوائم مالية</div>
            <div style={{ width: "100%" }} />
            <div className="box12">
              <IconButton component="label">
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => setFinancial_statements(e.target.files[0])}
                />
                <div className="box13">
                  <img src={icon1} alt="icon" />
                </div>
              </IconButton>
              {financial_statements ? (
                <div
                  className="text2 t201 bold"
                  style={{ textAlign: "center" }}
                >
                  {financial_statements.name}
                </div>
              ) : (
                <div className="text2 t201 bold">
                  قم بإرفاق نسخه بصيغه PDF من كشف حساب 3 أشهر
                </div>
              )}
            </div>
            {error && <ErrorText />}
          </div>
          <LoadingButton
            loading={loading}
            onClick={sendApplication}
            variant="contained"
            size="large"
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              bgcolor: "#014E97",
            }}
          >
            <div className="text3">إرسال</div>
          </LoadingButton>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
