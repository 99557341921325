import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Accordion,
  AccordionDetails,
  Grow,
  CircularProgress,
} from "@mui/material";
import icon1 from "../../../assets/Document.png";
import icon2 from "../../../assets/Paper.png";
import icon3 from "../../../assets/Group 1000004117.png";
import icon5 from "../../../assets/sadad.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicAccordion from "./Accordion";
import "./paymentsPage.css";
import DataTable from "./DtatTable";
import { useNavStore, usePayStore } from "../../../store";
import { useEffect } from "react";
import server from "../../../api/server";
import { showDate } from "../../utils/common";

const PaymentsPage = () => {
  const [expanded, setexpanded] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [installment, setInstallment] = useState(null);
  const [installmentLoading, setInstallmentLoading] = useState(true);
  const [subContract, setSubContract] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { setCurrentPayment } = usePayStore();
  // const subContract = installment?.contract.sub_contracts.filter(
  // 	(arr) => arr.type_id === 2
  // );

  const handleExpand = (id) => (_, isExpanded) => {
    setexpanded(isExpanded ? id : null);
    getInstallments(id);
  };

  const handleNavigateToPayment = () => {
    setCurrentPayment(installment.current_installment);
    navigate(`/renter/payment/${installment.current_installment.id}`);
  };

  const getInstallments = async (id) => {
    setInstallmentLoading(true);
    try {
      const { data } = await server.get(
        `/customers/get-contracts-installments/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!data.data.current_installment) throw "the user has no installments";
      // setLoading(false);
      const SubContract = data.data.contract.sub_contracts.filter(
        (arr) => arr.type_id === 2
      );
      setSubContract(SubContract[0]);
      setInstallment(data.data);
      setInstallmentLoading(false);
    } catch (error) {
      setInstallment(null);
      console.log(error);
      setInstallmentLoading(false);
    }
  };

  const getContracts = async () => {
    try {
      const { data } = await server.get("/customers/get-contracts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setContracts(data.data);
      if (data.data.length > 0) {
        getInstallments(data.data[0].id);
        setexpanded(data.data[0].id);
      } else {
        setInstallmentLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "الدفعات المجدولة",
      },
    });
    getContracts();
  }, []);
  return (
    <div style={{ padding: "10px", width: "90vw" }}>
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2} sx={{ direction: "rtl" }}>
          <Grid item xs={12}>
            <div
              className="box10"
              style={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <div className="box11" style={{ width: "100%" }}>
                <div className="text8 center">الدفعات المجدولة</div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{ pt: "0px" }}
            className="pt0"
          >
            <Grid item xs={12} direction={"column"} width={"100%"}>
              <div className="box1" style={{ borderRadius: "8px" }}>
                <div
                  className="box10"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <div className="box11" style={{ width: "100%" }}>
                    <div className="text8 center">العقود</div>
                  </div>
                </div>
                {contracts.length === 0 ? (
                  <div>لاتوجد عقود</div>
                ) : (
                  <BasicAccordion
                    data={contracts}
                    expanded={expanded}
                    handleChange={handleExpand}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          {installmentLoading ? (
            <Grid item xs={8}>
              <div
                style={{
                  width: "auto",
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Grid>
          ) : (
            <Grid
              item
              lg={8}
              md={8}
              sm={12}
              xs={12}
              sx={{ marginLeft: "0" }}
              className="pt0"
            >
              <Grid item xs={12}>
                <div
                  className="box1"
                  style={{
                    height: "auto",
                    minWidth: "auto",
                    borderRadius: "8px",
                  }}
                >
                  <div className="box43">
                    <div className="text8 text-r">
                      الدفعات المجدولة والاقساط
                    </div>

                    <div className="text11 text-r">
                      تفاصيل دفع الاقساط لعقد الإستئجار
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    {installment ? (
                      <>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <div className="box44">
                            <img src={icon1} alt="icon" />
                            <div className="box40">
                              <div className="text11">رقم العقد</div>
                              <div className="text10 text-r text-b">
                                {subContract.ejar_no}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                          <div className="box44">
                            <img src={icon2} alt="icon" />
                            <div className="box40">
                              <div className="text11 text-r">
                                إجمالي عدد الاقساط
                              </div>
                              <div className="text10 text-r text-b">
                                {installment.total_installments} قسط \ أقساط
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                          <div className="box44">
                            <img src={icon3} alt="icon" />
                            <div className="box40">
                              <div className="text11 text-r">
                                الأقساط المدفوعه
                              </div>
                              <div className="text10 text-r text-b">
                                {installment.paid_installments} قسط \ أقساط
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </>
                    ) : (
                      <div style={{ marginTop: "20px" }}>لاتوجد عقود</div>
                    )}

                    <Grid item xs={12}>
                      <div className="box45">
                        <p className="text8">القسط الحالي</p>
                        {installment ? (
                          <div
                            className="box2 mr0"
                            style={{
                              marginRight: 0,
                              width: "auto",
                            }}
                          >
                            <Grid container>
                              <Grid item lg={3} md={3} sm={6} xs={6}>
                                <p className="text11">التاريخ المحدد للدفع </p>
                                <p className="text12">
                                  {showDate(
                                    installment.current_installment
                                      .date_for_payment
                                  )}
                                </p>
                              </Grid>
                              <Grid item lg={3} md={3} sm={6} xs={6}>
                                <p className="text11">قيمة القسط</p>
                                <p className="text12">
                                  {
                                    installment.current_installment
                                      .deserved_amount
                                  }{" "}
                                  ريال سعودي
                                </p>
                              </Grid>
                              <Grid item lg={3} md={3} sm={6} xs={6}>
                                <p className="text11">الأقساط المدفوعه</p>
                                <p className="text12">
                                  {installment.paid_installments} قسط \ أقساط
                                </p>
                              </Grid>
                              <Grid
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  sx={{
                                    mb: "10px",
                                    width: "100%",
                                    marginInline: "5px",
                                  }}
                                  onClick={handleNavigateToPayment}
                                >
                                  <div className="text13">تقيد كمدفوع</div>
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          <div>لاتوجد أقساط</div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="box1"
                  style={{
                    height: "auto",
                    minWidth: "auto",
                    borderRadius: "8px",
                    overflowX: "auto",
                  }}
                >
                  <div
                    className="box10"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    <div className="box11" style={{ width: "100%" }}>
                      <div className="text8 center">سجل الدفع</div>
                    </div>
                  </div>
                  {/* table */}
                  {installment ? (
                    <DataTable data={installment.contract.installments} />
                  ) : (
                    <div>لاتوجد سجلات</div>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default PaymentsPage;
