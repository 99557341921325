import dayjs from "dayjs";
import server from "../../api/server";
import { useNotificationStore } from "../../store";
import { useEffect, useRef } from "react";
export const showDate = (dateObj) => {
  // const date = new Date(dateObj);
  const date = dayjs(dateObj).format("DD-MM-YYYY").toString();
  // const day = date.date();
  // const month = date.month() + 1;
  // const year = date.year();
  // return `${day}-${month}-${year}`;
  return date;
};

export const redirect = (statusId, currentPageId, navigate) => {
  if (!statusId) return;
  if (statusId === currentPageId) {
    return;
  } else {
    navigate(`/renter/requests/${statusId}`);
  }
};
export const redirectOwner = (statusId, currentPageId, navigate) => {
  if (!statusId) return;
  if (statusId === currentPageId) {
    return;
  } else {
    navigate(`/owner/requests/${statusId}`);
  }
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
