import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PieChartWithCenterLabel from "./PieChart";
import HorizontalLinearStepper from "./Stepper";
import icon1 from "../../../assets/ejar.png";
import icon2 from "../../../assets/file-copy-line 1.png";
import Contract from "./Contract";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Timer from "../../renter/request/Timer";
import { useReqStore } from "../../../store";
import { redirectOwner } from "../../utils/common";
import server from "../../../api/server";
import StepperContract from "../../renter/request/StepperContract";
import OwnerProfile from "../profile/Profile";

const EjarContractOwner = () => {
  // const { req } = useReqStore();
  // const req = JSON.parse(localStorage.getItem("req"));

  const reqId = localStorage.getItem("app-id");
  const { req, setReq } = useReqStore();
  const [loadingR, setLoadingR] = useState(true);
  const token = localStorage.getItem("token");

  const data = [
    { value: 70, label: "", color: "#014E97" },
    { value: 30, label: "", color: "#F1F4FF" },
  ];
  const navigate = useNavigate();

  const date = new Date(req.created_at).toLocaleDateString();

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-applications/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      setLoadingR(false);
      // redirect(data.date.application_status_id, CurrentPageId, navigate);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (req) {
      redirectOwner(req.application_status_id, 5, navigate);
    }
  }, [req]);
  return (
    <div className="req-root vw100">
      {loadingR ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginBlock: "20px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="box10">
            <div className="box11">
              <div className="text8 center">طلباتي</div>
            </div>
            <div>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  // width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={() => navigate("/owner/requests/new")}
              >
                <div className="text3">إنشاء طلب جديد</div>
              </Button>
            </div>
          </div>

          <div className="box14" style={{ height: "auto" }}>
            <Grid
              container
              sx={{
                direction: "rtl",
                borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
              }}
            >
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <div className="box11">
                  <div className="text22 center">{`الطلب #${req.serial_number}`}</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {date}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={0}
                xs={0}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                  },
                }}
              >
                <div className="box11">
                  <div className="text9 center">حالة الطلب</div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="shape2"></div>
                    <p className="text22 text-r" style={{ marginTop: "5%" }}>
                      إبرام العقد على منصة إيجار
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} />
                <div className="text9 center">مكتمل بنسبة</div>
                <PieChartWithCenterLabel data={data} />
              </Grid>
            </Grid>
            <div className="box14" />
            <Grid
              container
              sx={{
                direction: "rtl",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                padding: "10px",
                borderRadius: "8px",
                // display: "inline-block",
              }}
            >
              {/* <Grid item lg={1} md={1} sm={12} xs={12}>
						<div className="text9 center">حالة الطلب: </div>
					</Grid> */}
              <Grid item md={12} sm={12} xs={12}>
                <HorizontalLinearStepper
                  acitve={4}
                  statusName={
                    req.contracts?.status.name ||
                    "ابرام العقد بين قسطار والمالك"
                  }
                />
              </Grid>
            </Grid>
            <div
              className="box2"
              style={{
                // justifyContent: "space-between",
                border: "none",
                marginRight: "0px",
                flexDirection: "column",
                direction: "rtl",
              }}
            >
              <div
                className="text8"
                style={{
                  width: "100%",
                  padding: "5px",
                  borderTop: "10px",
                  marginRight: "0px",
                }}
              >
                الوسيط العقاري
              </div>
              <div className="text11" style={{ marginRight: "0px" }}>
                معلومات الوسيط العقاري المسئول عن إبرام العقد
              </div>
              {/* {req.contracts && (
                <Grid
                  container
                  sx={{
                    direction: "rtl",
                    border: "1px solid var(--form-stroke, #E7E7E7)",
                    padding: "10px",
                    borderRadius: "8px",
                    mt: 2,
                    // display: "inline-block",
                  }}
                >
                  <Grid item xs={12}>
                    <StepperContract
                      acitve={req.contracts.status_id - 1}
                      error={false}
                    />
                  </Grid>
                </Grid>
              )} */}
              {req.broker_id ? (
                <Box sx={boxStyle1} style={{ flexDirection: "column" }}>
                  <Box sx={boxStyle3}>
                    <div>
                      <div className="text3">الوسيط</div>
                      <div className="text8">
                        {req.broker?.name || "لايوجد"}
                      </div>
                    </div>
                  </Box>
                  <Box sx={boxStyle3}>
                    <div>
                      <div className="text3">رقم الجوال</div>
                      <div className="text8">
                        {req.broker?.phone || "لايوجد"}
                      </div>
                    </div>
                  </Box>
                </Box>
              ) : (
                <div className="text8">
                  جارِ البحث عن وسيط
                  <div
                    className="loading-dot"
                    style={{ direction: "revert-layer" }}
                  >
                    ...
                  </div>
                </div>
              )}
            </div>
            <Grid
              container
              sx={{
                direction: "rtl",
                padding: "10px",
                // display: "inline-block",
              }}
            >
              <div
                className="box2"
                style={{
                  justifyContent: "space-between",
                  border: "none",
                  marginRight: "0px",
                }}
              >
                <Box>
                  <div
                    className="text8"
                    style={{
                      width: "100%",
                      paddingBlock: "5px",
                      borderTop: "10px",
                      marginRight: "0px",
                    }}
                  >
                    العقود على منصه إيجار
                  </div>
                  <div className="text11" style={{ marginRight: "0px" }}>
                    يقوم الوسيط بإبرام العقود حاليا على منصه إيجار
                  </div>
                </Box>

                <img
                  src={icon1}
                  alt="icon"
                  height={120}
                  style={{ marginTop: "-20px" }}
                />
              </div>

              {/* <div
                className="box2 fx-dir mr0"
                style={{
                  justifyContent: "start",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  marginBlock: "5px",
                }}
              >
                <div
                  className="text11"
                  style={{
                    // width: "100%",
                    padding: "5px",
                    borderTop: "10px",
                    marginRight: "0px",
                  }}
                >
                  رقم العقد على منصه ايجار
                </div>
                <div className="box32 s09">
                  <div className="box31">
                    <div className="box30">454354677765532</div>
                  </div>
                  <div className="box33">
                    <img src={icon2} alt="icon" />
                  </div>
                </div>
              </div> */}
            </Grid>
          </div>
          <OwnerProfile inReq={true} />
          {/* <div
            className="box2 box34 mr0"
            style={{ justifyContent: "space-between" }}
          >
            <Grid
              container
              direction={"row-reverse"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={5}
                sx={{ justifyContent: "start", direction: "rtl" }}
              >
                <p className="text8">الوقت المتبقي لإكمال الطلب</p>
                <p className="text11">يجب إكمال العقد خلال الفترة المحددة</p>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Timer expiresIn={100000000} />
              </Grid>
            </Grid>
          </div> */}
        </>
      )}
    </div>
  );
};

export default EjarContractOwner;

const boxStyle1 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "10px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#F4F7FF",
  display: "flex",
  justifyContent: "space-between",
};
const boxStyle3 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #fff)",
  padding: "10px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#F4F7FF",
  display: "flex",
  justifyContent: "space-between",
};
