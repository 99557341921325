import icon1 from "../../../assets/Frame.png";
import icon3 from "../../../assets/Frame (2).png";
import icon4 from "../../../assets/Vector.png";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";

const MobileMenu = () => {
	const [open, setOpen] = useState({});
	const routes = [
		{
			page: "لوحة المعلومات",
			id: "/owner/dashboard",
			icon: (
				<img
					src={icon1}
					style={{ width: "15px", height: "15px" }}
					alt="icon"
				/>
			),
		},
		{
			page: "طلباتي",
			id: "/owner/requests",
			icon: <ArticleIcon style={{ width: "15px", height: "15px" }} />,
		},
		// {
		// 	page: "الدفعات المجدولة",
		// 	id: "/renter/payments",

		// 	icon: (
		// 		<img
		// 			src={icon3}
		// 			alt="icon"
		// 			style={{ width: "15px", height: "15px" }}
		// 		/>
		// 	),
		// },
		{
			page: "المساعدة والدعم",
			id: "/owner/support",

			icon: (
				<img
					src={icon4}
					alt="icon"
					style={{ width: "15px", height: "15px" }}
				/>
			),
		},
	];

	let navigate = useNavigate();

	const handleClick = (id) => {
		setOpen(
			Object.keys(open).forEach((key) => {
				open[key] = false;
			})
		);
		setOpen({ ...open, [id]: !open[id] });
		navigate(id);
		console.log(open);
	};

	const sidebarItems = () => {
		return routes.map(({ id, page, icon, subPage, path }) => {
			const openList = open[id] || false;

			const active = () => {
				return openList ? "active-mobile" : "reverse-mobile";
			};

			const activeIcone = () => {
				return openList
					? "active-icone-mobile"
					: "reverse-icone-mobile";
			};

			return (
				<div
					key={id}
					style={{
						display: "flex",
						flexDirection: "column",
						marginInline: 5,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<ListItemButton
						className={active()}
						onClick={() => handleClick(id, path)}
						sx={{
							width: "30px",
							height: "30px",
							border: "1px solid rgba(179, 237, 255, 0.25)",
							background: "rgba(255,255, 255, 0.20)",
						}}
					>
						<div className={activeIcone()}>{icon}</div>
					</ListItemButton>
					<ListItemText
						sx={{
							".MuiTypography-root": {
								fontFamily: "font",
								// fontWeight: "700",
								fontSize: "0.8rem",
							},
							color: "#fff",
						}}
						className={`direction `}
						primary={page}
					/>
				</div>
			);
		});
	};
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "space-around",
				alignItems: "center",
				flexDirection: "row",
				paddingBottom: "20px",
			}}
		>
			{sidebarItems()}
		</div>
	);
};

export default MobileMenu;
