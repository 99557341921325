import image1 from "../../../assets/image1.svg";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import { Grid, FormControl, Select } from "@mui/material";
import { useUserStor } from "../../../store";
import { useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import { Button } from "@mui/material";
import MobileMenu from "./MobileMenu";
import LoginAs from "./LoginAs";

export default function MobileHeader() {
  const [owner, setOwner] = React.useState(20);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorLoginAs, setAnchorLoginAs] = React.useState(null);
  const [openLoginAs, setOpenLoginAs] = React.useState(false);

  const handleClickLoginAs = (event) => {
    setAnchorLoginAs(event.currentTarget);
    setOpenLoginAs(true);
  };

  const { isOwner, setIsOwner, unSetOwner } = useUserStor();

  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleChange = (event) => {
    if (event.target.value == 1) {
      useUserStor.setState({ isOwner: 1 });
      navigate("/owner/dashboard");
    } else if (event.target.value == 0) {
      useUserStor.setState({ isOwner: 0 });
    }
    // console.log("f0 " + isOwner);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      // anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      // anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        direction: "rtl",
        // bgcolor: " #004A98",
        width: "100%",
        display: { md: "none" },
        background: "linear-gradient(163deg, #004A98 2.33%, #350055 153.89%)",
        // position: "absolute",
        // top: 0,
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        variant="outlined"
        elevation={0}
        style={{
          backgroundColor:
            "linear-gradient(163deg, #004A98 2.33%, #350055 153.89%)",
        }}
        // className="mobile-root"
      >
        <Toolbar>
          <img
            src={image1}
            alt="logo"
            style={{
              // maxWidth: "-webkit-fill-available",
              marginLeft: "8vw",
              marginTop: "20px",
              marginBottom: "10px",
              width: "60px",
            }}
          />

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "flex",
                marginLeft: "20px",
              },
            }}
          >
            <Grid container direction={"column"} sx={{ marginInline: "20px" }}>
              <Grid>
                <div
                  className="text6"
                  style={{
                    color: "#fff",
                    fontSize: "0.65rem",
                  }}
                >
                  محمد عبد الرحمن
                </div>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button onClick={handleClickLoginAs} startIcon={<ExpandMore />}>
                  <div
                    className="text7"
                    style={{
                      color: "#fff",
                      fontSize: "0.6rem",
                    }}
                  >
                    مالك
                  </div>
                </Button>
              </Grid>
            </Grid>

            <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
        <div>
          <MobileMenu />
        </div>
      </AppBar>
      {/* {renderMenu} */}
      <LoginAs
        open={openLoginAs}
        setOpen={setOpenLoginAs}
        anchorEl={anchorLoginAs}
      />
    </Box>
  );
}
