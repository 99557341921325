import {
  Button,
  Grid,
  TextField,
  styled,
  IconButton,
  CircularProgress,
  Box,
  Checkbox,
} from "@mui/material";
import icon1 from "../../../assets/upload.png";
import { useNavigate } from "react-router-dom";
import { useNavStore, useUserStor, useReqStore } from "../../../store";
import { useEffect, useState } from "react";
import server from "../../../api/server";
import SuccessApplication from "./SuccessApplication";
import LoadingButton from "@mui/lab/LoadingButton";
import { PhoneInput } from "react-international-phone";
import { Check, Close } from "@mui/icons-material";
import Selector from "../../utils/Selector";
import AlertMessage from "../../utils/AlertMessage";
import { color } from "framer-motion";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

const NewRenterReq = () => {
  const navigate = useNavigate();

  // company info
  const [registration_number, setRegistration_number] = useState("");
  const [company_owner_name, setCompany_owner_name] = useState("");
  const [branch_number, setBranch_number] = useState("");
  const [company_name, setCompany_name] = useState("");

  const [execute_status, setExecute_status] = useState("NOW");
  const [execute_date, setExecute_date] = useState(dayjs(Date.now()));

  //property info
  const [deedNo, setDeedNo] = useState("");
  const [propertyType, setPropertyType] = useState(1);
  const [property_owner_name, setProperty_owner_name] = useState("");
  const [property_owner_phone, setProperty_owner_Phone] = useState("");
  const [origin_rent_amount, setRent_amount] = useState(null);
  const [property_location, setProperty_location] = useState("");
  const [property_neighbourhood, setProperty_neighbourhood] = useState("");
  const [property_no, setProperty_no] = useState("");
  const [property_img, setproperty_img] = useState(null);
  const [short_code, setShort_code] = useState("");

  const [financial_statements, setFinancial_statements] = useState(null);

  const [successDeed, setSuccessDeed] = useState(false);
  const [failedDeed, setFailedDeed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingDeed, setLoadingDeed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appId, setAppId] = useState(null);
  const [propertyUse, setPropertyUse] = useState(1);

  const [company, setCompany] = useState({});
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [failedCompany, setFailedCompany] = useState(false);
  const [successCompany, setSuccessCompany] = useState(false);
  const [authError, setAuthError] = useState(false);

  const [error, setError] = useState();
  const [openAlertError, setOpenAlertError] = useState(false);

  // const { token } = useUserStor();
  const token = localStorage.getItem("token");
  const { setReq } = useReqStore();

  const formdata = new FormData();
  //company info
  formdata.append("company_name", company_name);
  formdata.append("company_owner_name", company_owner_name);
  formdata.append("registration_number", registration_number);
  formdata.append("branch_number", branch_number);

  execute_date &&
    formdata.append("execute_date", dayjs(execute_date).format("YYYY/MM/DD"));

  //property info
  formdata.append("deed_no", deedNo);
  formdata.append("building_type_id", propertyType);
  formdata.append("property_owner_name", property_owner_name);
  formdata.append("property_owner_phone", property_owner_phone.substring(1));
  formdata.append("origin_rent_amount", origin_rent_amount);
  formdata.append("property_location", property_location);
  formdata.append("property_neighbourhood", property_neighbourhood);
  formdata.append("property_no", property_no);
  property_img && formdata.append("property_img", property_img);

  formdata.append("short_code", short_code);
  // financial_statements.map((file) => {
  formdata.append("financial_statements", financial_statements);
  // });

  // formdata.append("company_register_id", company.id);
  // formdata.append("deed_id", deed?.id);
  // formdata.append("building_use_id", propertyUse);

  const sendApplication = async () => {
    if (
      !registration_number ||
      !company_name ||
      // !company_owner_name ||
      // !branch_number ||
      // !deedNo ||
      !property_owner_name ||
      !property_owner_phone ||
      !property_location ||
      !origin_rent_amount ||
      !financial_statements ||
      // !property_neighbourhood ||
      // !property_no ||
      // !short_code ||
      phoneError
    ) {
      validateFn();
      return;
    }
    setLoading(true);
    try {
      const { data } = await server.post(
        "/customers/store-rental-application",
        formdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAppId(data.data.id);
      setReq(data.data);
      localStorage.setItem("app-id", data.data.id);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response?.status === 422) {
        setServerError(error.response.data.error);
      } else if (error.response?.status === 500) {
        setError(error.response.data?.message);
        setOpenAlertError(true);
      }
    }
  };

  // const getDeed = async () => {
  //   if (deedNo === "") return;
  //   setLoadingDeed(true);
  //   setSuccessDeed(false);
  //   setFailedDeed(false);
  //   setAuthError(false);
  //   try {
  //     const { data } = await server.get(
  //       `/customers/get-deed-details/${deedNo}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setDeed(data.data);
  //     setLoadingDeed(false);
  //     setSuccessDeed(true);
  //   } catch (error) {
  //     console.log(error);

  //     setLoadingDeed(false);
  //     setDeed({});
  //     setFailedDeed(true);
  //     // setError(Object.keys(error.response.data.error).toString());
  //   }
  // };
  // const getCompany = async () => {
  //   if (registration_number === "") return;
  //   setLoadingCompany(true);
  //   setSuccessCompany(false);
  //   setFailedCompany(false);
  //   try {
  //     const { data } = await server.get(
  //       `/customers/get-company-details/${registration_number}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setCompany(data.data);
  //     setLoadingCompany(false);
  //     setSuccessCompany(true);
  //   } catch (error) {
  //     if (error.response.status === 401) {
  //       setAuthError(true);
  //     }
  //     console.log(error);
  //     setLoadingCompany(false);
  //     setCompany({});
  //     setFailedCompany(true);
  //     // setError(Object.keys(error.response.data.error).toString());
  //   }
  // };

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     getDeed();
  //   }, 1500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [deedNo]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     getCompany();
  //   }, 1500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [registration_number]);

  const AuthErrorText = () => {
    return (
      <div
        className="text2"
        style={{ color: "red", marginTop: "0px", width: "100%" }}
      >
        لا يمكنك التسجيل بهذا الرقم
      </div>
    );
  };

  //validations
  const [serverError, setServerError] = useState({});
  const serverErrorMessage = (field) => {
    if (serverError[field]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {serverError[field][0]}
        </div>
      );
    }
  };
  const validateFn = () => {
    const feilds = {
      registration_number,
      company_name,
      // company_owner_name,
      // branch_number,
      // deedNo,
      property_owner_name,
      property_owner_phone,
      origin_rent_amount,
      financial_statements,
      property_location,
      // property_neighbourhood,
      // property_no,
      // short_code,
    };
    for (const [key, value] of Object.entries(feilds)) {
      if (!value) {
        setServerError((prev) => ({ ...prev, [key]: ["مطلوب"] }));
      }
      // if (key === "financial_statements") {
      //   if (value.length === 0) {
      //     setServerError((prev) => ({ ...prev, [key]: ["مطلوب"] }));
      //   }
      // }
    }
  };

  //vaildations
  const [phoneError, setPhoneError] = useState(false);

  const validatePhone = () => {
    if (property_owner_phone.length < 10) {
      setPhoneError(true);
    }
  };

  const ErrorTextPhone = () => {
    return (
      <div
        className="text2"
        style={{ color: "red", marginTop: "0px", width: "100%" }}
      >
        الرقم غير صحيح
      </div>
    );
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "استمارة طلب إيجار",
        items: [{ text: "طلبات الإيجار", path: "/renter/requests" }],
      },
    });
  }, []);

  const handleSendReq = () => {
    setPhoneError(false);
    setServerError({});
    validatePhone();
    sendApplication();
  };

  const removeFile = (name) => {
    const newFiles = (array) => {
      return array.filter((item) => item.name !== name);
    };
    setFinancial_statements((prev) => newFiles(prev));
  };

  const onChoseFile = (file) => {
    const isValidFileUploaded = (file) => {
      const validExtensions = ["pdf"];
      const fileExtension = file.type.split("/")[1];
      return validExtensions.includes(fileExtension);
    };

    if (!isValidFileUploaded(file)) {
      setError(" pdf الملف يجب ان يكون بصيغة");
      setOpenAlertError(true);
      return;
    }

    setFinancial_statements(file);

    // Object.values(file).map((f) => {
    //   if (!isValidFileUploaded(f)) {
    //     setError(" pdf الملف يجب ان يكون بصيغة");
    //     setOpenAlertError(true);
    //     removeFile(f.name);
    //   }
    // });
  };

  const onChoseImage = (img) => {
    const isValidFileUploaded = (file) => {
      const validExtensions = ["png", "jpeg", "jpg"];
      const fileExtension = file.type.split("/")[1];
      return validExtensions.includes(fileExtension);
    };

    if (!isValidFileUploaded(img)) {
      setError("png, jpeg, jpg يجب ان تكون الصورة بصيغة");
      setOpenAlertError(true);
      return;
    }

    setproperty_img(img);
  };

  const RenderFiles = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {financial_statements.map((file) => {
          // console.log(financial_statements);
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "50px",
                width: "90%",
                backgroundColor: "#014e97",
                mt: 1,
                p: 1,
              }}
              key={Math.random()}
            >
              <div
                style={{
                  color: "#fff",
                  overflow: "hidden",
                  maxWidth: "100%",
                  maxHeight: "20px",
                }}
              >
                {file.name}
              </div>
              <Button sx={{ p: 0 }} onClick={() => removeFile(file.name)}>
                <Close sx={{ color: "#fff" }} />
              </Button>
            </Box>
          );
        })}
      </Box>
    );
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div style={{ marginInline: "10px", width: "95%" }}>
      <SuccessApplication open={success} setOpen={setSuccess} />
      <div className="box10">
        <div className="" style={{ textAlign: "right", direction: "rtl" }}>
          <div className="text8 center text-r">طلب إستئجار</div>
        </div>
      </div>
      <Grid
        container
        // direction={"row-reverse"}
        // justifyContent={"space-between"}
        spacing={2}
        sx={{ direction: "rtl" }}
      >
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="box1">
              <div
                className="text2 t201 bold slb-text"
                style={{ color: "#1a1a1a" }}
              >
                رقم السجل <span style={{ color: "red" }}>*</span>
                {/* {loadingCompany && <CircularProgress size="0.8rem" />}
                {successCompany && <Check color="success" fontSize="1.5rem" />}
                {failedCompany && <Close color="error" fontSize="1.2rem" />} */}
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={registration_number}
                name="registration_number"
                onChange={(e) => setRegistration_number(e.target.value)}
              />
              {serverErrorMessage("registration_number")}
              {authError && <AuthErrorText />}
              <div
                className="text2 t201 bold slb-text"
                style={{
                  color: "#1a1a1a",
                  // display: successCompany ? "flex" : "none",
                }}
              >
                اسم الشركة <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontFamily: "font-normal",
                  // display: successCompany ? "flex" : "none",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                name="company_name"
                value={company_name}
                onChange={(e) => setCompany_name(e.target.value)}
              />
              <div
                className="text2 t201 bold slb-text"
                style={{
                  color: "#1a1a1a",
                  // display: successCompany ? "flex" : "none",
                }}
              >
                اسم مالك الشركة
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontFamily: "font-normal",
                  // display: successCompany ? "flex" : "none",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                name="company_owner_name"
                value={company_owner_name}
                onChange={(e) => setCompany_owner_name(e.target.value)}
              />
              {/* {errorList.company_name && <ErrorText />} */}

              <div
                className="text2 t201 bold slb-text"
                style={{ color: "#1a1a1a" }}
              >
                عدد الفروع
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                onChange={(e) => setBranch_number(e.target.value)}
                value={branch_number}
                name="branch_number"
              />
              {serverErrorMessage("branch_number")}
              <Box sx={{ mt: 1 }}>
                <Checkbox
                  value={execute_status}
                  onChange={(e) =>
                    setExecute_status((pre) =>
                      pre === "NOW" ? "LATER" : "NOW"
                    )
                  }
                />
                طلب مستقبلي
              </Box>
            </div>
          </Grid>
          {execute_status === "LATER" && (
            <Grid xs={12}>
              <div
                className="box1"
                style={{ marginBottom: 30, display: "block" }}
              >
                <div
                  className="text10"
                  style={{
                    marginRight: "0px",
                    fontSize: "1rem",
                    marginBottom: "10px",
                  }}
                >
                  موعد انشاء الطلب
                </div>
                <div style={{ width: "100%" }}></div>
                <div className="text2" style={{ marginBottom: 5 }}>
                  التاريخ
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MobileDatePicker"]}>
                    <MobileDatePicker
                      sx={{
                        backgroundColor: "#F1F4FF",
                        width: "100%",
                        // direction: "rtl",
                        color: "GrayText",
                        fontFamily: "font",
                        padding: 0,
                      }}
                      size="small"
                      // value={dayjs(user.date)}
                      value={execute_date}
                      className="textInput1"
                      variant="outlined"
                      format="DD-MM-YYYY"
                      onChange={(newValue) => setExecute_date(newValue)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: false,
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {serverErrorMessage("execute_date")}
              </div>
            </Grid>
          )}
          <Grid item lg={12}>
            <div className="box1" style={{ marginBottom: 30 }}>
              <div
                className="text10"
                style={{
                  marginRight: "0px",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                بيانات العقار
              </div>
              <div style={{ width: "100%" }}></div>
              <div className="text2 t201 bold slb-text">
                رقم الصك
                {/* {loadingDeed && <CircularProgress size="0.8rem" />}
                {successDeed && <Check color="success" fontSize="1.5rem" />}
                {failedDeed && <Close color="error" fontSize="1.2rem" />} */}
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                onChange={(e) => setDeedNo(e.target.value)}
                value={deedNo}
                name="deedNo"
              />
              {serverErrorMessage("deedNo")}
              <Grid container spacing={2}>
                {/* <Grid item xs={12} md={6}>
                  <div className="text2 t201 bold slb-text">استخدام العقار</div>
                  <Selector
                    value={propertyUse}
                    setValue={setPropertyUse}
                    api="building-uses"
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <div className="text2 t201 bold slb-text">
                    نوع العقار <span style={{ color: "red" }}>*</span>
                  </div>
                  <Selector
                    value={propertyType}
                    setValue={setPropertyType}
                    api="building-types"
                  />
                </Grid>
              </Grid>
              <div className="text2 t201 bold slb-text">
                {" "}
                رقم جوال المالك <span style={{ color: "red" }}>*</span>
              </div>

              <PhoneInput
                inputClassName="c-input"
                defaultCountry="sa"
                value={property_owner_phone}
                onChange={(e) => setProperty_owner_Phone(e)}
                style={{ width: "100%", direction: "ltr" }}
                name="property_owner_phone"
              />
              {phoneError && <ErrorTextPhone />}

              <div className="text2 t201 bold slb-text">
                مبلغ الاإيجار <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => setRent_amount(e.target.value)}
                onWheel={(e) => e.target.blur()}
                value={origin_rent_amount}
              />
              {serverErrorMessage("origin_rent_amount")}
              <div
                className="text2 t201 bold slb-text"
                style={
                  {
                    // display: successDeed ? "flex" : "none",
                  }
                }
              >
                اسم مالك العقار <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                  // display: successDeed ? "flex" : "none",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={property_owner_name}
                onChange={(e) => setProperty_owner_name(e.target.value)}
                name="property_owner_name"
              />
              {serverErrorMessage("property_owner_name")}
              <div
                className="text2 t201 bold slb-text"
                style={
                  {
                    // display: successDeed ? "flex" : "none",
                  }
                }
              >
                المدينة <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={property_location}
                name="property_location"
                onChange={(e) => setProperty_location(e.target.value)}
              />
              {serverErrorMessage("property_location")}
              <div className="text2 t201 bold slb-text">العنوان المختصر</div>
              <TextField
                // value={deed?.regionCode || ""}

                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={short_code}
                name="short_code"
                onChange={(e) => setShort_code(e.target.value)}
                type="number"
              />
              {serverErrorMessage("short_code")}
              <div
                className="text2 t201 bold slb-text"
                style={
                  {
                    // display: successDeed ? "flex" : "none",
                  }
                }
              >
                الحي
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                  // display: successDeed ? "flex" : "none",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={property_neighbourhood}
                name="property_neighbourhood"
                onChange={(e) => setProperty_neighbourhood(e.target.value)}
              />
              {serverErrorMessage("property_neighbourhood")}
              <div
                className="text2 t201 bold slb-text"
                style={
                  {
                    // display: successDeed ? "flex" : "none",
                  }
                }
              >
                رقم المبنى
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                  // display: successDeed ? "flex" : "none",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={property_no}
                name="property_no"
                onChange={(e) => setProperty_no(e.target.value)}
              />
              {serverErrorMessage("property_no")}
            </div>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ marginLeft: "0px" }}>
          <div className="box1" style={{ height: "auto" }}>
            <div className="text10">
              قوائم مالية <span style={{ color: "red" }}> * </span>
            </div>
            <div style={{ width: "100%" }} />
            <div className="box12">
              <IconButton component="label">
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => onChoseFile(e.target.files[0])}
                  name="financial_statements"
                  accept=".pdf"
                />
                <div className="box13">
                  <img src={icon1} alt="icon" />
                </div>
              </IconButton>
              {financial_statements ? (
                <div
                  className="text2 t201 bold"
                  style={{ textAlign: "center" }}
                >
                  {/* <RenderFiles /> */}
                  {financial_statements.name}
                </div>
              ) : (
                <div className="text2 t201 bold">
                  قم بإرفاق نسخه بصيغه PDF من القوائم المالية ل3 أشهر أو كشف
                  حساب
                </div>
              )}
            </div>
          </div>
          {serverErrorMessage("financial_statements")}
          <div className="box1" style={{ height: "auto" }}>
            <div className="text10">صورة المبنى </div>
            <div style={{ width: "100%" }} />
            <div className="box12">
              <IconButton component="label">
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => onChoseImage(e.target.files[0])}
                />
                <div className="box13">
                  <img src={icon1} alt="icon" />
                </div>
              </IconButton>
              {property_img ? (
                <div
                  className="text2 t201 bold"
                  style={{ textAlign: "center" }}
                >
                  {property_img.name}
                </div>
              ) : (
                <div className="text2 t201 bold">قم بارفاق صورة من المبنى</div>
              )}
            </div>
            {serverErrorMessage("property_img")}
          </div>
          <LoadingButton
            variant="contained"
            size="large"
            loading={loading}
            sx={{
              marginTop: "10px",
              // marginLeft: "10px",
              // width: "10vw",
              bgcolor: "#014E97",
            }}
            onClick={handleSendReq}
            fullWidth
          >
            <div className="text3">إرسال الطلب</div>
          </LoadingButton>
        </Grid>
      </Grid>

      <AlertMessage
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={error}
        type={"error"}
      />
    </div>
  );
};

export default NewRenterReq;
