import { Grid, Button, CircularProgress } from "@mui/material";
import "./support.css";
import SearchBar from "./SearchBar";
import DataTable from "./DataTable";
import { useNavigate } from "react-router-dom";
import { useNavStore, useUserStor } from "../../../store";
import { useEffect } from "react";
import server from "../../../api/server";
import { useState } from "react";

const Support = () => {
	const navigate = useNavigate();
	const [tickets, setTickets] = useState(null);
	const [AllTickets, setAllTickets] = useState(null);
	const [searchString, setSearchString] = useState("");
	// const { token } = useUserStor();

	const token = localStorage.getItem("token");

	const getData = async () => {
		try {
			const { data } = await server.get("/customers/get-tickets", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setTickets(data.data);
			setAllTickets(data.data);
			console.log(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		useNavStore.setState({
			nav: {
				text: "المساعدة والدعم",
			},
		});
		getData();
	}, []);
	useEffect(() => {
		if (AllTickets) {
			setTickets(
				AllTickets.filter((ticket) =>
					ticket.title.includes(searchString)
				)
			);
		}
	}, [searchString]);

	return (
		<div style={{ overflowX: "auto", width: "100%" }}>
			{/* <div style={{ width: "100%" }}> */}
			<Grid item xs={12} sx={{ direction: "rtl" }}>
				<div
					className="box10"
					style={{
						display: "flex",
						justifyContent: "start",
						width: "100%",
					}}
				>
					<div className="box11" style={{ width: "100%" }}>
						<div className="text8 center">المساعدة والدعم</div>
					</div>
				</div>
			</Grid>
			<div className="box14" style={{ height: "auto" }}>
				<div className="box10 box50">
					<div className="box11 box51">
						<SearchBar setSearchString={setSearchString} />
						<Button
							variant="contained"
							size="small"
							sx={{
								marginRight: "10px",
								// width: "10vw",
								bgcolor: "#014E97",
								height: "45px",
							}}
						>
							<div className="text3">بحث</div>
						</Button>
					</div>
					<div className="box52">
						<Button
							variant="contained"
							size="large"
							sx={{
								marginLeft: "10px",
								width: "10vw",
								bgcolor: "#014E97",
							}}
							onClick={() =>
								navigate("/renter/support/newticket")
							}
						>
							<div className="text3">فتح تذكرة جديدة</div>
						</Button>
					</div>
				</div>
				<div
					className="box1"
					style={{
						height: "auto",
						minWidth: "auto",
						borderRadius: "8px",
						overflowX: "auto",
						maxWidth: "80vw",
						justifyContent: "center",
						display: "flex",
						width: "100%",
					}}
				>
					{tickets ? (
						<>
							{tickets.length === 0 ? (
								<div>لاتوجد تذاكر</div>
							) : (
								<DataTable data={tickets} />
							)}
						</>
					) : (
						<div style={{ textAlign: "center" }}>
							<CircularProgress />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Support;
