import { Button, Grid, TextField, styled, InputBase, Box } from "@mui/material";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import "./login.css";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useUserStor } from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const Register = () => {
  const [focused, setFocused] = useState(false);
  const [loading, setloading] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [identity_number, setIdentity_number] = useState("");
  const [identity_numberError, setIdentity_numberError] = useState(false);
  const { setOtpData } = useUserStor();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const isSignedIn = (user) => {
    if (user?.customer_type_id === 1) {
      navigate("/home");
    } else if (user?.customer_type_id === 2) {
      navigate("/owner/dashboard");
    } else {
      return;
    }
  };

  const getUser = async () => {
    try {
      const { data } = await server.get("/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      isSignedIn(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnfocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    setFocused(false);
  };

  const handleOnclickContinue = async () => {
    setIsNotValid(false);
    setIdentity_numberError(false);
    if (!identity_number) {
      setIdentity_numberError(true);
      return;
    }
    setloading(true);
    try {
      const res = await server.post("/customers/get-otp", {
        phone: phoneNumber.substring(1),
        identity_number,
      });
      console.log(res.data);
      setOtpData({ ...res.data.data, identity_number });
      // if (res.data.data?.is_register === 0) {
      //   navigate("/nafath-check");
      // } else {
      // navigate("/otp");
      // }
      navigate("/otp");
    } catch (err) {
      setloading(false);
      if (err.response.status === 401) {
        setErrorMessage("عفواً، الحساب غير نشط");
      } else {
        setErrorMessage(err.response.data?.error.phone[0]);
        console.log(err.response.status);
      }
      setIsNotValid(true);
    }
  };
  const TextLabel = ({ children }) => {
    const LabelText = useMemo(() => {
      // console.log(focused);
      return (
        <div
          className="text2"
          style={{ color: focused ? "#004A98" : "#4F4F4F" }}
        >
          {children}
        </div>
      );
    }, [focused]);
    return LabelText;
  };

  const showErrorText = () => {
    return (
      isNotValid && (
        <div className="text2" style={{ color: "red", marginTop: "0px" }}>
          {errorMessage || "الرقم غير صحيح"}
        </div>
      )
    );
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${image22})`,
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
      }}
    >
      <Grid
        container
        className="login-container"
        sx={{ flexDirection: "row-reverse" }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="image-container"
        >
          <img className="image1" src={image1} alt="Qistar" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="login-root"
        >
          <div className="logIn" style={{ alignItems: "center" }}>
            <img src={image2} className="image2" alt="Qistar" />
            <div className="text1">انشاء حساب</div>
            <Box
              sx={{
                color: "#014a97",
                fontFamily: "font-normal",
                display: "flex",
                gap: 1,
                direction: "rtl",
                alignItems: "center",
              }}
            >
              <div>مسجل مسبقاَ؟</div>
              <Button
                sx={{ fontFamily: "font-normal", color: "#014a97", p: 0 }}
                onClick={() => navigate("/")}
              >
                سجل دخولك
              </Button>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextLabel>رقم الجوال</TextLabel>
            </Box>

            <PhoneInput
              inputClassName="c-input"
              defaultCountry="sa"
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              style={{ width: "100%" }}
            />
            {showErrorText()}
            <Box sx={{ width: "100%" }}>
              <TextLabel>رقم الهوية / الإقامة</TextLabel>
            </Box>

            <TextField
              fullWidth
              value={identity_number}
              error={identity_numberError}
              name="identity_number"
              onChange={(e) => setIdentity_number(e.target.value)}
            />
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{ backgroundColor: "#004A98", width: "100%" }}
              onClick={handleOnclickContinue}
            >
              <div className="text3"> متابعة</div>
            </LoadingButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Register;
