import React from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import PieChartWithCenterLabel from "./PieChart";
import HorizontalLinearStepper from "./Stepper";
import { useState } from "react";
import { useNavStore, useReqStore } from "../../../store";
import { useEffect } from "react";
import server from "../../../api/server";
import { useNavigate } from "react-router-dom";
import EditFinancialStatements from "./EditFinancialStatments";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { redirect } from "../../utils/common";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequestShow = () => {
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [feedBack, setFeedBack] = useState(false);

  const { req, setReq } = useReqStore();
  const reqId = localStorage.getItem("app-id");
  const date = new Date(req.created_at).toLocaleDateString();
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const path = window.location.pathname.substring(17);

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-applications/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // localStorage.setItem("req", JSON.stringify(data.data));
      setReq(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (req) {
      redirect(req.application_status_id, path, navigate);
    }
  }, [req]);

  const data = [
    {
      value: req.application_status_id === 7 ? 0 : req.application_status_id,
      label: "",
      color: "#014E97",
    },
    {
      value:
        req.application_status_id === 7 ? 10 : 10 - req.application_status_id,
      label: "",
      color: "#F1F4FF",
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFeedBack(false);
  };

  const openFile = () => {
    window.open(`https://api.qistar.sa/storage/${req.files[0].file_path}`);
  };

  //credit status name render
  const CreditOnCheck = () => {
    return (
      <div
        className="text2"
        style={{
          color: "#FFC300",
          marginTop: "5px",
          width: "100%",
          display: "flex",
          fontSize: "0.85rem",
        }}
      >
        <div style={{ color: "#1f1f1f", marginLeft: 5 }}>حالة الفحص - </div>
        {req.credit_check_status.name}
      </div>
    );
  };
  const CreditRejected = () => {
    return (
      <div
        className="text2"
        style={{
          color: "red",
          marginTop: "5px",
          width: "100%",
          display: "flex",
          fontSize: "0.85rem",
        }}
      >
        <div style={{ color: "#1f1f1f", marginLeft: 5 }}>حالة الفحص - </div>
        {req.credit_check_status.name}
      </div>
    );
  };

  const showNote = () => {
    if (req.credit_note) {
      return (
        <div
          className="text2"
          style={{
            marginTop: "5px",
            width: "100%",
            display: "flex",
            fontSize: "0.85rem",
            color: "#FF1F00",
          }}
        >
          <div style={{ color: "#1f1f1f", marginLeft: 5 }}>السبب - </div>
          {req.credit_note}
        </div>
      );
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "الطلبات",
        items: [{ text: "طلبات الإيجار", path: "/renter/requests" }],
      },
    });
    console.log(path);
    getData();
  }, []);

  return (
    <div className="req-root vw100 scale-r">
      <Snackbar open={feedBack} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          <div className="text3">تم التعديل بنجاح</div>
        </Alert>
      </Snackbar>
      <EditFinancialStatements
        open={openEdit}
        setOpen={setOpenEdit}
        token={token}
        appId={req.id}
        setFeedBack={setFeedBack}
      />
      {/* <Contract open={openContract} setOpen={setOpenContract} /> */}
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="box10">
            <div className="box11">
              <div className="text8 center">طلباتي</div>
            </div>
            <div>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  // width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={() => navigate("/renter/requests/new")}
              >
                <div className="text3">إنشاء طلب جديد</div>
              </Button>
            </div>
          </div>

          <div className="box14 " style={{ height: "auto" }}>
            <Grid
              container
              sx={{
                direction: "rtl",
                borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
              }}
            >
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <div className="box11">
                  <div className="text22 center">{`الطلب #${req.serial_number}`}</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {date}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={0}
                xs={0}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                  },
                }}
              >
                <div className="box11" style={{ width: "100%" }}>
                  <div className="text9 center">حالة الطلب</div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="shape2"></div>
                    <p className="text22 text-r" style={{ marginTop: "5%" }}>
                      {req.status.name}
                    </p>
                  </div>
                  {(req.credit_status_id === 2 ||
                    req.credit_status_id === 4) && <CreditOnCheck />}
                  {req.credit_status_id === 5 && <CreditRejected />}
                  {req.credit_status_id === 4 && showNote()}
                  {req.credit_status_id === 5 && showNote()}
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} />
                <div className="text9 center">مكتمل بنسبة</div>
                <PieChartWithCenterLabel data={data} />
              </Grid>
            </Grid>
            <div className="box14" />
            <Grid
              container
              sx={{
                direction: "rtl",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              <Grid item md={12} sm={12} xs={12}>
                {req.application_status_id === 7 ? (
                  <HorizontalLinearStepper
                    acitve={req.last_status_id - 1}
                    error
                  />
                ) : (
                  <HorizontalLinearStepper
                    acitve={req.application_status_id - 1}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                direction: "rtl",
                padding: "10px",
                // display: "inline-block",
              }}
            >
              <div className="text8" style={{ marginTop: "10px" }}>
                بيانات المستأجر
              </div>
              <Grid container direction={"row"} sx={{ marginTop: "10px" }}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23">القوائم المالية</div>
                  <div
                    className="box15"
                    style={{
                      justifyContent: "space-between",
                      paddingBlock: 10,
                    }}
                  >
                    <Button onClick={openFile}>
                      <div className="text2">عرض الملف</div>
                    </Button>
                    {req.credit_status_id === 4 && (
                      <Button onClick={() => setOpenEdit(true)}>
                        <div className="text2">تعديل</div>
                      </Button>
                    )}
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23">عدد الفروع</div>
                  <div className="box15">
                    <div className="text12">
                      {req.branch_number ? req.branch_number : "لايوجد"}
                    </div>
                  </div>
                </Grid>
                {/* <Grid item lg={4} md={4} sm={12} xs={12}>
									<div className="text23">الدخل الشهري</div>
									<div className="box15">
										<div className="text12">15000 ريال</div>
									</div>
								</Grid> */}
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                direction: "rtl",
                padding: "10px",
                // display: "inline-block",
              }}
            >
              <div className="text8" style={{ marginTop: "10px" }}>
                بيانات العقار
              </div>
              <Grid container direction={"row"} sx={{ marginTop: "10px" }}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23">اسم المالك</div>
                  <div className="box15">
                    <div className="text12">{req.property_owner_name}</div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23">العنوان المختصر</div>
                  <div className="box15">
                    <div className="text12">
                      {req.short_code ? req.short_code : "لا يوجد"}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23">الموقع</div>
                  <div className="box15">
                    <div className="text12">{req.property_location}</div>
                  </div>
                </Grid>
                {/* <div
							style={{
								display: "flex",
								alignItems: "center",
								width: "100%",
							}}
							onClick={() => setOpenContract(true)}
						>
							<div
								className="text8"
								style={{ marginTop: "10px" }}
							>
								بيانات التعاقد
							</div>
							<div className="box16">
								<div className="box17">
									<img src={icon1} alt="icon" />
									<div className="text24">
										الموافقة على عقد الإلتزام
									</div>
								</div>
								<div className="text24">لم تتم الموافقة</div>
							</div>
						</div> */}
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default RequestShow;
