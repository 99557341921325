import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Accordion,
  AccordionDetails,
  Grow,
  Box,
  CircularProgress,
} from "@mui/material";
import icon1 from "../../assets/Mada_Logo 1.png";
import icon2 from "../../assets/Apple_Pay_logo 1.png";
import icon3 from "../../assets/visa-svgrepo-com 1.png";
import icon4 from "../../assets/mastercard-2 1.png";
import icon5 from "../../assets/sadad.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import server from "../../api/server";
import "./pay.css";
import { usePayStore } from "../../store";
import SuccessPayment from "./SuccessPayment";
import FailedPayment from "./FailedPayment";
import { showDate } from "../utils/common";

const Payment = () => {
  const [expanded, setexpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [methods, setMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState({});
  const [successPay, setSuccessPay] = useState(false);
  const navigate = useNavigate();
  const payRef = useRef(null);
  const payRef4 = useRef(null);
  const payRef5 = useRef(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const { currentPayment, setCurrentPayment } = usePayStore();
  const amount = Number(currentPayment.deserved_amount * 100);
  const { installmentId } = useParams();
  const { statusId } = useParams();

  const getInstallment = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-installments-details/${installmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setCurrentPayment(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getMethods = async () => {
    try {
      const { data } = await server.get("/customers/payment-methods", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMethods(data.data);
      setSelectedMethod(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const storePayment = async (transactionId) => {
    try {
      const { data } = await server.post(
        "/customers/store-payment",
        {
          customer_id: user.id,
          transaction_id: transactionId,
          payment_status_id: 1,
          amount,
          payment_method_id: selectedMethod.id,
          note: `تم دفع القسط بالرقم ${currentPayment.serial_no}`,
          installment_id: currentPayment.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessPay(true);
      if (data.data.id) {
        return 2;
      }
    } catch (error) {
      setSuccessPay(false);
      console.log(error);
      return false;
    }
  };

  const selectPaymentMethod = () => {
    if (!selectedMethod) return;
    if (selectedMethod.id < 4) {
      window.Moyasar.init({
        element: ".mysr-form",
        amount,
        currency: "SAR",
        description: `installment serial number : ${currentPayment.serial_no}`,
        publishable_api_key: "pk_test_mKUcQ6MWBiN9jurpXYM1zNupuBZDdkJWB9G2im17",
        // callback_url: "https://moyasar.com/thanks",
        callback_url: "https://api.qistar.sa/api/customers/callback-payment",
        methods: [selectedMethod.slug],
        on_completed: (PaymentData) => {
          return new Promise(function (resolve, reject) {
            // savePayment is just an example, your usage may vary.
            const check = async () => {
              const res = await storePayment(PaymentData.id);
              console.log(res);
              if (res === 2) {
                resolve({});
              } else {
                reject();
              }
            };
            check();
          });
        },
      });
    } else if (selectedMethod.id === 4) {
      window.Moyasar.init({
        element: ".mysr-form4",
        amount,
        currency: "SAR",
        description: `installment serial number : ${currentPayment.serial_no}`,
        publishable_api_key: "pk_test_mKUcQ6MWBiN9jurpXYM1zNupuBZDdkJWB9G2im17",
        callback_url: "https://api.qistar.sa/api/customers/callback-payment",
        methods: [selectedMethod.slug],
        on_completed: (PaymentData) => {
          return new Promise(function (resolve, reject) {
            // savePayment is just an example, your usage may vary.
            const check = async () => {
              const res = await storePayment(PaymentData.id);
              console.log(res);
              if (res === 2) {
                resolve({});
              } else {
                reject();
              }
            };
            check();
          });
        },
      });
    } else if (selectedMethod.id === 5) {
      console.log("app");
      window.Moyasar.init({
        element: ".mysr-form5",
        amount,
        currency: "SAR",
        description: `installment serial number : ${currentPayment.serial_no}`,
        publishable_api_key: "pk_test_mKUcQ6MWBiN9jurpXYM1zNupuBZDdkJWB9G2im17",
        callback_url: "https://api.qistar.sa/api/customers/callback-payment",
        methods: ["applepay"],
        on_completed: (PaymentData) => {
          return new Promise(function (resolve, reject) {
            // savePayment is just an example, your usage may vary.
            const check = async () => {
              const res = await storePayment(PaymentData.id);
              console.log(res);
              if (res === 2) {
                resolve({});
              } else {
                reject();
              }
            };
            check();
          });
        },
        apple_pay: {
          country: "SA",
          label: "Qistar",
          validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate",
        },
      });
    }
  };

  const renterMethods = () => {
    return methods.map((method) => {
      return (
        <Grid
          item
          key={method.id}
          xs={4}
          md={3}
          sm={4}
          lg={2.4}
          sx={{ cursor: "pointer" }}
        >
          <div
            className={selectedMethod?.id === method.id ? "box19 pay" : "box19"}
            onClick={() => setSelectedMethod(method)}
          >
            <img
              src={`https://api.qistar.sa/storage/${method.image}`}
              alt="icon"
              style={{ height: "40px" }}
              className="pay-icon"
            />
          </div>
        </Grid>
      );
    });
  };

  useEffect(() => {
    if (payRef.current && payRef4.current && payRef5.current) {
      selectPaymentMethod();
    }
  }, [payRef, selectedMethod]);
  useEffect(() => {
    getMethods();
    getInstallment();
  }, []);
  return (
    <div className="vw100">
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <SuccessPayment
            open={statusId === "1"}
            amount={currentPayment.deserved_amount}
          />
          <FailedPayment open={statusId === "2"} id={currentPayment.id} />
          <Grid
            container
            // direction={"row-reverse"}
            // justifyContent={"space-around"}
            spacing={2}
            sx={{ direction: "rtl" }}
          >
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid item lg={12} md={12} sm={12} xs={12} direction={"column"}>
                <div className="box1" style={{ border: "solid 1px #E7E7E7" }}>
                  <div
                    className="box14"
                    style={{ display: "block", marginTop: "0px" }}
                  >
                    <div className="text8">الدفع بالبطاقه</div>
                    <div className="text25 mr0 bold-600">
                      إختر وسيلة الدفع التي تناسبك
                    </div>
                  </div>
                  <Grid container spacing={1}>
                    {renterMethods()}
                  </Grid>
                  {/* <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2 bold" style={{ color: "#4F4F4F" }}>
                    رقم البطاقه
                  </div>
                  <TextField
                    sx={{
                      backgroundColor: "#E7E7E7",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      fontWeight: "700px",
                      transformBox: "scale(0.5)",
                      // height: "1rem",
                    }}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2 bold" style={{ color: "#4F4F4F" }}>
                    تاريخ الأنتهاء
                  </div>
                  <TextField
                    sx={{
                      backgroundColor: "#E7E7E7",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      fontWeight: "700px",
                      // height: "1rem",
                    }}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2 bold" style={{ color: "#4F4F4F" }}>
                    إسم حامل البطاقه
                  </div>
                  <TextField
                    sx={{
                      backgroundColor: "#E7E7E7",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      fontWeight: "700px",
                      transformBox: "scale(0.5)",
                      // height: "1rem",
                    }}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2 bold" style={{ color: "#4F4F4F" }}>
                    رمز التحقق CVV
                  </div>
                  <TextField
                    sx={{
                      backgroundColor: "#E7E7E7",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      fontWeight: "700px",
                    }}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <div className="text25 bold" style={{ width: "10vw" }}>
                  المبلغ المراد دفعه
                </div>
                <TextField
                  sx={{
                    backgroundColor: "#E7E7E7",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    fontWeight: "700px",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ backgrondColor: "#fff" }}
                      >
                        <div
                          className="text2 bold"
                        >
                          ريال
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                
              </div> */}
                  <Box sx={{ width: "95%" }}>
                    <Box
                      sx={{
                        padding: 2,
                        borderRadius: "8px",
                        mt: "1rem",
                        border: "1px solid #E7E7E7",
                        width: "100%",
                      }}
                    >
                      <div
                        ref={payRef}
                        className="mysr-form"
                        style={{ display: selectedMethod.id > 3 && "none" }}
                      ></div>

                      <div
                        ref={payRef4}
                        className="mysr-form4"
                        style={{ display: selectedMethod.id !== 4 && "none" }}
                      ></div>

                      <div
                        ref={payRef5}
                        className="mysr-form5"
                        style={{ display: selectedMethod.id !== 5 && "none" }}
                      ></div>
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              // sx={{ marginLeft: "50px" }}
            >
              <Grid item xs={12}>
                <div
                  className="box1"
                  style={{
                    height: "auto",
                    border: "solid 1px #E7E7E7",
                  }}
                >
                  <div
                    className="text8"
                    style={{
                      borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                      width: "100%",
                      padding: "5px",
                    }}
                  >
                    الفاتورة
                  </div>
                  {/* <div style={{ width: "100%" }} /> */}
                  <div
                    // className="box10"
                    style={{
                      direction: "rtl",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      marginTop: "10px",
                      borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                      padding: "5px",
                    }}
                  >
                    <div>
                      <div className="text2 bold" style={{ color: "#080D2B" }}>
                        رقم الفاتورة
                      </div>
                    </div>

                    <div>
                      <div className="text25 bold">
                        {currentPayment.serial_no}
                      </div>
                    </div>
                  </div>
                  <div
                    // className="box10"
                    style={{
                      direction: "rtl",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      marginTop: "10px",
                      borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                      padding: "5px",
                    }}
                  >
                    <div>
                      <div className="text2 bold" style={{ color: "#080D2B" }}>
                        تاريخ الإصدار
                      </div>
                    </div>

                    <div>
                      <div className="text25 bold">
                        {showDate(currentPayment.created_at)}
                      </div>
                    </div>
                  </div>
                  <div
                    // className="box10"
                    style={{
                      direction: "rtl",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      marginTop: "10px",
                      borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                      padding: "5px",
                    }}
                  >
                    <div>
                      <div className="text2 bold" style={{ color: "#080D2B" }}>
                        المبلغ المستحق
                      </div>
                    </div>

                    <div>
                      <div className="text25 bold">
                        {currentPayment.deserved_amount} ريال
                      </div>
                    </div>
                  </div>

                  <div
                    // className="box10"
                    style={{
                      direction: "rtl",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      marginTop: "10px",
                      borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                      padding: "5px",
                    }}
                  >
                    <div>
                      <div className="text2 bold" style={{ color: "#080D2B" }}>
                        تاريخ الأستحقاق
                      </div>
                    </div>

                    <div>
                      <div className="text25 bold">
                        {showDate(currentPayment.date_for_payment)}
                      </div>
                    </div>
                  </div>
                  {/* <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#004A98",
                      width: "100%",
                    }}
                    // onClick={() => navigate("/renter/requests/aproved")}
                  >
                    <div className="text3"> إدفع الآن</div>
                  </Button> */}
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                <div className="box1" style={{ height: "auto" }}>
                  <div style={{ width: "100%" }} />
                  <div className="box20">
                    <div className="box11">
                      <div className="text8 center">الدفع بخدمه سداد</div>
                      <div className="text25 bold mr0 bold-600">
                        إختر وسيلة الدفع التي تناسبك
                      </div>
                    </div>
                    <div className="box19">
                      <img src={icon5} alt="icon" />
                    </div>
                  </div>
                  <Grow
                    in={expanded}
                    style={{ display: expanded ? "flex" : "none" }}
                  >
                    <div
                      className="box1"
                      style={{
                        height: "auto",
                        backgroundColor: "#E7E7E7",
                        minWidth: "fit-content",
                      }}
                    >
                      <div
                        // className="box10"
                        style={{
                          direction: "rtl",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                          marginTop: "10px",
                          borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                          padding: "5px",
                        }}
                      >
                        <div>
                          <div
                            className="text2 bold"
                            style={{ color: "#080D2B" }}
                          >
                            رقم السداد
                          </div>
                        </div>

                        <div>
                          <div className="text25 bold">88238456</div>
                        </div>
                      </div>
                      <div
                        // className="box10"
                        style={{
                          direction: "rtl",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                          marginTop: "10px",
                          borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                          padding: "5px",
                        }}
                      >
                        <div>
                          <div
                            className="text2 bold"
                            style={{ color: "#080D2B" }}
                          >
                            تاريخ الإصدار
                          </div>
                        </div>

                        <div>
                          <div className="text25 bold">24-9-2023</div>
                        </div>
                      </div>
                      <div
                        // className="box10"
                        style={{
                          direction: "rtl",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                          marginTop: "10px",
                          borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                          padding: "5px",
                        }}
                      >
                        <div>
                          <div
                            className="text2 bold"
                            style={{ color: "#080D2B" }}
                          >
                            تاريخ الانتهاء
                          </div>
                        </div>

                        <div>
                          <div className="text25 bold">26-9-2023</div>
                        </div>
                      </div>

                      <div
                        // className="box10"
                        style={{
                          direction: "rtl",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                          marginTop: "10px",
                          borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
                          padding: "5px",
                        }}
                      >
                        <div>
                          <div
                            className="text2 bold"
                            style={{ color: "#080D2B" }}
                          >
                            اسم المفوتر{" "}
                          </div>
                        </div>

                        <div>
                          <div className="text25 bold">قسطار</div>
                        </div>
                      </div>
                    </div>
                  </Grow>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#EF7721",
                      width: "100%",
                    }}
                    onClick={() => setexpanded(true)}
                    style={{ display: expanded ? "none" : "flex" }}
                  >
                    <div className="text3"> الدفع عن طريق خدمة سداد</div>
                  </Button>
                </div>
              </Grid> */}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Payment;
