import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Box,
  Divider,
  Checkbox,
} from "@mui/material";
import { useNavStore, useUserStor, useReqStore } from "../../../store";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import server from "../../../api/server";
import SuccessApplication from "./SuccessApplication";
import { PhoneInput } from "react-international-phone";
import { Check, Close } from "@mui/icons-material";
import Selector from "../../utils/Selector";
import AlertMessage from "../../utils/AlertMessage";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const NewOwnerReq = () => {
  const [deed, setDeed] = useState();
  const [loadingDeed, setLoadingDeed] = useState(false);
  const [successDeed, setSuccessDeed] = useState(false);
  const [failedDeed, setFailedDeed] = useState(false);
  const [error, setError] = useState(null);
  const [openAlertError, setOpenAlertError] = useState(false);

  const [deedNo, setDeedNo] = useState("");
  const [propertyType, setPropertyType] = useState(1);
  const [property_owner_name, setProperty_owner_name] = useState("");
  const [origin_rent_amount, setRent_amount] = useState(null);
  const [property_location, setProperty_location] = useState("");
  const [property_neighbourhood, setProperty_neighbourhood] = useState("");
  const [property_no, setProperty_no] = useState("");
  const [short_code, setShort_code] = useState("");

  const [execute_status, setExecute_status] = useState("NOW");
  const [execute_date, setExecute_date] = useState("");

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [property_location_link, setProperty_location_link] = useState(null);
  const [company_owner_name, setCompany_owner_name] = useState(null);
  const [company_owner_phone, setCompany_owner_phone] = useState("");
  const [propertyUse, setPropertyUse] = useState(1);

  const { token } = useUserStor();
  const user = JSON.parse(localStorage.getItem("user"));

  const formdata = new FormData();
  //property info
  formdata.append("deed_no", deedNo);
  formdata.append("property_owner_name", property_owner_name);
  formdata.append("property_location", property_location);
  formdata.append("short_code", short_code);
  formdata.append("property_neighbourhood", property_neighbourhood);
  formdata.append("property_no", property_no);
  formdata.append("property_owner_phone", user.phone);
  formdata.append("origin_rent_amount", origin_rent_amount);
  formdata.append("building_type_id", propertyType);

  execute_date &&
    formdata.append("execute_date", dayjs(execute_date).format("YYYY/MM/DD"));

  //company info
  formdata.append("company_owner_name", company_owner_name);
  formdata.append("company_owner_phone", company_owner_phone.substring(1));

  // formdata.append("deed_id", deed?.id);
  // formdata.append("building_use_id", propertyUse);

  //vaildations
  const [serverError, setServerError] = useState({});
  const serverErrorMessage = (field) => {
    if (serverError[field]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {serverError[field][0]}
        </div>
      );
    }
  };
  const validateFn = () => {
    const feilds = {
      // deedNo,
      property_owner_name,
      property_location,
      // property_neighbourhood,
      // property_no,
      company_owner_name,
      company_owner_phone,
      origin_rent_amount,
      // short_code,
    };
    for (const [key, value] of Object.entries(feilds)) {
      if (!value) {
        setServerError((prev) => ({ ...prev, [key]: ["مطلوب"] }));
      }
    }
  };

  const [phoneError, setPhoneError] = useState(false);

  const validatePhone = () => {
    if (company_owner_phone.length < 8) {
      setPhoneError(true);
    }
  };

  const ErrorTextPhone = () => {
    return (
      <div
        className="text2"
        style={{ color: "red", marginTop: "0px", width: "100%" }}
      >
        الرقم غير صحيح
      </div>
    );
  };

  const sendApplication = async () => {
    if (
      // !deedNo ||
      !property_owner_name ||
      !property_location ||
      // !property_neighbourhood ||
      // !property_no ||
      !company_owner_name ||
      !company_owner_phone ||
      // !short_code ||
      origin_rent_amount === 0 ||
      phoneError
    ) {
      validateFn();
      return;
    }
    setLoading(true);
    try {
      const { data } = await server.post(
        "/customers/store-rental-application",
        formdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getDeed = async () => {
    if (deedNo === "") return;
    setLoadingDeed(true);
    setSuccessDeed(false);
    setFailedDeed(false);
    try {
      const { data } = await server.get(
        `/customers/get-deed-details/${deedNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(data);
      // console.log(short_code);
      setDeed(data.data);
      setLoadingDeed(false);
      setSuccessDeed(true);
    } catch (error) {
      console.log(error);
      setLoadingDeed(false);
      setFailedDeed(true);

      setDeed({});
    }
  };

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     console.log("send req");
  //     getDeed();
  //   }, 1500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [deedNo]);

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "استمارة طلب إيجار",
        items: [{ text: "طلبات الإيجار", path: "/owner/requests" }],
      },
    });
  }, []);

  const handleSendReq = () => {
    setPhoneError(false);
    setServerError({});
    validatePhone();
    sendApplication();
  };

  const total = Number(origin_rent_amount) - 0.1 * Number(origin_rent_amount);

  return (
    <div style={{ marginInline: "10px", width: "95%" }}>
      <SuccessApplication open={success} setOpen={setSuccess} />
      <div className="box10">
        <div className="box11">
          <div className="text8 center text-r">طلب تأجير</div>
        </div>
      </div>
      <Grid
        container
        // direction={"row-reverse"}
        // justifyContent={"space-between"}
        spacing={2}
        sx={{ direction: "rtl" }}
      >
        <Grid item md={7} xs={12}>
          <Box
            sx={{
              padding: 2,
              bgcolor: "#fff",
              borderRadius: "8px",
              mt: "1rem",
              border: "1px solid #E7E7E7",
            }}
          >
            <div className=" text60">بيانات العقار</div>
            <div style={{ width: "100%" }}></div>
            <div className="text2 t201 bold slb-text">رقم الصك</div>
            <TextField
              sx={{
                ":focus-within": {
                  backgroundColor: "#F1F4FF",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              size="small"
              onChange={(e) => setDeedNo(e.target.value)}
              inputProps={{
                startAdornment: (
                  // <InputAdornment position="end">
                  <CircularProgress />
                  // </InputAdornment>
                ),
              }}
            />
            {serverErrorMessage("deedNo")}
            <div className="text2 t201 bold slb-text">
              اسم مالك العقار <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              value={property_owner_name}
              name="property_owner_name"
              sx={{
                ":focus-within": {
                  backgroundColor: "#F1F4FF",
                },
                ".Mui-disabled": {
                  backgroundColor: "#f8f8f8",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              size="small"
              onChange={(e) => setProperty_owner_name(e.target.value)}
            />
            {serverErrorMessage("property_owner_name")}
            <div className="text2 t201 bold slb-text">
              المدينة <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              sx={{
                ":focus-within": {
                  backgroundColor: "#f8f8f8",
                },
                ".Mui-disabled": {
                  backgroundColor: "#f8f8f8",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              size="small"
              value={property_location}
              name="property_location"
              onChange={(e) => setProperty_location(e.target.value)}
            />
            {serverErrorMessage("property_location")}
            <div className="text2 t201 bold slb-text">الرمز البريدي</div>
            <TextField
              value={short_code}
              sx={{
                ":focus-within": {
                  backgroundColor: "#f8f8f8",
                },
                ".Mui-disabled": {
                  backgroundColor: "#f8f8f8",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              size="small"
              name="short_code"
              onChange={(e) => setShort_code(e.target.value)}
            />

            <div className="text2 t201 bold slb-text">الحي</div>
            <TextField
              value={property_neighbourhood}
              sx={{
                ":focus-within": {
                  backgroundColor: "#f8f8f8",
                },
                ".Mui-disabled": {
                  backgroundColor: "#f8f8f8",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              size="small"
              onChange={(e) => setProperty_neighbourhood(e.target.value)}
              name="property_neighbourhood"
            />
            {serverErrorMessage("property_neighbourhood")}
            <div className="text2 t201 bold slb-text">رقم المبنى</div>
            <TextField
              value={property_no}
              sx={{
                ":focus-within": {
                  backgroundColor: "#f8f8f8",
                },
                ".Mui-disabled": {
                  backgroundColor: "#f8f8f8",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              size="small"
              name="property_no"
              onChange={(e) => setProperty_no(e.target.value)}
            />
            {serverErrorMessage("property_no")}
            <div className="text2 bold slb-text">رقم جوال المالك</div>
            <TextField
              disabled
              sx={{
                ":focus-within": {
                  backgroundColor: "#F1F4FF",
                },
                ".Mui-disabled": {
                  backgroundColor: "#f8f8f8",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                backgroundColor: "#ece8e8",
                fontWeight: "700px",
              }}
              value={user.phone}
              className="textInput1"
              variant="outlined"
              size="small"
            />
            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={6}>
                <div className="text2 t201 bold slb-text">استخدام العقار</div>
                <Selector
                  value={propertyUse}
                  setValue={setPropertyUse}
                  api="building-uses"
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <div className="text2 t201 bold slb-text">
                  نوع العقار <span style={{ color: "red" }}>*</span>
                </div>
                <Selector
                  value={propertyType}
                  setValue={setPropertyType}
                  api="building-types"
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 1 }}>
              <Checkbox
                value={execute_status}
                onChange={(e) =>
                  setExecute_status((pre) => (pre === "NOW" ? "LATER" : "NOW"))
                }
              />
              طلب مستقبلي
            </Box>
          </Box>
          {execute_status === "LATER" && (
            <Grid xs={12}>
              <div
                className="box1"
                style={{ marginBottom: 30, display: "block" }}
              >
                <div
                  className="text10"
                  style={{
                    marginRight: "0px",
                    fontSize: "1rem",
                    marginBottom: "10px",
                  }}
                >
                  موعد انشاء الطلب
                </div>
                <div style={{ width: "100%" }}></div>
                <div className="text2" style={{ marginBottom: 5 }}>
                  التاريخ
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MobileDatePicker"]}>
                    <MobileDatePicker
                      sx={{
                        backgroundColor: "#F1F4FF",
                        width: "100%",
                        // direction: "rtl",
                        color: "GrayText",
                        fontFamily: "font",
                        padding: 0,
                      }}
                      size="small"
                      // value={dayjs(user.date)}
                      value={execute_date}
                      className="textInput1"
                      variant="outlined"
                      format="DD-MM-YYYY"
                      onChange={(newValue) => setExecute_date(newValue)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: false,
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {serverErrorMessage("execute_date")}
              </div>
            </Grid>
          )}
          {/*  */}
          <>
            {/* check and delete */}
            <Box
              sx={{
                padding: 2,
                bgcolor: "#fff",
                borderRadius: "8px",
                mt: "1rem",
                border: "1px solid #E7E7E7",
              }}
            >
              <div className="text60">بيانات المستأجر</div>
              <div style={{ width: "100%" }}></div>
              <div className="text2 bold slb-text" style={{ color: "#4F4F4F" }}>
                الأسم الكامل <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={company_owner_name}
                name="company_owner_name"
                onChange={(e) => setCompany_owner_name(e.target.value)}
              />
              {serverErrorMessage("company_owner_name")}
              <div className="text2 bold slb-text" style={{ color: "#4F4F4F" }}>
                رقم الجوال <span style={{ color: "red" }}>*</span>
              </div>

              <PhoneInput
                inputClassName="c-input"
                defaultCountry="sa"
                value={company_owner_phone}
                onChange={(e) => setCompany_owner_phone(e)}
                style={{ width: "100%", direction: "ltr" }}
              />
              {phoneError && <ErrorTextPhone />}
            </Box>
          </>
          {/*  */}
        </Grid>
        <Grid xs={12} md={5}>
          <Box
            sx={{
              padding: 2,
              bgcolor: "#fff",
              borderRadius: "8px",
              mt: "2rem",
              border: "1px solid #E7E7E7",
            }}
          >
            <div className="text2 bold slb-text">
              مبلغ الاإيجار المتفق عليه مع المستأجر{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              sx={{
                ":focus-within": {
                  backgroundColor: "#F1F4FF",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
                "[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }}
              className="textInput1"
              variant="outlined"
              size="small"
              type="number"
              value={origin_rent_amount}
              onChange={(e) => setRent_amount(e.target.value)}
            />
            {serverErrorMessage("origin_rent_amount")}
            <Box
              sx={{
                padding: 2,
                bgcolor: "#fff",
                borderRadius: "8px",
                mt: "1rem",
                border: "1px solid #E7E7E7",
              }}
            >
              <div className="text2 bold slb-text">نسبة الخصم</div>
              <div
                className="text2 bold slb-text"
                style={{ fontSize: "1.5rem" }}
              >
                10%
              </div>
              <Divider />
              <div className="text2 bold slb-text">قيمة المبلغ</div>
              <div
                className="text2 bold slb-text"
                style={{ fontSize: "1.5rem" }}
              >
                {total}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <div className="box10">
        <div>
          <LoadingButton
            variant="contained"
            size="large"
            loading={loading}
            sx={{
              marginTop: "10px",
              // marginLeft: "10px",
              // width: "10vw",
              bgcolor: "#014E97",
            }}
            onClick={handleSendReq}
          >
            <div className="text3">إرسال الطلب</div>
          </LoadingButton>
        </div>
      </div>
      <AlertMessage
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={error}
        type={"error"}
      />
    </div>
  );
};

export default NewOwnerReq;
