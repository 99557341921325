import { Button, Grid, CircularProgress } from "@mui/material";
import icon1 from "../../../assets/Document.png";
import icon2 from "../../../assets/Paper.png";
import icon3 from "../../../assets/Group 1000004117.png";
import icon5 from "../../../assets/sadad.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicAccordion from "./Accordion";
import DataTable from "./DtatTable";
import { useNavStore } from "../../../store";
import { useEffect } from "react";
import server from "../../../api/server";
import { showDate } from "../../utils/common";

const Deposites = () => {
  const [expanded, setexpanded] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [installment, setInstallment] = useState(null);
  const [installmentLoading, setInstallmentLoading] = useState(true);
  const [subContract, setSubContract] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  // const subContract = installment?.contract.sub_contracts.filter(
  // 	(arr) => arr.type_id === 2
  // );

  const handleExpand = (id) => (_, isExpanded) => {
    setexpanded(isExpanded ? id : null);
    getInstallments(id);
  };

  const getInstallments = async (id) => {
    setInstallmentLoading(true);
    try {
      const { data } = await server.get(
        `/customers/deposits?contract_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!data.data[0]) throw "the user has no installments";
      // setLoading(false);
      // const SubContract = data.data.contract.sub_contracts.filter(
      //   (arr) => arr.type_id === 2
      // );
      // setSubContract(SubContract[0]);
      setInstallment(data.data);
      setInstallmentLoading(false);
    } catch (error) {
      setInstallment(null);
      console.log(error);
      setInstallmentLoading(false);
    }
  };

  const getContracts = async () => {
    try {
      const { data } = await server.get("/customers/get-contracts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setContracts(data.data);
      if (data.data.length > 0) {
        getInstallments(data.data[0].id);
        setexpanded(data.data[0].id);
      } else {
        setInstallmentLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "الإيرادات",
      },
    });
    getContracts();
  }, []);
  return (
    <div style={{ padding: "10px", width: "90vw" }}>
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2} sx={{ direction: "rtl" }}>
          <Grid item xs={12}>
            <div
              className="box10"
              style={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <div className="box11" style={{ width: "100%" }}>
                <div className="text8 center">الإيرادات</div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{ pt: "0px" }}
            className="pt0"
          >
            <Grid item xs={12} direction={"column"} width={"100%"}>
              <div className="box1" style={{ borderRadius: "8px" }}>
                <div
                  className="box10"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <div className="box11" style={{ width: "100%" }}>
                    <div className="text8 center">العقود</div>
                  </div>
                </div>
                {contracts.length === 0 ? (
                  <div>لاتوجد عقود</div>
                ) : (
                  <BasicAccordion
                    data={contracts}
                    expanded={expanded}
                    handleChange={handleExpand}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          {installmentLoading ? (
            <Grid item xs={8}>
              <div
                style={{
                  width: "auto",
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Grid>
          ) : (
            <Grid
              item
              lg={8}
              md={8}
              sm={12}
              xs={12}
              sx={{ marginLeft: "0" }}
              className="pt0"
            >
              <Grid item xs={12}>
                <div
                  className="box1"
                  style={{
                    height: "auto",
                    minWidth: "auto",
                    borderRadius: "8px",
                    overflowX: "auto",
                  }}
                >
                  <div
                    className="box10"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    <div className="box11" style={{ width: "100%" }}>
                      <div className="text8 center">سجل الإيرادات</div>
                    </div>
                  </div>
                  {/* table */}
                  {installment ? (
                    <DataTable data={installment} />
                  ) : (
                    <div>لاتوجد سجلات</div>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Deposites;
