import { useEffect } from "react";
import Pusher from "pusher-js";
import server from "./api/server";
import { useNotificationStore, useReqStore } from "./store";

const RealTime = () => {
  const token = localStorage.getItem("token");
  const appId = localStorage.getItem("app-id");
  const user = JSON.parse(localStorage.getItem("user"));
  const { setReq } = useReqStore();
  const { fetchNotifications } = useNotificationStore();

  // Pusher.logToConsole = true;
  useEffect(() => {
    if (!user) return;
    const pusher = new Pusher("517b50cfe0b56ca84678", {
      broadcaster: "pusher",
      cluster: "ap2",
      channelAuthorization: {
        endpoint: `https://api.qistar.sa/api/customers/broadcasting/auth`,
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      },
    });
    const channel = pusher.subscribe(`private-application.${user.id}`);
    channel.bind("application-event", function (data) {
      const appId2 = localStorage.getItem("app-id");
      if (data.event_data.id === Number(appId2)) {
        console.log(`dataid : ${data.event_data.id} appId: ${Number(appId2)}`);

        const getApplication = async () => {
          try {
            const { data } = await server.get(
              `/customers/get-applications/${appId2}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setReq(data.data);
          } catch (error) {
            console.log(error);
          }
        };
        getApplication();
      }
      console.log(data);
    });

    const notificationsChannel = pusher.subscribe(
      `private-notification.${user.id}`
    );
    notificationsChannel.bind("customer-notification", (data) => {
      fetchNotifications();
    });
  }, []);

  return <div></div>;
};

export default RealTime;
