import React from "react";
import { MenuItem, Select, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import server from "../../api/server";

export default function Selector({ value, setValue, api }) {
	const token = localStorage.getItem("token");
	const [data, setCreditRisksData] = useState([]);

	const getData = async () => {
		try {
			const { data } = await server.get(`/customers/${api}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setCreditRisksData(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	const renderRisks = () => {
		return data.map((item) => {
			return (
				<MenuItem
					key={item.id}
					value={item.id}
					sx={{
						bgColor: "#fd16169c",
						textAlign: "right",
						direction: "rtl",
					}}
				>
					<div className="text9 mr0">{item.name}</div>
				</MenuItem>
			);
		});
	};

	const onRiskChange = (e) => {
		setValue(e.target.value);
		console.log(value);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			{data.length !== 0 ? (
				<Select
					value={value}
					onChange={(e) => onRiskChange(e)}
					sx={{
						direction: "rtl",
					}}
					size="small"
					fullWidth
				>
					{renderRisks()}
				</Select>
			) : (
				<CircularProgress />
			)}
		</>
	);
}
