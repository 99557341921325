import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import {
	ClickAwayListener,
	ListItemButton,
	List,
	ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { useUserStor } from "../../../store";

export default function LoginAs({ anchorEl, open, setOpen }) {
	const loginAsRef = React.useRef(null);
	const navigate = useNavigate();
	const { setUserData, token } = useUserStor();

	const handleClose = (event) => {
		if (loginAsRef.current && loginAsRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const handleSwichType = async () => {
		try {
			const { data } = await server.post(
				"/customers/switch-type",
				{
					customer_type: "1",
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log(data);
			setUserData(data.data[0]);
			localStorage.setItem("user", JSON.stringify(data.data[0]));
			navigate("/home");
		} catch (error) {
			console.log(error);
		}
	};

	const handleLoguot = async () => {
		try {
			const { data } = await server.get("/customers/logout", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			console.log(data);
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			navigate("/login");
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Box sx={{ width: 500 }}>
			<Popper
				open={open}
				anchorEl={anchorEl}
				placement={"bottom-start"}
				transition
				// ref={loginAsRef}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper sx={{ padding: "10px" }}>
							<ClickAwayListener onClickAway={handleClose}>
								<div style={{ direction: "rtl" }}>
									<div
										className="text11 text-r"
										style={{ fontSize: "1rem" }}
									>
										الدخول ك :
									</div>
									<List sx={{ direction: "rtl" }}>
										<ListItemButton
											className=""
											sx={{
												".MuiTypography-root": {
													fontFamily: "inherit",
													// fontWeight: "600",
													textAlign: "right",
													fontSize: "1rem",
												},
												backgroundColor: "#F4F7FF",
												color: "#014A97",
												margin: "10px 0px",
												width: "200px",
												border: "1px solid rgba(1, 74, 151, 0.08)",
												borderRadius: "4px",
											}}
											onClick={() => handleSwichType()}
										>
											<ListItemText
												className="dirction"
												primary="مستأجر"
											/>
										</ListItemButton>

										<ListItemButton
											className=""
											disabled
											sx={{
												".MuiTypography-root": {
													fontFamily: "inherit",
													// fontWeight: "600",
													textAlign: "right",
													fontSize: "1rem",
												},
												backgroundColor: "#F4F7FF",
												color: "#999",
												margin: "10px 0px",
												width: "200px",
												border: "1px solid rgba(1, 74, 151, 0.08)",
												borderRadius: "4px",
											}}
										>
											<ListItemText
												className="dirction"
												primary="مالك"
											/>
										</ListItemButton>
										<ListItemButton
											className=""
											sx={{
												".MuiTypography-root": {
													fontFamily: "inherit",
													// fontWeight: "600",
													textAlign: "right",
													fontSize: "1rem",
												},
												backgroundColor: "#F4F7FF",
												color: "#014A97",
												margin: "10px 0px",
												width: "200px",
												borderRadius: "4px",
											}}
											onClick={handleLoguot}
										>
											<ListItemText
												className="dirction"
												primary="تسجيل خروج"
											/>
										</ListItemButton>
									</List>
								</div>
							</ClickAwayListener>
						</Paper>
					</Fade>
				)}
			</Popper>
		</Box>
	);
}
