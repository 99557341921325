import {
  Grid,
  TextField,
  styled,
  IconButton,
  CircularProgress,
  Button,
  Box,
} from "@mui/material";
import icon1 from "../../../assets/upload.png";
import { useNavigate } from "react-router-dom";
import { useNavStore, useUserStor, useReqStore } from "../../../store";
import { useEffect, useState } from "react";
import server from "../../../api/server";
import SuccessApplication from "./SuccessApplication";
import LoadingButton from "@mui/lab/LoadingButton";
import { Check, Close } from "@mui/icons-material";
import { redirect } from "../../utils/common";
import AlertMessage from "../../utils/AlertMessage";

const CompleteApplication = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [appId, setAppId] = useState(null);
  const [registration_number, setRegistration_number] = useState("");
  const [branch_number, setBranch_number] = useState("");
  const [financial_statements, setFinancial_statements] = useState([]);
  const [openAlertError, setOpenAlertError] = useState(false);

  const [error, setError] = useState("");

  const [company, setCompany] = useState({});
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [failedCompany, setFailedCompany] = useState(false);
  const [successCompany, setSuccessCompany] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [company_name, setCompany_name] = useState("");
  const [company_owner_name, setCompany_owner_name] = useState("");
  const [property_img, setproperty_img] = useState(null);

  // const { token } = useUserStor();
  const token = localStorage.getItem("token");

  const { req } = useReqStore();

  const formdata = new FormData();
  formdata.append("company_name", company_name);
  formdata.append("company_owner_name", company_owner_name);
  formdata.append("registration_number", registration_number);
  formdata.append("branch_number", branch_number);
  formdata.append("property_img", property_img);
  financial_statements.map((file) => {
    formdata.append("financial_statements[]", file);
  });

  formdata.append("application_id", req.id);

  const sendApplication = async () => {
    if (
      !company_name ||
      !registration_number ||
      // !branch_number ||
      // !company_owner_name ||
      // !property_img ||
      financial_statements.length === 0
    ) {
      validateFn();
      return;
    }
    setLoading(true);
    try {
      const { data } = await server.post(
        "/customers/complete-application",
        formdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      localStorage.setItem("req", JSON.stringify(data.data));
      setAppId(data.data.id);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response?.status === 422) {
        setServerError(error.response.data.error);
      }
    }
  };

  const getCompany = async () => {
    if (registration_number === "") return;
    setLoadingCompany(true);
    setSuccessCompany(false);
    setFailedCompany(false);
    try {
      const { data } = await server.get(
        `/customers/get-company-details/${registration_number}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompany(data.data);
      setLoadingCompany(false);
      setSuccessCompany(true);
    } catch (error) {
      if (error.response.status === 401) {
        setAuthError(true);
      }
      console.log(error);
      setLoadingCompany(false);
      setCompany({});
      setFailedCompany(true);
      // setError(Object.keys(error.response.data.error).toString());
    }
  };

  //validations
  const [serverError, setServerError] = useState({});
  const serverErrorMessage = (field) => {
    if (serverError[field]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {serverError[field][0]}
        </div>
      );
    }
  };
  const validateFn = () => {
    const feilds = {
      registration_number,
      // branch_number,
      financial_statements,
      company_name,
      // company_owner_name,
      // property_img,
    };
    for (const [key, value] of Object.entries(feilds)) {
      if (!value) {
        setServerError((prev) => ({ ...prev, [key]: ["مطلوب"] }));
      }
      if (key === "financial_statements") {
        if (value.length === 0) {
          setServerError((prev) => ({ ...prev, [key]: ["مطلوب"] }));
        }
      }
    }
  };

  const AuthErrorText = () => {
    return (
      <div
        className="text2"
        style={{ color: "red", marginTop: "0px", width: "100%" }}
      >
        لا يمكنك التسجيل بهذا الرقم
      </div>
    );
  };

  const handleSendReq = () => {
    setServerError({});
    sendApplication();
  };

  const removeFile = (name) => {
    const newFiles = (array) => {
      return array.filter((item) => item.name !== name);
    };
    setFinancial_statements((prev) => newFiles(prev));
  };

  const onChoseFile = (file) => {
    const isValidFileUploaded = (file) => {
      const validExtensions = ["pdf"];
      const fileExtension = file.type.split("/")[1];
      return validExtensions.includes(fileExtension);
    };

    setFinancial_statements((prev) => [...prev, ...file]);

    Object.values(file).map((f) => {
      if (!isValidFileUploaded(f)) {
        setError(" pdf الملف يجب ان يكون بصيغة");
        setOpenAlertError(true);
        removeFile(f.name);
      }
    });
  };

  const onChoseImage = (img) => {
    const isValidFileUploaded = (file) => {
      const validExtensions = ["png", "jpeg", "jpg"];
      const fileExtension = file.type.split("/")[1];
      return validExtensions.includes(fileExtension);
    };

    if (!isValidFileUploaded(img)) {
      setError("png, jpeg, jpg يجب ان تكون الصورة بصيغة");
      setOpenAlertError(true);
      return;
    }

    setproperty_img(img);
  };

  const RenderFiles = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {financial_statements.map((file) => {
          // console.log(financial_statements);
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "50px",
                width: "90%",
                backgroundColor: "#014e97",
                mt: 1,
                p: 1,
              }}
              key={Math.random()}
            >
              <div
                style={{
                  color: "#fff",
                  overflow: "hidden",
                  maxWidth: "100%",
                  maxHeight: "20px",
                }}
              >
                {file.name}
              </div>
              <Button sx={{ p: 0 }} onClick={() => removeFile(file.name)}>
                <Close sx={{ color: "#fff" }} />
              </Button>
            </Box>
          );
        })}
      </Box>
    );
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "استمارة طلب إيجار",
        items: [{ text: "طلبات الإيجار", path: "/renter/requests" }],
      },
    });
  }, []);

  useEffect(() => {
    if (req) {
      redirect(req.application_status_id, 1, navigate);
    }
  }, [req]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     getCompany();
  //   }, 1500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [registration_number]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div style={{ marginInline: "10px", width: "95%" }}>
      <SuccessApplication
        open={success}
        setOpen={setSuccess}
        appId={appId}
        token={token}
        createdByOwner={true}
      />
      <div className="box10">
        <div className="" style={{ textAlign: "right", direction: "rtl" }}>
          <div className="text8 center text-r">طلب إستئجار</div>
          {/* <div className="text9 text-r">المالك : محمد بن علي</div> */}
        </div>
      </div>
      <Grid
        container
        // direction={"row-reverse"}
        // justifyContent={"space-between"}
        spacing={2}
        sx={{ direction: "rtl" }}
      >
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12} direction={"column"}>
            <div className="box1">
              <div
                className="text2 t201 bold slb-text"
                style={{ color: "#1a1a1a" }}
              >
                رقم السجل <span style={{ color: "red" }}>*</span>
                {/* {loadingCompany && <CircularProgress size="0.8rem" />}
                {successCompany && <Check color="success" fontSize="1.5rem" />}
                {failedCompany && <Close color="error" fontSize="1.2rem" />} */}
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={registration_number}
                name="registration_number"
                onChange={(e) => setRegistration_number(e.target.value)}
              />
              {serverErrorMessage("registration_number")}
              {/* {authError && <AuthErrorText />} */}
              <div
                className="text2 t201 bold slb-text"
                style={{
                  color: "#1a1a1a",
                }}
              >
                اسم الشركة <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  ".Mui-disabled": {
                    backgroundColor: "#f8f8f8",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontFamily: "font-normal",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={company_name}
                name="company_name"
                onChange={(e) => setCompany_name(e.target.value)}
              />
              {serverErrorMessage("company_name")}
              <div
                className="text2 t201 bold slb-text"
                style={{
                  color: "#1a1a1a",
                }}
              >
                اسم مالك الشركة
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  ".Mui-disabled": {
                    backgroundColor: "#f8f8f8",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontFamily: "font-normal",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={company_owner_name}
                name="company_owner_name"
                onChange={(e) => setCompany_owner_name(e.target.value)}
              />
              {serverErrorMessage("company_owner_name")}
              <div
                className="text2 t201 bold slb-text"
                style={{ color: "#1a1a1a" }}
              >
                عدد الفروع
              </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                onChange={(e) => setBranch_number(e.target.value)}
                value={branch_number}
                name="branch_number"
              />
              {serverErrorMessage("branch_number")}
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} direction={"column"}>
            <div className="box1" style={{ marginBottom: 30 }}>
              <div className="text10" style={{ marginRight: "0px" }}>
                بيانات العقار
              </div>
              <div style={{ width: "100%" }}></div>
              <div className="text2 t201 bold slb-text">موقع العقار </div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={req.property_location}
                disabled
              />
              <div className="text2 t201 bold slb-text">العنوان المختصر</div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={req.short_code}
                disabled
              />
              <div className="text2 t201 bold slb-text">اسم المالك</div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={req.property_owner_name}
                disabled
              />

              <div className="text2 t201 bold slb-text">رقم جوال المالك</div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={req.property_owner_phone}
                disabled
              />
              <div className="text2 t201 bold slb-text">مبلغ الاإيجار</div>
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                size="small"
                value={req.origin_rent_amount}
                disabled
              />
            </div>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ marginLeft: "0px" }}>
          <div className="box1" style={{ height: "auto" }}>
            <div className="text10">
              قوائم مالية <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ width: "100%" }} />
            <div className="box12">
              <IconButton component="label">
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => onChoseFile(e.target.files)}
                  multiple
                  accept=".pdf"
                />
                <div className="box13">
                  <img src={icon1} alt="icon" />
                </div>
              </IconButton>
              {financial_statements.length > 0 ? (
                <div
                  className="text2 t201 bold"
                  style={{ textAlign: "center" }}
                >
                  <RenderFiles />
                </div>
              ) : (
                <div className="text2 t201 bold">
                  قم بإرفاق نسخه بصيغه PDF من القوائم المالية ل3 أشهر أو كشف
                  حساب
                </div>
              )}
            </div>
            {serverErrorMessage("financial_statements")}
          </div>
          <div className="box1" style={{ height: "auto" }}>
            <div className="text10">صورة المبنى</div>
            <div style={{ width: "100%" }} />
            <div className="box12">
              <IconButton component="label">
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => onChoseImage(e.target.files[0])}
                />
                <div className="box13">
                  <img src={icon1} alt="icon" />
                </div>
              </IconButton>
              {property_img ? (
                <div
                  className="text2 t201 bold"
                  style={{ textAlign: "center" }}
                >
                  {property_img.name}
                </div>
              ) : (
                <div className="text2 t201 bold">قم بارفاق صورة من المبنى</div>
              )}
            </div>
            {serverErrorMessage("property_img")}
          </div>
        </Grid>
      </Grid>
      <div className="box10">
        <div>
          <LoadingButton
            variant="contained"
            loading={loading}
            size="large"
            sx={{
              marginTop: "10px",
              // marginLeft: "10px",
              // width: "10vw",
              bgcolor: "#014E97",
            }}
            onClick={handleSendReq}
          >
            <div className="text3">إرسال الطلب</div>
          </LoadingButton>
        </div>
      </div>
      <AlertMessage
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={error}
        type={"error"}
      />
    </div>
  );
};

export default CompleteApplication;
