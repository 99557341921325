import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useState } from "react";
import { showDate } from "../../utils/common";

export default function DataTable({ data }) {
	const [sortDir, setSortDir] = useState(true);
	const sort = (asc) => {
		if (asc) {
			data.sort((a, b) => a.status_id - b.status_id);
		} else {
			data.sort((a, b) => b.status_id - a.status_id);
		}
	};
	const handleSort = () => {
		setSortDir((prev) => !prev);
		sort(sortDir);
	};
	const renderRows = () => {
		return data.map((item) => {
			return (
				<div
					// className="box10"
					style={{
						direction: "rtl",
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						marginTop: "10px",
						borderBottom: "1px solid #E7E7E7",
						padding: "5px",
					}}
					key={item.id}
				>
					<Grid container>
						<Grid item xs={2}>
							<div>
								<div
									className="text9 f-normal"
									style={{ color: "#080D2B" }}
								>
									{item.serial_no}{" "}
								</div>
							</div>
						</Grid>
						<Grid
							item
							xs={2}
							display={"flex"}
							justifyContent={"center"}
						>
							<div className="text9 f-normal">
								{showDate(item.created_at)}
							</div>
						</Grid>
						<Grid
							item
							xs={2}
							display={"flex"}
							justifyContent={"center"}
						>
							<div className="text9 f-normal">
								{showDate(item.date_for_payment)}
							</div>
						</Grid>
						<Grid
							item
							xs={2}
							display={"flex"}
							justifyContent={"center"}
						>
							<div className="text9 f-normal">
								{item.deserved_amount} ريال
							</div>
						</Grid>
						<Grid
							item
							xs={2}
							display={"flex"}
							justifyContent={"center"}
						>
							{item.paid_amount ? (
								<div className="text9 f-normal">
									{item.paid_amount} ريال
								</div>
							) : (
								<div className="text9 f-normal">0 ريال</div>
							)}
						</Grid>
						<Grid
							item
							xs={2}
							display={"flex"}
							justifyContent={"center"}
						>
							{item.status_id === 1 ? (
								<div className="text9 f-normal orange">
									غير مدفوع
								</div>
							) : (
								<div className="text9 f-normal text-green">
									تم الدفع
								</div>
							)}
						</Grid>
					</Grid>
				</div>
			);
		});
	};
	return (
		<div
			className="box1"
			style={{
				height: "auto",
				backgroundColor: "#F4F7FF",
				minWidth: "fit-content",
				overflowY: "hidden",
				// whiteSpace: "nowrap",
				width: "500px",
			}}
		>
			<div
				// className="box10"
				style={{
					direction: "rtl",
					display: "flex",
					// justifyContent: "space-between",
					width: "100%",
					alignItems: "center",
					marginTop: "10px",
					borderBottom: "1px solid #E7E7E7",
					padding: "15px",
					backgroundColor: "#fff",
					borderRadius: "4px",
					float: "none",
				}}
			>
				<Grid container>
					<Grid item xs={2}>
						<div>
							<div
								className="text9"
								style={{ color: "#080D2B", width: "550px" }}
							>
								رقم الفاتورة
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">تاريخ الإصدار</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">التاريخ المحدد للدفع</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">مبلغ القسط</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">المبلغ المدفوع</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
						sx={{ cursor: "pointer" }}
						onClick={handleSort}
					>
						<div className="text9">الحالة</div>
						{sortDir ? <ExpandMore /> : <ExpandLess />}
					</Grid>
				</Grid>
			</div>
			{/* rows */}
			{renderRows()}
		</div>
	);
}
