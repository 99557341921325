import { Button, CircularProgress, Grid } from "@mui/material";
import PieChartWithCenterLabel from "./PieChart";
import HorizontalLinearStepper from "./Stepper";
import icon1 from "../../../assets/check.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { redirectOwner, showDate } from "../../utils/common";
import { useReqStore } from "../../../store";

const CompleteRequset = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const reqId = localStorage.getItem("app-id");
  const [loading, setLoading] = useState(true);
  const { req, setReq } = useReqStore();
  const [installments, setInstallments] = useState([]);

  const chartData = [{ value: 6, label: "مكتمل", color: "#27AE60" }];

  const date = new Date(req.created_at).toLocaleDateString();
  const getData = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-applications/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      // setLoading(false);
      getInstallments(data.data.contracts.id);
    } catch (error) {
      console.log(error);
    }
  };
  const getInstallments = async (id) => {
    try {
      const { data } = await server.get(
        `/customers/deposits?contract_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInstallments(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const renderInstallments = () => {
    return installments.map((item) => {
      return (
        <div
          // className="box10"
          style={{
            direction: "rtl",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: "10px",
            borderBottom: "1px solid #E7E7E7",
            padding: "5px",
          }}
          key={item.id}
        >
          <Grid container>
            <Grid item xs={6}>
              <div>
                <div className="text9" style={{ color: "#080D2B" }}>
                  {item.amount} ريال
                </div>
              </div>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"center"}>
              <div className="text9">{showDate(item.date_for_payment)}</div>
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (req) {
      redirectOwner(req.application_status_id, 6, navigate);
    }
  }, [req]);

  return (
    <div className="req-root vw100">
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="box10">
            <div className="box11">
              <div className="text8 center">طلباتي</div>
            </div>
            <div>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  // width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={() => navigate("/owner/requests/new")}
              >
                <div className="text3">إنشاء طلب جديد</div>
              </Button>
            </div>
          </div>
          <div className="box14" style={{ height: "auto" }}>
            <Grid
              container
              sx={{
                direction: "rtl",
                borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
              }}
            >
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <div className="box11">
                  <div className="text22 center">{`الطلب #${req.serial_number}`}</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {date}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={0}
                xs={0}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                  },
                }}
              >
                <div className="box11">
                  <div className="text9 center">حالة الطلب</div>
                  <div
                    className="text9"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="shape2"></div>
                    <p className="text22" style={{ marginTop: "5%" }}>
                      الطلب مكتمل
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={9} />
                <div className="text9 center">مكتمل بنسبة</div>
                <PieChartWithCenterLabel data={chartData} />
              </Grid>
            </Grid>
            <div className="box14" />
            <Grid
              container
              sx={{
                direction: "rtl",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                padding: "10px",
                borderRadius: "8px",
                // display: "inline-block",
              }}
            >
              {/* <Grid item xs={1}>
								<div className="text9 center">حالة الطلب: </div>
							</Grid> */}
              <Grid item xs={12}>
                <HorizontalLinearStepper
                  acitve={6}
                  statusName={req.contracts?.status.name}
                />
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                direction: "rtl",
                padding: "10px",
                // display: "inline-block",
              }}
            >
              <div style={{ display: "flex" }}>
                <img src={icon1} alt="icon" />
                <div
                  className="text8"
                  style={{
                    width: "100%",
                    padding: "5px",
                    borderTop: "10px",
                  }}
                >
                  إكتمل الطلب بنجاح
                </div>
                {/* <div className="text11 text-r">عبارة عن رسوم تدفع للمنصه</div> */}
              </div>

              <div
                className="text8"
                style={{
                  width: "100%",
                  padding: "5px",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                الإيرادات
              </div>

              <div
                className="box1 w70"
                style={{
                  height: "auto",
                  backgroundColor: "#F4F7FF",
                  minWidth: "min-content",
                  width: "35%",
                }}
              >
                <div
                  // className="box10"
                  style={{
                    direction: "rtl",
                    display: "flex",
                    // justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "10px",
                    borderBottom: "1px solid #E7E7E7",
                    padding: "15px",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <div>
                        <div className="text6" style={{ color: "#080D2B" }}>
                          مبلغ القسط
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <div className="text9">التاريخ المحدد للدفع</div>
                    </Grid>
                  </Grid>
                </div>
                {renderInstallments()}
              </div>
              {/*  */}
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default CompleteRequset;
