import image1 from "../../../assets/empty 1.png";
import { Button, Grid } from "@mui/material";
import "./request.css";
import { useNavigate } from "react-router-dom";
import { useNavStore, useUserStor } from "../../../store";
import { useEffect } from "react";
import DataTable from "./DataTable";
import server from "../../../api/server";

const RenterRequest = () => {
  const navigate = useNavigate();
  const { dashboardData, token, setDashboardData } = useUserStor();
  const localToken = localStorage.getItem("token");

  const getData = async () => {
    try {
      const { data } = await server.get("/customers/get-data", {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setDashboardData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    useNavStore.setState({ nav: { text: "طلبات الإيجار" } });
    getData();
  }, []);

  const handleCreateNewReq = () => {
    navigate("/renter/requests/new");
  };

  const renderApplications = () => {
    return (
      <div>
        <div className="box10">
          <div className="box11">
            <div className="text8 center">طلباتي</div>
          </div>
          <div>
            <Button
              variant="contained"
              size="large"
              sx={{
                marginTop: "10px",
                marginLeft: "10px",
                // width: "10vw",
                bgcolor: "#014E97",
              }}
              onClick={handleCreateNewReq}
            >
              <div className="text3">إنشاء طلب جديد</div>
            </Button>
          </div>
        </div>
        <div
          className="box1"
          style={{
            height: "auto",
            minWidth: "auto",
            borderRadius: "8px",
            overflowX: "auto",
            maxWidth: "80vw",
          }}
        >
          <div
            className="box10"
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div className="box11" style={{ width: "100%" }}>
              <div className="text8 center">كل الطلبات</div>
            </div>
          </div>
          {/* table */}
          <DataTable data={dashboardData.applications} />
        </div>
      </div>
    );
  };

  const renderEmptyScreen = () => {
    return (
      <div className="renter-req-root">
        <div className="shape10">
          <img src={image1} alt="img" />
        </div>
        <div className="text20">
          لا توجد طلبات إيجار محفوظه يمكنك إنشاء طلب جديد
        </div>
        <Button
          variant="outlined"
          size="large"
          sx={{
            minWidth: "20vw",
            width: "max-content",
            borderRadius: "52px",
            border: "solid 1px #004A98",
          }}
          onClick={handleCreateNewReq}
        >
          <div className="text21">إنشاء طلب إيجار +</div>
        </Button>
      </div>
    );
  };
  return dashboardData?.applications?.length > 0
    ? renderApplications()
    : renderEmptyScreen();
};

export default RenterRequest;
