import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Grid, FormControl, Select, Button } from "@mui/material";
import { useUserStor, useNavStore, useNotificationStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import NotificationsList from "./NotificationsList";
import LoginAs from "./LoginAs";
import BreadcrumbsNav from "./Breadcrumbs";
import server from "../../api/server";

export default function PrimarySearchAppBar() {
  const [open, setOpen] = React.useState(false);
  const [anchorLoginAs, setAnchorLoginAs] = React.useState(null);
  const [openLoginAs, setOpenLoginAs] = React.useState(false);

  const { newNotifications } = useNotificationStore();

  const handleClickLoginAs = (event) => {
    setAnchorLoginAs(event.currentTarget);
    setOpenLoginAs(true);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const localToken = localStorage.getItem("token");

  const navigate = useNavigate();

  React.useEffect(() => {
    // getUnreadNotifications();
  }, []);

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <Box
      sx={{
        flexGrow: 1,
        direction: "rtl",
        backgroundColor: "#fff",
        width: "100%",
        display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        variant="outlined"
        // sx={{ borderBottom: "1px solid #818181" }}
        elevation={0}
      >
        <Toolbar variant="regular">
          <BreadcrumbsNav />

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                marginLeft: "20px",
              },
              alignItems: "center",
              transform: "scale(0.8)",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(67, 122, 179, 0.20)",
                borderRadius: "50%",
                display: "flex",
                width: "40px",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={() => setOpen(true)}
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                {newNotifications.length === 0 ? (
                  <NotificationsIcon />
                ) : (
                  <Badge variant="dot" color="success">
                    <NotificationsIcon />
                  </Badge>
                )}
              </IconButton>
            </div>
            <Grid container direction={"column"} sx={{ marginInline: "20px" }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingInline: "8px",
                }}
              >
                <div className="text6">{user?.name}</div>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button onClick={handleClickLoginAs} startIcon={<ExpandMore />}>
                  <div className="text7">مستأجر</div>
                </Button>
              </Grid>
            </Grid>

            <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              onClick={() => navigate("/renter/profile")}
              aria-haspopup="true"
              color="inherit"
            >
              {/* <AccountCircle /> */}
              <img
                src={`https://api.qistar.sa/storage/${user?.profile_pic}`}
                className="pro-img"
                alt="profile"
              />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={() => navigate("/renter/profile")}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <LoginAs
        open={openLoginAs}
        setOpen={setOpenLoginAs}
        anchorEl={anchorLoginAs}
      />
      <NotificationsList open={open} setOpen={setOpen} />
    </Box>
  );
}
