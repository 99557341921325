import React, { useEffect } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../../assets/image1.svg";
import { useNavigate, useLocation } from "react-router-dom";
import icon1 from "../../../assets/Frame.png";
import icon2 from "../../../assets/Frame (1).png";
import icon3 from "../../../assets/Frame (2).png";
import icon4 from "../../../assets/Vector.png";
import icon5 from "../../../assets/lucide_chevron-down.png";
import icon6 from "../../../assets/lucide_chevron-up.png";
import { Fab } from "@mui/material";
import contract from "../../../assets/contratctW.png";
import ArticleIcon from "@mui/icons-material/Article";
import { CreditScore } from "@mui/icons-material";

const SideBar = ({ sideBarMain, expand, setExpand }) => {
  const [open, setOpen] = React.useState({});
  const location = useLocation();

  let navigate = useNavigate();

  const handleClick = (id) => {
    setOpen(
      Object.keys(open).forEach((key) => {
        open[key] = false;
      })
    );
    setOpen({ ...open, [id]: !open[id] });
    navigate(id);
    // console.log(open);
  };

  const routes = [
    {
      page: "لوحة المعلومات",
      id: "/owner/dashboard",
      icon: <img src={icon1} alt="icon" />,
    },
    {
      page: "طلباتي",
      id: "/owner/requests",
      icon: <ArticleIcon />,
    },
    {
      page: "العقود",
      id: "/owner/contracts",
      icon: <img src={contract} alt="icon" width={20} />,
    },
    {
      page: "الإيرادات",
      id: "/owner/deposits",
      icon: <CreditScore />,
    },
    {
      page: "المساعدة والدعم",
      id: "/owner/support",

      icon: <img src={icon4} alt="icon" />,
    },
  ];

  const shrink = () => {
    return expand ? "" : "sideBar-shrink";
  };

  const logoWidth = () => {
    return expand ? "120px" : "80px";
  };

  useEffect(() => {
    setOpen({ ...open, [location.pathname]: true });
    //   if (location.pathname.includes("/home/settings")) {
    //     setOpen({ ...open, 3: true });
    //   } else if (location.pathname.includes("/home/support")) {
    //     setOpen({ ...open, 5: true });
    //   } else if (location.pathname.includes("/home/users")) {
    //     setOpen({ ...open, 4: true });
    //   }
  }, []);

  const sidebarItems = () => {
    return routes.map(({ id, page, icon, subPage, path }) => {
      const openList = open[id] || false;

      const active = () => {
        return openList ? "active" : "reverse";
      };

      const activeIcone = () => {
        return openList ? "active-icone" : "reverse-icone";
      };

      const display = () => {
        return !expand ? "displayNon" : "";
      };

      return (
        <div key={id}>
          <ListItemButton
            className={active()}
            onClick={() => handleClick(id, path)}
            sx={{
              ".MuiTypography-root": {
                fontFamily: "inherit",
                fontSize: "0.8rem",
              },
            }}
          >
            <ListItemIcon className={activeIcone()} sx={{ width: "10px" }}>
              {icon}
            </ListItemIcon>
            <ListItemText className={`direction ${display()}`} primary={page} />
          </ListItemButton>
        </div>
      );
    });
  };

  return (
    <>
      <div className={`sideBar ${sideBarMain} ${shrink()} sidebar-mobile`}>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "linear-gradient(180deg, #004A98 0%, #350055 100%);",
            right: "0px",
            direction: "rtl",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{ bgcolor: "transparent" }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  // maxWidth: "-webkit-fill-available",
                  marginLeft: "8vw",
                  marginTop: "20px",
                  width: logoWidth(),
                }}
              />
            </ListSubheader>
          }
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Fab
              size="small"
              sx={{
                minHeight: "20px",
                height: "20px",
                width: "20px",
                ml: "-7px",
                bgcolor: "#fff",
              }}
              onClick={() => setExpand((state) => !state)}
            >
              {expand ? (
                <img src={icon5} alt="i" />
              ) : (
                <img src={icon6} alt="i" />
              )}
            </Fab>
          </div>
          {sidebarItems()}
        </List>
      </div>
    </>
  );
};

export default SideBar;
