import { Button, Grid, TextField } from "@mui/material";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useUserStor } from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import AlertMessage from "../utils/AlertMessage";

const Signup = () => {
  const [focused, setFocused] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  //validatios
  const [nameServerErrorText, setNameServerErrorText] = useState(null);
  const [emailServerErrorText, setEmailServerErrorText] = useState(null);
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const NameServerError = () => {
    if (nameServerErrorText) {
      return (
        <div className="text2" style={{ color: "red", marginTop: "0px" }}>
          {nameServerErrorText}
        </div>
      );
    }
  };
  const EmailServerError = () => {
    if (emailServerErrorText) {
      return (
        <div className="text2" style={{ color: "red", marginTop: "0px" }}>
          {emailServerErrorText}
        </div>
      );
    }
  };

  const { setUserData, setToken, userData, token, otpData } = useUserStor();

  const navigate = useNavigate();

  const register = async () => {
    console.log(otpData);
    setEmailServerErrorText(null);
    setNameServerErrorText(null);
    if (!name) {
      if (!name) {
        setNameServerErrorText("مطلوب");
      }

      return;
    }
    if (email && !validateEmail(email)) {
      setEmailServerErrorText("البريد غير صحيح");
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await server.post("/customers/register", {
        phone: otpData.phone,
        name,
        email,
        customer_type: "1",
        identity_number: otpData.identity_number,
      });
      if (data.data) {
        setUserData(data.data.customer);
        setToken(data.data.token);
        // console.log(userData);
        // console.log(token);
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", JSON.stringify(data.data.customer));
        navigate("/home");
      }
      // navigate("/otp");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.response.status === 500) {
        setServerError(error.response.data.message);
        setOpenErrorMessage(true);
        console.log(error);
      } else if (error.response?.data?.error?.name) {
        setNameServerErrorText(error.response.data.error.name[0]);
      } else if (error.response?.data?.error?.email) {
        setEmailServerErrorText(error.response.data.error.email[0]);
      }
    }
  };

  const handleOnfocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    setFocused(false);
  };

  const handleOnclickContinue = () => {
    register();
  };
  const TextLabel = ({ children }) => {
    const LabelText = useMemo(() => {
      // console.log(focused);
      return (
        <div
          className="text2"
          style={{ color: focused ? "#004A98" : "#4F4F4F" }}
        >
          {children}
        </div>
      );
    }, [focused]);
    return LabelText;
  };

  useEffect(() => {
    if (!otpData) {
      navigate("/");
    }
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${image22})`,
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
      }}
    >
      <Grid
        container
        className="login-container"
        sx={{ flexDirection: "row-reverse" }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="image-container"
        >
          <img className="image1" src={image1} alt="Qistar" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="login-root"
        >
          <div className="logIn">
            <img src={image2} className="image2" alt="Qistar" />
            <div className="text1">تسجيل مستخدم جديد</div>
            <TextLabel>الأسم</TextLabel>
            <div style={{ width: "100%" }}>
              <TextField
                error={nameServerErrorText}
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  // fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                onFocus={handleOnfocus}
                onBlur={handleOnBlur}
                onChange={(e) => setName(e.target.value)}
              />
              <NameServerError />
            </div>
            <TextLabel>البريد الإلكتروني</TextLabel>
            <div style={{ width: "100%" }}>
              <TextField
                error={emailServerErrorText}
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  // fontWeight: "700px",
                }}
                type="email"
                className="textInput1"
                variant="outlined"
                onFocus={handleOnfocus}
                onBlur={handleOnBlur}
                onChange={(e) => setEmail(e.target.value)}
              />
              <EmailServerError />
            </div>
            <TextLabel>رقم الجوال</TextLabel>
            {otpData && (
              <TextField
                sx={{
                  ":focus-within": {
                    backgroundColor: "#F1F4FF",
                  },
                  width: "100%",
                  direction: "rtl",
                  color: "GrayText",
                  // fontWeight: "700px",
                }}
                className="textInput1"
                variant="outlined"
                onFocus={handleOnfocus}
                onBlur={handleOnBlur}
                // onChange={(e) => setPhone(e.target.value)}
                value={otpData.phone}
                disabled
              />
            )}

            <LoadingButton
              loading={isLoading}
              variant="contained"
              sx={{ backgroundColor: "#004A98", width: "100%" }}
              onClick={handleOnclickContinue}
            >
              <div className="text3"> متابعة</div>
            </LoadingButton>
          </div>
        </Grid>
      </Grid>
      <AlertMessage
        message={serverError}
        open={openErrorMessage}
        setOpen={setOpenErrorMessage}
        type={"error"}
      />
    </div>
  );
};

export default Signup;
