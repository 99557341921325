import { InputLabel, TextField, styled } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "./TextEditor";
import icone1 from "../../../assets/upload.png";
import { useNavigate } from "react-router-dom";
import { useNavStore, useUserStor } from "../../../store";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import server from "../../../api/server";

const NewTicketOwner = () => {
	const navigate = useNavigate();

	const token = localStorage.getItem("token");
	//  const { token } = useUserStor();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState(null);
	const [description, setDescription] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [filePath, setFilePath] = useState(null);

	const formData = new FormData();
	formData.append("title", title);
	formData.append("description", description);
	formData.append("file_name", fileName);
	formData.append("file_path", filePath);

	const handleUpload = (file) => {
		setFileName(file.name);
		setFilePath(file);
	};

	const sendTicket = async () => {
		setLoading(true);
		try {
			const { data } = await server.post(
				"/customers/store-ticket",
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log(data);
			navigate("/owner/support");
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		useNavStore.setState({
			nav: {
				text: "التذكرة",
				items: [{ text: "المساعدة والدعم", path: "/owner/support" }],
			},
		});
	}, []);

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	return (
		<div style={{ margin: "2rem", gap: "20px", display: "grid" }}>
			<div className="box10">
				<div className="box11">
					<div className="text8 center">التذكرة #24252</div>
				</div>
				<div>
					<LoadingButton
						variant="contained"
						size="large"
						sx={{
							marginTop: "10px",
							marginLeft: "10px",
							width: "10vw",
							bgcolor: "#014E97",
						}}
						loading={loading}
						onClick={sendTicket}
					>
						<div className="text3">إرسال</div>
					</LoadingButton>
				</div>
			</div>
			<div className="box53">
				<div className="text8">العنوان</div>
				<div className="text11 mr0">
					وصف واضح لعنوان المشكلة التي تواجهك
				</div>
				<div className="box54">
					<InputLabel shrink htmlFor="bootstrap-input">
						<div className="text8 text-r">العنوان .</div>
					</InputLabel>
					<TextField
						fullWidth
						label=""
						id="title"
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>
			</div>
			<div className="box53">
				<div className="text8">شرح المشكلة</div>
				<div className="text11 mr0">
					وصف واضح لعنوان المشكلة التي تواجهك
				</div>
				{/*  */}
				<TextEditor setDescription={setDescription} />
			</div>
			<div className="box53">
				<div className="text8 mr0">إرفاق صور وملفات اضافيه</div>
				<div className="text11 mr0">
					وصف واضح لعنوان المشكلة التي تواجهك
				</div>
				{/*  */}
				<LoadingButton
					variant="contained"
					size="small"
					sx={{
						marginTop: "10px",
						marginLeft: "10px",
						// marginRight: "5%",
						width: "15vw",
						bgcolor: "#014E97",
						gap: 5,
						"&:hover": { backgroundColor: "#014E97" },
					}}
					component="label"
				>
					<VisuallyHiddenInput
						onChange={(e) => handleUpload(e.target.files[0])}
						type="file"
					/>
					<img src={icone1} alt="icon" />
					<div className="text3">رفع صورة</div>
				</LoadingButton>
				{fileName && <div className="text11 mr0">{fileName}</div>}
			</div>
		</div>
	);
};

export default NewTicketOwner;
