import {
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import { useRef } from "react";
import Notifications from "../../Dashboard/Notifications";

const NotificationsList = ({ open, setOpen }) => {
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Popper
      open={open}
      // anchorEl={anchorRef.current}
      // role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      sx={{ width: "30%", ml: "20px", zIndex: "99", mt: 5 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <div className="box1 h0">
                {/* {/* <p className="text8">صندوق الوارد</p> */}
                <Grid container>
                  <Notifications userType="owner" setOpen={setOpen} />
                </Grid>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default NotificationsList;
