import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Check } from "@mui/icons-material";
import server from "../../../api/server";
import { CircularProgress, Tooltip } from "@mui/material";

//owner steps
// const steps = [
// 	"تم إرسال الطلب بنجاح",
// 	"الفحص الإئتماني للمستأجر",
// 	"قبول العرض المالي",
// 	"سداد قيمة الإيجار",
// 	"إبرام العقد على منصة ايجار",
// 	"مكتمل",
// ];

export default function HorizontalLinearStepper({ acitve, error, statusName }) {
  const [activeStep, setActiveStep] = React.useState(acitve || 0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [steps, setSteps] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const token = localStorage.getItem("token");

  const isStepOptional = (step) => {
    return step === 1;
  };

  const getStatus = async () => {
    setLoading(true);
    try {
      const { data } = await server.get(
        "/customers/get-applications-statuses",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      setSteps(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // const handleNext = () => {
  //   let newSkipped = skipped;
  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(newSkipped.values());
  //     newSkipped.delete(activeStep);
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const backgroundStep = (a, c, e) => {
    if (a) {
      return e ? "#EB001B" : "#FFC300";
    } else if (c) {
      return "#27AE60";
    } else {
      return "#F1F4FF";
    }
  };

  const renderLabelName = (label) => {
    // if (label.id === 3) return "الموافقة على العرض المالي";
    return label.name;
  };

  const CustomIcon = ({ active, completed, icon }) => {
    const contents = completed ? <Check fontSize="inherit" /> : icon;
    return (
      <div
        style={{
          backgroundColor: backgroundStep(active, completed, error),
          color: active || completed ? "white" : "#1A1A1A",
          minHeight: "25px",
          minWidth: "25px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // padding: "5px",
          fontSize: "0.7rem",
          border: !active && !completed ? "solid 1px #1A1A1A" : "none",
        }}
      >
        {contents}
      </div>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Stepper activeStep={acitve || 1} className="overF-auto">
          {steps.map((label, index) => {
            if (label.id === 7) return;
            const stepProps = {};
            const labelProps = {
              StepIconComponent: CustomIcon,
            };

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Tooltip
                placement="top"
                arrow
                title={
                  <div style={{ direction: "rtl" }}>
                    <div style={{ fontFamily: "font-normal" }}>
                      {label.info}
                    </div>
                    {label.id === 5 && statusName && (
                      <div style={{ fontFamily: "font-normal" }}>
                        حالة الطلب عند الوسيط: {statusName}
                      </div>
                    )}
                  </div>
                }
              >
                <Step
                  key={label.id}
                  {...stepProps}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {window.innerWidth > 900 ? (
                    <StepLabel
                      {...labelProps}
                      sx={{
                        ".Mui-completed": {
                          color: "#27AE60",
                        },
                        ".Mui-active": {
                          color: error ? "#EB001B" : "#FFC300",
                        },
                      }}
                    >
                      <div
                        className="normal-step"
                        style={{
                          marginRight: "5px",
                          fontSize: "0.72rem",
                        }}
                      >
                        {renderLabelName(label)}
                      </div>
                    </StepLabel>
                  ) : (
                    <StepLabel
                      {...labelProps}
                      sx={{
                        flexDirection: "column",
                        ".Mui-completed": {
                          color: "#27AE60",
                        },
                        ".Mui-active": {
                          color: error ? "#EB001B" : "#FFC300",
                        },
                      }}
                      StepIconComponent={CustomIcon}
                    >
                      <div
                        className="normal-step"
                        style={{ width: "max-content" }}
                      >
                        {renderLabelName(label)}
                      </div>
                    </StepLabel>
                  )}
                </Step>
              </Tooltip>
            );
          })}
        </Stepper>
      )}
    </Box>
  );
}
