import { Button, Grid } from "@mui/material";
import icon1 from "../../../assets/Group.png";
import PieChartWithCenterLabel from "./PieChart";
import icon2 from "../../../assets/icon1.png";
import icon3 from "../../../assets/icon3.png";
import { useNavStore, useUserStor } from "../../../store";
import { useEffect } from "react";
import server from "../../../api/server";
import { useNavigate } from "react-router-dom";
import Inbox from "../../Dashboard/Inbox";
import Activities from "../../Dashboard/Activities";
import image1 from "../../../assets/empty 1.png";

const DashboardOwner = () => {
  const { userData, setDashboardData, dashboardData } = useUserStor();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const getData = async () => {
    try {
      const { data } = await server.get("/customers/get-data", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDashboardData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const isUserOwner = () => {
    if (user?.customer_type_id === 1) {
      navigate("/home");
    }
  };

  const refreshData = async () => {
    try {
      const { data } = await server.get("/customers/refresh-applications", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNewReq = () => {
    navigate("/owner/requests/new");
  };
  const renderEmptyScreen = () => {
    return (
      <div className="renter-req-root" style={{ justifyContent: "flex-start" }}>
        <div className="shape10">
          <img src={image1} alt="img" />
        </div>
        <div className="text20">
          لا توجد طلبات إيجار محفوظه يمكنك إنشاء طلب جديد
        </div>
        <Button
          variant="outlined"
          size="large"
          sx={{
            minWidth: "20vw",
            width: "max-content",
            borderRadius: "52px",
            border: "solid 1px #004A98",
          }}
          onClick={handleCreateNewReq}
        >
          <div className="text21">إنشاء طلب تأجير +</div>
        </Button>
      </div>
    );
  };

  useEffect(() => {
    useNavStore.setState({ nav: { text: "لوحة المعلومات" } });
    refreshData();
    getData();
    isUserOwner();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {dashboardData.applications?.length === 0 ? (
        renderEmptyScreen()
      ) : (
        <Grid
          container
          direction={"row"}
          sx={{ display: "flex", direction: "rtl", mt: "0px" }}
          mt={"0px"}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="wc400px"
              style={{ marginRight: "0px", fontFamily: "font" }}
            >
              {`أهلا بك , ${userData?.name.split(" ")[0]} 👋`}
            </div>
            <Button
              variant="contained"
              size="large"
              sx={{
                marginTop: "10px",
                marginLeft: "10px",
                // width: "10vw",
                bgcolor: "#014E97",
              }}
              onClick={handleCreateNewReq}
            >
              <div className="text3">إنشاء طلب جديد</div>
            </Button>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={"flex"}
            sx={{ height: "max-content" }}
          >
            <div className="admin-box1 h0" style={{ padding: 10 }}>
              <div
                style={{ marginBottom: "10px", width: "100%" }}
                className="flex-center"
              >
                <img
                  src={icon2}
                  alt="icon"
                  style={{ width: "20px", height: "20px" }}
                />
                <p className="text8 mr5">صندوق الوارد</p>
              </div>

              <Grid container>
                <Inbox userType="owner" />
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={"flex"}
            sx={{ height: "max-content", flexDirection: "row" }}
          >
            <div className="admin-box1 h0" style={{ padding: 10 }}>
              <div
                style={{ marginBottom: "1em", width: "100%" }}
                className="flex-center"
              >
                <img
                  src={icon3}
                  alt="icon"
                  style={{ width: "20px", height: "20px" }}
                />
                <p className="text8 mr5">الأنشطة الاخيرة</p>
              </div>
              <Grid container>
                <Activities userType="owner" />
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default DashboardOwner;
