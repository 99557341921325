import * as React from "react";
import { PieChart, pieArcClasses } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";

// const data = [
//   { value: 7, label: "المدفوع", color: "#014E97" },
//   { value: 3, label: "المتبقي", color: "#F1F4FF" },
// ];

export default function PieChartWithCenterLabel({ data }) {
	const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
	const percent = data[0].value / TOTAL;

	const size = {
		width: 300,
		height: 200,
		legend: { hidden: true },
	};

	const StyledText = styled("text")(({ theme }) => ({
		fill: theme.palette.text.primary,
		textAnchor: "middle",
		dominantBaseline: "central",
		fontSize: 30,
		// fontWeight: 700,
		color: "#014E97",
	}));

	function PieCenterLabel({ children }) {
		const { width, height, left, top } = useDrawingArea();
		return (
			<StyledText x={left + width / 2} y={top + height / 2}>
				{children}
			</StyledText>
		);
	}
	return (
		<PieChart
			series={[
				{
					data,
					innerRadius: 70,
					startAngle: 0,
					endAngle: 360,
					cornerRadius: 20,
				},
			]}
			{...size}
			//   sx={{
			//     transform: "scale(0.5)",
			//   }}
			margin={{ right: 5, top: 0 }}
		>
			<PieCenterLabel>{`${(percent * 100).toFixed(0)}%`}</PieCenterLabel>
		</PieChart>
	);
}
