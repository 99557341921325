import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserStor } from "../../../store";

export default function DataTable({ data }) {
	const navigate = useNavigate();

	const { setTicket } = useUserStor();

	const handleShow = (row) => {
		setTicket(row);
		navigate("/owner/support/ticket");
	};

	const renderRow = data.map((row) => {
		const date = new Date(row.created_at).toLocaleDateString();
		const description = row.description.substring(0, 20);

		return (
			<div
				onClick={() => handleShow(row)}
				style={{
					direction: "rtl",
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
					alignItems: "center",
					marginTop: "10px",
					borderBottom: "1px solid #E7E7E7",
					padding: "5px",
				}}
				key={row.id}
			>
				<Grid container>
					<Grid item xs={2}>
						<div>
							<div
								className="text9 text50 bold"
								style={{ color: "#080D2B" }}
							>
								{row.id}#
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9 text50 bold">{date}</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9 text50 bold">{row.title}</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9 text50 bold">
							{description}...
						</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9 text50 bold">
							{row.status.name}
						</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<Button variant="contained">
							<div
								className="text9 text50 bold"
								style={{ color: "#fff" }}
							>
								عرض
							</div>
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	});

	return (
		<div
			className="box1"
			style={{
				height: "auto",
				backgroundColor: "#F4F7FF",
				minWidth: "min-content",
				width: "-webkit-fill-available",
				// width: "-moz-available",
				// width: "90vmax",
			}}
		>
			<div
				style={{
					direction: "rtl",
					display: "flex",
					width: "100%",
					alignItems: "center",
					marginTop: "10px",
					borderBottom: "1px solid #E7E7E7",
					padding: "15px",
					backgroundColor: "#fff",
					borderRadius: "4px",
					minWidth: "500px",
				}}
			>
				<Grid container>
					<Grid item xs={2}>
						<div>
							<div className="text6" style={{ color: "#080D2B" }}>
								رقم التذكرة
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">التاريخ</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">العنوان</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">الوصف</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">الحالة</div>
					</Grid>
					<Grid
						item
						xs={2}
						display={"flex"}
						justifyContent={"center"}
					>
						<div className="text9">خيارات</div>
					</Grid>
				</Grid>
			</div>
			{/* rows */}
			{renderRow}
		</div>
	);
}
