import { Grid, CircularProgress, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import server from "../../api/server";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Activities = ({ userType }) => {
  const [loading, setLoading] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState();

  const localToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const { data } = await server.get("/customers/get-activities", {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setData(data.data);
      setLoadingData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const goToApplication = async (link, id) => {
    setLoading({ [id]: true });
    try {
      const { data } = await server.get(`/customers${link}`, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      // setLoading({ [link]: false });
      localStorage.setItem("req", JSON.stringify(data.data));
      if (data.data.application_status_id) {
        navigate(`/${userType}/requests/${data.data.application_status_id}`);
      }
    } catch (error) {
      console.log(error);
      setLoading({ [id]: false });
    }
  };

  const appIsCanceled = (link) => {
    if (link.details.includes("قمت بالغاء")) return true;
    if (link.application_status_id === 7) return true;
  };

  useEffect(() => {
    getData();
  }, []);

  const handleShow = (item) => {
    if (item.link.includes("get-applications")) {
      goToApplication(item.link, item.id);
    }
  };

  const renderData = () => {
    return data?.slice(0, 4).map((item) => {
      return (
        <Grid item xs={12} key={item.id}>
          <div
            className="box3"
            style={{
              borderRadius: "5px",
              padding: 5,
              paddingLeft: 0,
            }}
          >
            <div className="text14 f-normal">{item.details}</div>
            {!appIsCanceled(item) ? (
              <LoadingButton
                loading={loading[item.id]}
                onClick={() => handleShow(item)}
              >
                <div className="text13">عرض</div>
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={loading[item.id]}
                sx={{ "&:hover": { background: "#fff" } }}
                disabled
              >
                <div className="text13" style={{ color: "#fff" }}>
                  عرض
                </div>
              </LoadingButton>
            )}
          </div>
          <Divider sx={{ mb: "3px", mt: "3px" }} />
        </Grid>
      );
    });
  };

  return (
    <>
      {loadingData ? (
        <CircularProgress />
      ) : (
        <>{data?.length === 0 ? <div>لاتوجد أنشطة</div> : renderData()}</>
      )}
    </>
  );
};

export default Activities;
