import { create } from "zustand";
import server from "./api/server";

export const useUserStor = create((set) => ({
  isOwner: 0,
  phone: null,
  setPhone: (newPhone) => set((state) => ({ ...state, phone: newPhone })),

  uuid: null,
  setUuid: (uuid) => set((state) => ({ ...state, uuid })),

  otpData: null,
  setOtpData: (otpData) => set((state) => ({ ...state, otpData })),

  userData: null,
  setUserData: (userData) => set((state) => ({ ...state, userData })),

  adminData: null,
  setAdminData: (adminData) => set((state) => ({ ...state, adminData })),

  token: null,
  setToken: (token) => set((state) => ({ ...state, token })),

  ticket: {},
  setTicket: (ticket) => set((state) => ({ ...state, ticket })),

  dashboardData: {},
  setDashboardData: (dashboardData) =>
    set((state) => ({ ...state, dashboardData })),
}));

export const useReqStore = create((set) => ({
  req: {},
  setReq: (req) => set((state) => ({ ...state, req })),
  fees: null,
  setFees: (fees) => set((state) => ({ ...state, fees })),
}));

export const useNavStore = create((set) => ({
  nav: { text: "لوحة المعلومات" },
}));

const updateObjectById = (array, idToUpdate, updatedFields) => {
  return array.map((obj) =>
    obj.id === idToUpdate ? { ...obj, ...updatedFields } : obj
  );
};
export const useNotificationStore = create((set) => ({
  notifications: [],
  setNotifications: (data) =>
    set((state) => ({
      ...state,
      notifications: data,
      newNotifications: data.slice(0, 4).filter((item) => item.is_read === 0),
    })),
  updateRead: (id) =>
    set((state) => ({
      ...state,
      notifications: updateObjectById(state.notifications, id, {
        is_read: 1,
      }),
      newNotifications: state.newNotifications.filter((item) => item.id !== id),
    })),
  newNotifications: [],
  setNew: (data) => set((state) => ({ ...state, newNotifications: data })),
  fetchNotifications: async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await server.get("/customers/get-notifications", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => ({ ...state, notifications: data.data }));
      const unreadedNotifications = data.data
        .slice(0, 4)
        .filter((item) => item.is_read === 0);
      set((state) => ({ ...state, newNotifications: unreadedNotifications }));
    } catch (error) {
      console.log(error);
    }
  },
}));

export const useSettingStore = create((set) => ({
  settings: {},
  setSettings: (data) => set((state) => ({ ...state, settings: data })),
}));
export const usePayStore = create((set) => ({
  currentPayment: {},
  setCurrentPayment: (data) =>
    set((state) => ({ ...state, currentPayment: data })),
}));
