import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import image8 from "../../assets/image_8.png";
import { useUserStor } from "../../store";
import server from "../../api/server";
import { useInterval } from "../utils/common";
import { useNavigate } from "react-router-dom";

const NafathCheck = () => {
  const { otpData } = useUserStor();
  const navigate = useNavigate();

  const TextLabel = ({ children }) => {
    const LabelText = useMemo(() => {
      // console.log(focused);
      return (
        <div className="text2" style={{ color: "#4F4F4F" }}>
          {children}
        </div>
      );
    }, []);
    return LabelText;
  };

  const nafathCheck = async () => {
    if (!otpData) return;
    try {
      const { data } = await server.get(
        `/customers/nafath-check/${otpData.requestId}`
      );
      if (data.data.status === "COMPLETED") {
        navigate("/signup");
      } else if (data.data.status === "WAITING") {
        return;
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useInterval(() => nafathCheck(), 2000);
  useEffect(() => {
    if (!otpData) {
      navigate("/");
    }
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${image22})`,
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
      }}
    >
      <Grid
        container
        className="login-container"
        sx={{ flexDirection: "row-reverse" }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="image-container"
        >
          <img className="image1" src={image1} alt="Qistar" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="login-root"
        >
          <div className="logIn" style={{ alignItems: "center" }}>
            <img src={image2} className="image2" alt="Qistar" />
            <div className="text1">التسجيل عبر بوابة نفاذ</div>
            <TextLabel>هذا الاجراء مطلوب مرة واحدة فقط</TextLabel>
            <img src={image8} className="image8" alt="nafath" />
            <TextLabel>قم بفتح تطبيق نفاذ لتأكيد الرقم</TextLabel>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "solid 10px #04a492",
                height: 100,
                width: 100,
                borderRadius: "50%",
                fontFamily: "font",
                fontSize: 32,
                color: "#04a492",
              }}
            >
              {otpData && otpData.rand_no}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NafathCheck;
