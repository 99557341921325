import * as React from "react";
import { PieChart, pieArcClasses } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";

const size = {
  width: 300,
  height: 200,
  legend: { hidden: true },
};

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 30,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieChartWithCenterLabel({
  paid_installments,
  unpaid_installments,
}) {
  const data = [
    { value: paid_installments, label: "المدفوع", color: "#27AE60" },
    { value: unpaid_installments, label: "المتبقي", color: "#FFC300" },
  ];
  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
  const percent = data[0].value / TOTAL;

  return (
    <PieChart
      series={[{ data, innerRadius: 70 }]}
      {...size}
      //   sx={{
      //     transform: "scale(0.5)",
      //   }}
      margin={{ right: 5, top: 0 }}
    >
      {paid_installments === 0 && unpaid_installments === 0 ? (
        <PieCenterLabel>{`0%`}</PieCenterLabel>
      ) : (
        <PieCenterLabel>{`${(percent * 100).toFixed(0)}%`}</PieCenterLabel>
      )}
    </PieChart>
  );
}
