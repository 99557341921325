import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import icon1 from "../../../assets/file-download-fill 1.png";
import { showDate } from "../../utils/common";

export default function BasicAccordion({ data, expanded, handleChange }) {
  return data.map((contract) => {
    const subContract = contract.sub_contracts.filter(
      (arr) => arr.type_id === 2
    );
    return (
      <Accordion
        key={contract.id}
        elevation={0}
        sx={{
          backgroundColor: "#F4F7FF",
          mt: "10px",
          borderRadius: "3px",
          width: "100%",
          boxShadow: "none",
          ":before": {
            height: 0,
          },
        }}
        expanded={expanded === contract.id}
        onChange={handleChange(contract.id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="box40">
            <div className="text11">رقم العقد</div>
            <div className="text10 text-r">{subContract[0].ejar_no}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="box41">
            <div className="box40">
              <div className="text11 text-r">تاريخ بداية العقد</div>
              <div className="text10 text-r">
                {showDate(subContract[0].start_date)}
              </div>
            </div>
            <div className="box40">
              <div className="text11 text-r">تاريخ نهاية العقد</div>
              <div className="text10 text-r">
                {showDate(subContract[0].end_date)}
              </div>
            </div>
          </div>
          <div className="box42">
            <div className="box40">
              <div className="text11 text-r">ملف العقد</div>
              <div className="text10 text-r">contract_file.pdf</div>
            </div>
            <a
              href={`https://api.qistar.sa/storage/${subContract[0].ejar_file}`}
              target="_blank"
            >
              <img src={icon1} alt="icon" />
            </a>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });
  return (
    <div style={{ width: "100%" }}>
      <Accordion
        sx={{
          backgroundColor: "#F4F7FF",
          mt: "10px",
          borderRadius: "3px",
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="box40">
            <div className="text11">رقم العقد</div>
            <div className="text10 text-r">EJAR-RES-11012345697</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="box41">
            <div className="box40">
              <div className="text11 text-r">تاريخ بداية العقد</div>
              <div className="text10 text-r">03-09-2023</div>
            </div>
            <div className="box40">
              <div className="text11 text-r">تاريخ نهاية العقد</div>
              <div className="text10 text-r">03-09-2023</div>
            </div>
          </div>
          <div className="box42">
            <div className="box40">
              <div className="text11 text-r">ملف العقد</div>
              <div className="text10 text-r">contract_file.pdf</div>
            </div>
            <img src={icon1} alt="icon" />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
