import { Button, CircularProgress, Grid } from "@mui/material";
import PieChartWithCenterLabel from "./PieChart";
import HorizontalLinearStepper from "./Stepper";
import icon2 from "../../../assets/file-copy-line 1.png";
import { useState, useEffect } from "react";
import { useNavStore, useReqStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { redirectOwner } from "../../utils/common";
// import icon1 from "../../../assets/single-folded-content 1.png";

const RequestShowOwner = () => {
  // const { req } = useReqStore();
  const navigate = useNavigate();

  const reqId = localStorage.getItem("app-id");
  const { req, setReq } = useReqStore();
  const [loadingR, setLoadingR] = useState(true);
  const token = localStorage.getItem("token");

  const date = new Date(req.created_at).toLocaleDateString();
  const data = [
    { value: 4, label: "", color: "#014E97" },
    { value: 6, label: "", color: "#F1F4FF" },
  ];

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-applications/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      setLoadingR(false);
    } catch (error) {
      console.log(error);
    }
  };

  const CurrentPageId = window.location.pathname.substring(16);

  useEffect(() => {
    if (req) {
      redirectOwner(req.application_status_id, CurrentPageId, navigate);
    }
  }, [req]);

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "الطلبات",
        items: [{ text: "طلبات الإيجار", path: "/owner/requests" }],
      },
    });
    console.log();
    getData();
  }, []);

  return (
    <div className="req-root vw100 scale-r">
      {/* <Contract open={openContract} setOpen={setOpenContract} /> */}
      {loadingR ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginBlock: "20px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="box10">
            <div className="box11">
              <div className="text8 center">طلباتي</div>
            </div>
            <div>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  // width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={() => navigate("/owner/requests/new")}
              >
                <div className="text3">إنشاء طلب جديد</div>
              </Button>
            </div>
          </div>

          <div className="box14" style={{ height: "auto" }}>
            <Grid
              container
              sx={{
                direction: "rtl",
                borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
              }}
            >
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <div className="box11">
                  <div className="text22 center">{`الطلب #${req.serial_number}`}</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {date}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={0}
                xs={0}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                  },
                }}
              >
                <div className="box11" style={{ width: "100%" }}>
                  <div className="text9 center">حالة الطلب</div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="shape2"></div>
                    <p className="text22 text-r" style={{ marginTop: "5%" }}>
                      {req.status.name}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={9} />
                <div className="text9 center">مكتمل بنسبة</div>
                <PieChartWithCenterLabel data={data} />
              </Grid>
            </Grid>
            <div className="box14" />
            <Grid
              container
              sx={{
                direction: "rtl",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                padding: "10px",
                borderRadius: "8px",
                // display: "inline-block",
              }}
            >
              {/* <Grid item lg={1} md={1} sm={12} xs={12}>
						<div className="text9 center">حالة الطلب: </div>
					</Grid> */}
              <Grid item md={12} sm={12} xs={12}>
                {req.application_status_id === 7 ? (
                  <HorizontalLinearStepper
                    acitve={req.last_status_id - 1}
                    error
                  />
                ) : (
                  <HorizontalLinearStepper
                    acitve={req.application_status_id - 1}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                direction: "rtl",
                padding: "10px",
                // display: "inline-block",
              }}
            >
              <div className="text8" style={{ marginTop: "10px" }}>
                بيانات المستأجر
              </div>
              <Grid
                container
                direction={"row"}
                sx={{ marginTop: "10px" }}
                spacing={1.5}
              >
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23-1">إسم المستأجر</div>
                  <div className="box15-1">
                    <div className="text12">{req.renter.name || "لا يوجد"}</div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23-1">رقم الجوال</div>
                  <div className="box15-1">
                    <div className="text12">
                      {req.renter.phone || "لا يوجد"}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="text23-1">البريد الإلكتروني</div>
                  <div className="box15-1">
                    <div className="text12">
                      {req.renter.email || "لا يوجد"}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                direction: "rtl",
                padding: "10px",
                // display: "inline-block",
              }}
            >
              <div className="text8" style={{ marginTop: "10px" }}>
                بيانات العقار
              </div>
              <Grid
                container
                direction={"row"}
                sx={{ marginTop: "10px" }}
                spacing={1.5}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text23-1">اسم المالك</div>
                  <div className="box15-1">
                    <div className="text12">{req.property_owner_name}</div>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text23-1">العنوان المختصر</div>
                  <div className="box15-1">
                    <div className="text12">{req.short_code || "لا يوجد"}</div>
                  </div>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className="text8" style={{ marginTop: "10px" }}>
                    بيانات التعاقد
                  </div>
                  {/* <div style={{ width: "100%" }} /> */}
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className="box61">
                        <div className="box11">
                          <div className="text22 center mr0">قيمة التعاقد</div>
                          <div
                            className="text9 mr0"
                            style={{
                              marginTop: "5%",
                              textAlign: "right",
                            }}
                          >
                            نص مفصل يوضح للمالك تفاصيل تعاقد المنصه مع المالك
                            والنسبة التي تذهب الى قسطار
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className="box61">
                        <div
                          className="box11"
                          style={{
                            justifyContent: "start",
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                            // marginBlock: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          {/* <Grid xs={12}> */}
                          <div
                            className="text9"
                            style={{
                              width: "100%",
                              padding: "5px",
                              borderTop: "10px",
                              marginRight: "0px",
                              textAlign: "right",
                              marginBottom: "5px",
                            }}
                          >
                            رقم العقد على منصه ايجار
                          </div>
                          <div className="box32" style={{ width: "100%" }}>
                            <div className="box31 mr0">
                              <div className="box30">454354677765532</div>
                            </div>
                            <div
                              className="box33"
                              // onClick={() =>
                              //   navigate("/renter/requests/complete")
                              // }
                            >
                              <img src={icon2} alt="icon" />
                            </div>
                          </div>
                          {/* </Grid> */}
                          {/* <Grid xs={4} sx={{ justifyContent: "end", display: "flex" }}> */}
                          {/* </Grid> */}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default RequestShowOwner;
