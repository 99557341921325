import { CircularProgress, Divider, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import server from "../../api/server";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationStore } from "../../store";

const Notifications = ({ userType, setOpen }) => {
  const [loading, setLoading] = useState({});

  const localToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const { notifications, updateRead, fetchNotifications } =
    useNotificationStore();

  const updateData = async (notId) => {
    try {
      const { data } = await server.get(
        `/customers/get-notifications?notification_id=${notId}`,
        {
          headers: {
            Authorization: `Bearer ${localToken}`,
          },
        }
      );
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const goToApplication = async (link) => {
    setLoading({ [link]: true });
    try {
      const { data } = await server.get(`/customers${link}`, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      console.log(data.data.application_status_id);
      // setLoading({ [link]: false });
      localStorage.setItem("req", JSON.stringify(data.data));
      if (data.data.application_status_id) {
        localStorage.setItem("app-id", data.data.id);
        navigate(`/${userType}/requests/${data.data.application_status_id}`);
        if (setOpen) setOpen(false);
      }
      setLoading({ [link]: false });
    } catch (error) {
      console.log(error);
      setLoading({ [link]: false });
    }
  };

  const handleShow = (item) => {
    if (item.link.includes("get-applications")) {
      goToApplication(item.link);
    }
    updateRead(item.id);
    updateData(item.id);
  };

  const showDetails = (text) => {
    if (text.length > 50) {
      return text.substring(0, 50) + "...";
    } else {
      return text;
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const renderData = () => {
    // const unreadedNotifications = notifications
    //   .slice(0, 4)
    //   .filter((item) => item.is_read === 0);
    // setNew(unreadedNotifications);
    return notifications?.slice(0, 4).map((item) => {
      return (
        <Grid item xs={12} key={item.id}>
          <div
            className="box3"
            style={{
              background: item.is_read ? "#fff" : "rgba(39, 174, 96, 0.20)",
              // marginBottom: "10px",
              // borderBottom: "solid 0.5px #999999",
              borderRadius: "5px",
              padding: 5,
              paddingLeft: 0,
            }}
          >
            <div className="box4">
              {item.is_read ? (
                <div className="shape3"></div>
              ) : (
                <div className="shape3" style={{ background: "#27AE60" }}></div>
              )}

              <div
                className="text14 f-normal"
                style={{ textAlign: "right", width: "100%" }}
              >
                {showDetails(item.details)}
              </div>
            </div>
            <LoadingButton
              loading={loading[item.link]}
              onClick={() => handleShow(item)}
            >
              <div className="text13">عرض</div>
            </LoadingButton>
          </div>
          <Divider sx={{ mb: "3px" }} />
        </Grid>
      );
    });
  };

  return (
    <>
      {notifications?.length === 0 ? (
        <div>لاتوجد إشعارات</div>
      ) : (
        <>{notifications ? <>{renderData()}</> : <CircularProgress />}</>
      )}
    </>
  );
};

export default Notifications;
