import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import icon1 from "../../../assets/contrtctIcon.png";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import LoadingButton from "@mui/lab/LoadingButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Contract({ open, setOpen, req, token, refresh }) {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const agreeContract = async () => {
    setLoading(true);
    try {
      const { data } = await server.patch(
        `/customers/agree-commitment-contract/${req.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          redirect: "follow",
        }
      );
      console.log(data);
      // navigate("/renter/requests/5");
      refresh();
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleAgeeAndContinue = () => {
    agreeContract();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            direction: "rtl",
            display: "flex",
          }}
          id="customized-dialog-title"
        >
          <img src={icon1} alt="icon" />
          <div>
            <div className="text22" style={{ width: "100%" }}>
              عقد إلتزام
            </div>
            <div className="text9">01-9-2023</div>
          </div>
        </DialogTitle>

        <DialogContent dividers sx={{ direction: "rtl" }}>
          {/*  */}
          <>
            <div className="text8">الطرف الأول</div>
            <Grid
              container
              sx={{
                direction: "rtl",
              }}
            >
              <Grid item xs={6}>
                <div className="box11">
                  <div className="text11">السيد </div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {req.renter?.name}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="box11">
                  <div className="text11">صاحب الهوية</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {req.renter?.identity_number}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
          <>
            <div className="text8">الطرف الثاني</div>
            <Grid
              container
              sx={{
                direction: "rtl",
              }}
            >
              <Grid item xs={6}>
                <div className="box11">
                  <div className="text11">السيد </div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {req.company_name}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="box11">
                  <div className="text11">السجل التجاري رقم</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {req.registration_number}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
          <>
            <div className="text9">وقد إتفق الطرفان على ما يلي</div>
          </>
          <>
            <div className="box15">
              <div className="text11" style={{ textAlign: "right" }}>
                على المستأجر الالتزام بتسديد الدفعات في وقتها المحدد حسب العقد
                الأطراف: يشمل هذا البند أسماء الأطراف المعنية بالعقد، أي المؤجر
                (الطرف الأول) والمستأجر (الطرف الثاني)، ويشير إلى أن كلا الطرفين
                لديهم القدرة القانونية للتعاقد. وصف العقار: يتضمن هذا البند
                وصفاً مفصلاً للعقار المؤجر، بما في ذلك الموقع والمساحة
                والاستخدام المحدد للعقار. يُذكر هنا أيضًا ما إذا كان العقار
                مفروشًا أم لا. مدة العقد: يحدد هذا البند فترة سريان العقد، بما
                في ذلك تاريخ بدء العقد وتاريخ انتهائه. يمكن أن تكون الفترة محددة
                بعدد معين من الأشهر أو السنوات، أو قد تكون لفترة غير محددة (عقد
                سارٍ حتى نهاية إشعار مسبق). الإيجار وطرق الدفع: يتعين تحديد هذا
                البند المبلغ المتفق عليه للإيجار الشهري أو السنوي، وطرق الدفع
                المقبولة (نقدًا، شيكًا، تحويل بنكي، إلخ)، وموعد استحقاق الدفعات.
                الإنهاء المبكر: يتعين تناول هذا البند الإجراءات المتعلقة بإنهاء
                العقد قبل انتهاء مدته، سواء كان ذلك بواسطة المؤجر أو المستأجر،
                والشروط والغرامات المحتملة المرتبطة بهذا الإنهاء المبكر.
                الضمانات: يمكن أن يتطلب هذا البند من المستأجر تقديم ضمانات
                مالية، مثل وديعة تأمين أو كفالة بنكية، لضمان الالتزام بشروط
                العقد وتعويض أي أضرر يلحق بالعقار.
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            autoFocus
            onClick={handleAgeeAndContinue}
            variant="contained"
            size="large"
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              bgcolor: "#014E97",
            }}
            loading={loading}
          >
            <div className="text3">الموافقه على العقد والمتابعة</div>
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
