import Grid from "@mui/material/Grid";
import SideBar from "./Sidebar";
import StatusBar from "./StatusBar";
import { useState, useEffect } from "react";
import MobileHeader from "./MobileHeader";
import { useSettingStore, useUserStor } from "../../../store";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import server from "../../../api/server";
import RealTime from "../../../RealTime";

const HomeLayoutOwner = ({ element }) => {
  const [drawer, setDrawer] = useState(false);
  const [toggleSidebar, setToggleSeidebar] = useState(true);
  const [loading, setLoading] = useState(true);

  const { setSettings } = useSettingStore();

  const { userData, setToken, setUserData } = useUserStor();
  const navigate = useNavigate();

  const localToken = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const userParsed = JSON.parse(user);

  const getData = async () => {
    try {
      const { data } = await server.get("/user", {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setLoading(false);
      localStorage.setItem("user", JSON.stringify(data));
      if (data.customer_type_id === 1) {
        navigate("/home");
      }
    } catch (error) {
      console.log(error);
      navigate("/login");
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await server.get(`/customers/settings`, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setSettings(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
    console.log(drawer);
  };

  const sidebarGrid = () => {
    return toggleSidebar ? 2.3 : 0.8;
  };

  const StatusBarGrid = () => {
    return toggleSidebar ? 9.7 : 11.2;
  };

  useEffect(() => {
    if (!localToken) {
      navigate("/login");
    }
    if (!userData) {
      setToken(localToken);
      setUserData(JSON.parse(user));
    }
    // isUserOwner();
    if (localToken) {
      getData();
      getSettings();
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#F4F7FF",
      }}
    >
      <RealTime />
      <div>
        <Grid
          direction={"row-reverse"}
          container
          sx={{
            height: "100vh",
            alignItems: "flex-start",
            backgroundColor: "#F4F7FF",
          }}
        >
          {/* The side bar */}
          <Grid item xs={sidebarGrid()}>
            <SideBar
              sideBarMain="sideBar-main"
              expand={toggleSidebar}
              setExpand={setToggleSeidebar}
            />
          </Grid>
          {/* status bar */}

          <Grid
            item
            xs={12}
            md={StatusBarGrid()}
            lg={StatusBarGrid()}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              //   justifyContent: "flex-start",

              backgroundColor: "#F4F7FF",
            }}
          >
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <StatusBar />
                <MobileHeader />
                <div
                  style={{
                    backgroundColor: "#F4F7FF",
                    minHeight: "-webkit-fill-available",
                    minHeight: "-moz-available",
                    // minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                    marginInline: "40px",
                  }}
                  className="mh-100vh scale root-m"
                >
                  {element}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HomeLayoutOwner;
