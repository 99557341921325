import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PieChartWithCenterLabel from "./PieChart";
import HorizontalLinearStepper from "./Stepper";
import icon1 from "../../../assets/single-folded-content 1.png";
import Contract from "./Contract";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePayStore, useReqStore, useUserStor } from "../../../store";
import server from "../../../api/server";
import { NumericFormat } from "react-number-format";

const RenterPay = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { currentPayment, setCurrentPayment } = usePayStore();
  const reqId = localStorage.getItem("app-id");
  const { req, setReq } = useReqStore();
  // const { token } = useUserStor();
  //   const req = JSON.parse(localStorage.getItem("req"));
  const token = localStorage.getItem("token");

  const date = new Date(req.created_at).toLocaleDateString();

  const installment = Math.round(req.total_rent_amount / 12);

  const data = [
    { value: 10, label: "", color: "#014E97" },
    { value: 0, label: "", color: "#F1F4FF" },
  ];

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/customers/get-applications/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      // setLoading(false);
      getInstallments(data.data.contracts.id);
    } catch (error) {
      console.log(error);
    }
  };
  const getInstallments = async (id) => {
    try {
      const { data } = await server.get(
        `/customers/get-contracts-installments/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   setInstallments(data.data.contract.installments);
      setCurrentPayment(data.data.current_installment);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateToPayment = () => {
    navigate(`/renter/payment/${currentPayment.id}`);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="req-root vw100" style={{ width: "100%" }}>
      <div className="box10">
        <div className="box11">
          <div className="text8 center">طلباتي</div>
        </div>
        <div>
          <Button
            variant="contained"
            size="large"
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              // width: "10vw",
              bgcolor: "#014E97",
            }}
            onClick={() => navigate("/renter/requests/new")}
          >
            <div className="text3">إنشاء طلب جديد</div>
          </Button>
        </div>
      </div>
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="box14 " style={{ height: "auto" }}>
          <Grid
            container
            sx={{
              direction: "rtl",
              borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
            }}
          >
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <div className="box11">
                <div className="text22 center">{`الطلب #${req.id}`}</div>
                <div className="text9" style={{ marginTop: "5%" }}>
                  {date}
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={0}
              xs={0}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <div className="box11" style={{ width: "100%" }}>
                <div className="text9 center">حالة الطلب</div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="shape2"></div>
                  <p className="text22 text-r" style={{ marginTop: "5%" }}>
                    دفع الرسوم الإدارية{" "}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12} />
              <div className="text9 center">مكتمل بنسبة</div>
              <PieChartWithCenterLabel data={data} />
            </Grid>
          </Grid>
          {/* <div className="box14" /> */}
          <Grid
            container
            sx={{
              direction: "rtl",
              padding: "10px",
              // display: "inline-block",
              gap: "10px",
            }}
          >
            <div
              className="text8 mr0"
              style={{
                width: "100%",
                padding: "5px",
                borderTop: "10px",
              }}
            >
              دفع الرسوم الإدارية
            </div>
            <div className="text11 mr0">عبارة عن رسوم تدفع للمنصه</div>

            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={6}>
							<Box sx={boxStyle2}>
								<div className="text3">متوسط القسط الشهري</div>
								<div
									className="text8"
									style={{
										color: "#014E97",
										marginTop: 5,
									}}
								>
									{installment} ريال
								</div>
							</Box>
						</Grid> */}
              <Grid item xs={12} md={6}>
                <Box sx={boxStyle2}>
                  <div className="text3">قيمة الدفعة الأولى</div>
                  <div
                    className="text8"
                    style={{
                      color: "#014E97",
                      marginTop: 5,
                    }}
                  >
                    <NumericFormat
                      displayType="text"
                      thousandSeparator=","
                      value={installment + req.Administrative_fees}
                    />

                    <div style={{ marginRight: 5 }}>ريال</div>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                sx={{ bgcolor: "#014E97", width: "45vmin" }}
                onClick={handleNavigateToPayment}
              >
                <div
                  className="text25"
                  style={{ color: "#fff", width: "max-content" }}
                >
                  سداد الدفعة الأولى
                </div>
              </Button>
            </Grid>
            <div
              className="mr0"
              style={{ justifyContent: "space-between" }}
            ></div>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default RenterPay;

const boxStyle2 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "15px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#fff",
};
